// import React, { useMemo } from 'react';
// import { Table } from 'antd';
// import PropTypes from 'prop-types';

// const ExpensesTable = ({ pnl, t }) => {
//   const getTotalJSX = (value) => (value !== null && value !== 0 ? value.toFixed(2) : '-');
//   const calculateAverage = (total, count) => (total ? (total / count).toFixed(2) : '-');

//   // Dynamic columns for each month
//   const monthColumns = [
//     "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
//     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//   ].map((month) => ({
//     title: <span style={{ fontWeight: 'bold' }}>{month}</span>,
//     dataIndex: month.toLowerCase(),
//     key: month.toLowerCase(),
//     width: 120,
//   }));

//   const fixedColumns = [
//     { title: t("Total"), dataIndex: 'total2', key: 'total2', width: 100, fixed: 'right' },
//     { title: t("Average"), dataIndex: 'average2', key: 'average2', width: 100, fixed: 'right' },
//   ];

//   const columns = [
//     { title: t("Expenses"), dataIndex: 'Expenses', key: 'Expenses', width: 180 },
//     ...monthColumns,
//     ...fixedColumns,
//   ];

//   const data = useMemo(() => {
//     const rows = [
//       {
//         key: '1',
//         Expenses: 'Miscellaneous Costs',
//         children: pnl.map((item, index) => {
//           return item.miscCosts.map((miscCost, miscIndex) => {
//             return {
//               key: `1-${index}-${miscIndex}`, // Unique key for each child row
//               Expenses: miscCost.name, // Name of the miscellaneous cost
//               ...monthColumns.reduce((acc, col, monthIndex) => {
//                 // Assuming the month index corresponds to the index of pnl
//                 const value = item?.miscCosts[miscIndex]?.total; // Get the total for this miscCost
//                 acc[col.dataIndex] = value ? getTotalJSX(value) : '-';
//                 return acc;
//               }, {}),
//             };
//           });
//         }).flat(), // Flatten the array of arrays into a single array
//         ...monthColumns.reduce((acc, col) => {
//           const monthSum = pnl.reduce((sum, item) => {
//             return sum + (item.miscCosts.reduce((miscSum, miscCost) => miscSum + (miscCost.total || 0), 0));
//           }, 0);
//           acc[col.dataIndex] = getTotalJSX(monthSum);
//           return acc;
//         }, {}),
//         total2: getTotalJSX(pnl.reduce((sum, item) => {
//           return sum + item.miscCosts.reduce((miscSum, miscCost) => miscSum + (miscCost.total || 0), 0);
//         }, 0)),
//         average2: calculateAverage(pnl.reduce((sum, item) => {
//           return sum + item.miscCosts.reduce((miscSum, miscCost) => miscSum + (miscCost.total || 0), 0);
//         }, 0), 12),
//       },
//       {
//         key: '2',
//         Expenses: 'DirectCostOfGoods',
//         ...monthColumns.reduce((acc, col, index) => {
//           const value = pnl[index]?.costOfGoods;
//           acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//           return acc;
//         }, {}),
//         total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.costOfGoods) || 0), 0)),
//         average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.costOfGoods) || 0), 0), 12),
//       },
//       {
//         key: '3',
//         Expenses: 'ShippingHandling',
//         ...monthColumns.reduce((acc, col, index) => {
//           const value = pnl[index]?.SnH;
//           acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//           return acc;
//         }, {}),
//         total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.SnH) || 0), 0)),
//         average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.SnH) || 0), 0), 12),
//       },
//       {
//         key: '4',
//         Expenses: 'RefundsReturns',
//         ...monthColumns.reduce((acc, col, index) => {
//           const value = pnl[index]?.refunds;
//           acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//           return acc;
//         }, {}),
//         total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.refunds) || 0), 0)),
//         average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.refunds) || 0), 0), 12),
//       },
//       {
//         key: '5',
//         Expenses: 'Discounts',
//         ...monthColumns.reduce((acc, col, index) => {
//           const value = pnl[index]?.discounts;
//           acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//           return acc;
//         }, {}),
//         total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.discounts) || 0), 0)),
//         average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.discounts) || 0), 0), 12),
//       },
//       {
//         key: '6',
//         Expenses: 'Ads',
//         ...monthColumns.reduce((acc, col, index) => {
//           const value = pnl[index]?.ads;
//           acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//           return acc;
//         }, {}),
//         total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.ads) || 0), 0)),
//         average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.ads) || 0), 0), 12),
//       },
//     ];

//     // Calculate monthly totals for the total row
//     const totalRow = {
//       key: 'total',
//       Expenses: 'Total',
//       ...monthColumns.reduce((acc, col) => {
//         const monthSum = rows.reduce((sum, row) => sum + (parseFloat(row[col.dataIndex]) || 0), 0);
//         acc[col.dataIndex] = getTotalJSX(monthSum);
//         return acc;
//       }, {}),
//       total2: getTotalJSX(rows.reduce((sum, row) => sum + parseFloat(row.total2) || 0, 0)),
//       average2: calculateAverage(rows.reduce((sum, row) => sum + parseFloat(row.total2) || 0, 0), 12),
//     };

//     return [...rows, totalRow];
//   }, [pnl]);

//   return (
//     <Table
//       columns={columns}
//       dataSource={data}
//       scroll={{ x: 1500 }}
//       pagination={false}
//       expandable={{
//         expandedRowRender: record => record.children ? (
//           <Table
//             columns={columns}
//             dataSource={record.children}
//             pagination={false}
//             showHeader={false}
//           />
//         ) : null,
//         rowExpandable: record => record.children && record.children.length > 0,
//       }}
//     />
//   );
// };

// ExpensesTable.propTypes = {
//   pnl: PropTypes.array.isRequired,
//   t: PropTypes.func.isRequired,
// };

// export default ExpensesTable;



// import React, { useMemo } from 'react';
// import { Table } from 'antd';
// import PropTypes from 'prop-types';
// import moment from 'moment';

// const ExpensesTable = ({ pnl, t, additionalCosts = [] }) => {
//   const getTotalJSX = (value) => (value !== null && value !== 0 ? value.toFixed(2) : '-');
//   const calculateAverage = (total, count) => (total ? (total / count).toFixed(2) : '-');

//   // Dynamic columns for each month
//   const monthColumns = [
//     "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
//     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//   ].map((month) => ({
//     title: <span style={{ fontWeight: 'bold' }}>{month}</span>,
//     dataIndex: month.toLowerCase(),
//     key: month.toLowerCase(),
//     width: 120,
//   }));

//   const fixedColumns = [
//     { title: t("Total"), dataIndex: 'total2', key: 'total2', width: 100, fixed: 'right' },
//     { title: t("Average"), dataIndex: 'average2', key: 'average2', width: 100, fixed: 'right' },
//   ];

//   const columns = [
//     { title: t("Expenses"), dataIndex: 'Expenses', key: 'Expenses', width: 180 },
//     ...monthColumns,
//     ...fixedColumns,
//   ];

//   // Original predefined rows for the table
//   const predefinedRows = [
//     {
//               key: '1',
//               Expenses: 'DirectCostOfGoods',
//               ...monthColumns.reduce((acc, col, index) => {
//                 const value = pnl[index]?.costOfGoods;
//                 acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//                 return acc;
//               }, {}),
//               total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.costOfGoods) || 0), 0)),
//               average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.costOfGoods) || 0), 0), 12),
//             },
//             {
//               key: '2',
//               Expenses: 'ShippingHandling',
//               ...monthColumns.reduce((acc, col, index) => {
//                 const value = pnl[index]?.SnH;
//                 acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//                 return acc;
//               }, {}),
//               total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.SnH) || 0), 0)),
//               average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.SnH) || 0), 0), 12),
//             },
//             {
//               key: '3',
//               Expenses: 'RefundsReturns',
//               ...monthColumns.reduce((acc, col, index) => {
//                 const value = pnl[index]?.refunds;
//                 acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//                 return acc;
//               }, {}),
//               total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.refunds) || 0), 0)),
//               average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.refunds) || 0), 0), 12),
//             },
//             {
//               key: '4',
//               Expenses: 'Discounts',
//               ...monthColumns.reduce((acc, col, index) => {
//                 const value = pnl[index]?.discounts;
//                 acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//                 return acc;
//               }, {}),
//               total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.discounts) || 0), 0)),
//               average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.discounts) || 0), 0), 12),
//             },
//             {
//               key: '5',
//               Expenses: 'Ads',
//               ...monthColumns.reduce((acc, col, index) => {
//                 const value = pnl[index]?.ads;
//                 acc[col.dataIndex] = value ? getTotalJSX(parseInt(value)) : '-';
//                 return acc;
//               }, {}),
//               total2: getTotalJSX(pnl.reduce((sum, item) => sum + (parseInt(item?.ads) || 0), 0)),
//               average2: calculateAverage(pnl.reduce((sum, item) => sum + (parseInt(item?.ads) || 0), 0), 12),
//             },
//   ];

//   // Map additionalCosts from Costs.js to new rows
//   const additionalRows = additionalCosts.map((cost) => {
//     if (!cost || !cost.activeDate || !cost.price) return null; // Check for necessary fields

//     const monthIndex = moment(parseInt(cost.activeDate)).month();
//     const monthName = monthColumns[monthIndex].dataIndex;

//     return {
//       key: `cost-${cost.id || Math.random()}`,
//       Expenses: cost.name || 'Unnamed Cost',
//       [monthName]: getTotalJSX(cost.price),
//       total2: getTotalJSX(cost.price),
//       average2: calculateAverage(cost.price, 12),
//     };
//   }).filter(Boolean); // Remove any null entries

//   // Calculate monthly totals for the total row
//   const totalRow = {
//     key: 'total',
//     Expenses: 'Total',
//     ...monthColumns.reduce((acc, col) => {
//       const monthSum = [...predefinedRows, ...additionalRows].reduce((sum, row) => sum + (parseFloat(row[col.dataIndex]) || 0), 0);
//       acc[col.dataIndex] = getTotalJSX(monthSum);
//       return acc;
//     }, {}),
//     total2: getTotalJSX([...predefinedRows, ...additionalRows].reduce((sum, row) => sum + (parseFloat(row.total2) || 0), 0)),
//     average2: calculateAverage([...predefinedRows, ...additionalRows].reduce((sum, row) => sum + (parseFloat(row.total2) || 0), 0), 12),
//   };

//   // Combine original rows, additional rows from Costs.js, and total row
//   const data = useMemo(() => [...predefinedRows, ...additionalRows, totalRow], [pnl, additionalCosts]);

//   return (
//     <Table
//       columns={columns}
//       dataSource={data}
//       scroll={{ x: 1500 }}
//       pagination={false}
//     />
//   );
// };

// ExpensesTable.propTypes = {
//   pnl: PropTypes.array.isRequired,
//   t: PropTypes.func.isRequired,
//   additionalCosts: PropTypes.array,
// };

// export default ExpensesTable;


import React, { useMemo, forwardRef,useImperativeHandle } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { formatNumberWithSpaces } from '../../GoogleAnalytics/common/HelperFunctions/utils';

const ExpensesTable = forwardRef(({ pnl, t }, ref) => {
  // Safe number formatting function
  const getTotalJSX = (value) => {
    // Handle all possible invalid inputs
    if (value === null || value === undefined || value === '') {
      return '-';
    }
    
    // Convert to number
    const num = Number(value);
    
    // Check if it's a valid number and not zero
    if (!isNaN(num) && num !== 0) {
      return num.toFixed(2);
    }
    
    return '-';
  };

  // Safe average calculation
  const calculateAverage = (total, count) => {
    const num = Number(total);
    if (isNaN(num) || num === 0 || !count) {
      return '-';
    }
    return (num / count).toFixed(2);
  };

  const monthColumns = [
  "JAN", "FEB", "MAR", "APR", "MAY", "JUN", 
  "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
].map((month) => ({
  title: <span style={{ fontWeight: 'bold' }}>{t(month)}</span>, // Correctly call `t` with the string key
  dataIndex: month.toLowerCase(),
  key: month.toLowerCase(),
  width: 120,
  render: (value) => (
    <span className="table-cell-text">
      {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
    </span>
  ),
}));


  const fixedColumns = [
    { 
      title: t("TOTAL"), 
      dataIndex: 'total2', 
      key: 'total2', 
      width: 120, 
      fixed: 'right',
      render: (value) => (
        <span className="table-cell-text">
          {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
        </span>
      ),
    },
    { 
      title: t("AVERAGE"), 
      dataIndex: 'average2', 
      key: 'average2', 
      width: 120, 
      fixed: 'right',
      render: (value) => (
        <span className="table-cell-text">
          {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
        </span>
      ),
    },
  ];

  const columns = [
    { title: t("EXPENSES"), dataIndex: 'Expenses', fixed: 'left', key: 'Expenses', width: 220 },
    ...monthColumns,
    ...fixedColumns,
  ];

  const data = useMemo(() => {
    // Safely get misc costs for a month
    const getMiscCosts = (monthData) => {
      if (!monthData || !Array.isArray(monthData.miscCosts)) {
        return [];
      }
      return monthData.miscCosts;
    };

    // Safely get numeric value
    const safeNumber = (value) => {
      const num = Number(value);
      return isNaN(num) ? 0 : num;
    };

    // Get unique misc cost names
    const miscCostNames = Array.from(new Set(
      pnl.flatMap(month => getMiscCosts(month).map(cost => cost.name))
    ));

    // Create children rows for misc costs
    const miscChildren = miscCostNames.map((name, index) => {
      const monthlyValues = {};
      let totalForName = 0;

      monthColumns.forEach((col, monthIndex) => {
        const monthData = pnl[monthIndex];
        const costEntry = getMiscCosts(monthData).find(cost => cost.name === name);
        const value = safeNumber(costEntry?.total);
        monthlyValues[col.dataIndex] = value;
        totalForName += value;
      });

      return {
        key: `misc-${index}`,
        Expenses: <span className="table-cell-text">{name}</span>,
        ...monthlyValues,
        total2: totalForName,
        average2: totalForName / 12
      };
    });

    // Main data rows
    const rows = [
      {
        key: '1',
        Expenses: <span className="table-cell-text">{t("Miscellaneous Costs")}</span>,
        children: miscChildren,
        ...monthColumns.reduce((acc, col, monthIndex) => {
          const monthTotal = getMiscCosts(pnl[monthIndex])
            .reduce((sum, cost) => sum + safeNumber(cost.total), 0);
          acc[col.dataIndex] = monthTotal;
          return acc;
        }, {}),
        total2: pnl.reduce((sum, month) => 
          sum + getMiscCosts(month).reduce((mSum, cost) => mSum + safeNumber(cost.total), 0),
          0
        ),
        average2: pnl.reduce((sum, month) => 
          sum + getMiscCosts(month).reduce((mSum, cost) => mSum + safeNumber(cost.total), 0),
          0
        ) / 12
      },
      {
        key: '2',
        Expenses: <span className="table-cell-text">{t("Direct Cost Of Goods")}</span>,
        ...monthColumns.reduce((acc, col, index) => {
          acc[col.dataIndex] = safeNumber(pnl[index]?.costOfGoods);
          return acc; 
        }, {}),
        total2: pnl.reduce((sum, item) => sum + safeNumber(item?.costOfGoods), 0),
        average2: pnl.reduce((sum, item) => sum + safeNumber(item?.costOfGoods), 0) / 12
      },
      {
        key: '3',
        Expenses: <span className="table-cell-text">{t("Shipping & Handling")}</span>,
        ...monthColumns.reduce((acc, col, index) => {
          acc[col.dataIndex] = safeNumber(pnl[index]?.SnH);
          return acc;
        }, {}),
        total2: pnl.reduce((sum, item) => sum + safeNumber(item?.SnH), 0),
        average2: pnl.reduce((sum, item) => sum + safeNumber(item?.SnH), 0) / 12
      },
      {
        key: '4',
        Expenses: <span className="table-cell-text">{t("RefundsReturns")}</span>,
        ...monthColumns.reduce((acc, col, index) => {
          acc[col.dataIndex] = safeNumber(pnl[index]?.refunds);
          return acc;
        }, {}),
        total2: pnl.reduce((sum, item) => sum + safeNumber(item?.refunds), 0),
        average2: pnl.reduce((sum, item) => sum + safeNumber(item?.refunds), 0) / 12
      },
      {
        key: '5',
        Expenses: <span className="table-cell-text">{t("Discounts")}</span>,
        ...monthColumns.reduce((acc, col, index) => {
          acc[col.dataIndex] = safeNumber(pnl[index]?.discounts);
          return acc;
        }, {}),
        total2: pnl.reduce((sum, item) => sum + safeNumber(item?.discounts), 0),
        average2: pnl.reduce((sum, item) => sum + safeNumber(item?.discounts), 0) / 12
      },
      {
        key: '6',
        Expenses: <span className="table-cell-text">{t("Ads")}</span>,
        ...monthColumns.reduce((acc, col, index) => {
          acc[col.dataIndex] = safeNumber(pnl[index]?.ads);
          return acc;
        }, {}),
        total2: pnl.reduce((sum, item) => sum + safeNumber(item?.ads), 0),
        average2: pnl.reduce((sum, item) => sum + safeNumber(item?.ads), 0) / 12
      },
    ];

    // Calculate total row
    const totalRow = {
      key: 'total',
      Expenses: <span className="table-cell-text">{t("Total")}</span>,
      ...monthColumns.reduce((acc, col) => {
        const monthSum = rows.reduce((sum, row) => {
          if (row.children) {
            return sum + row.children.reduce(
              (childSum, child) => childSum + safeNumber(child[col.dataIndex]),
              0
            );
          }
          return sum + safeNumber(row[col.dataIndex]);
        }, 0);
        acc[col.dataIndex] = monthSum;
        return acc;
      }, {}),
      total2: rows.reduce((sum, row) => {
        if (row.children) {
          return sum + row.children.reduce(
            (childSum, child) => childSum + safeNumber(child.total2),
            0
          );
        }
        return sum + safeNumber(row.total2);
      }, 0),
      average2: rows.reduce((sum, row) => {
        if (row.children) {
          return sum + row.children.reduce(
            (childSum, child) => childSum + safeNumber(child.total2),
            0
          );
        }
        return sum + safeNumber(row.total2);
      }, 0) / 12
    };

    return [...rows, totalRow];
  }, [pnl]);

  const tableRef = React.useRef();

  useImperativeHandle(ref, () => ({
    getTableElement: () => tableRef.current?.querySelector("table"),
  }));

  return (
    <div ref={tableRef}>

    <Table
      columns={columns}
      dataSource={data}
      scroll={{ x: 1500 }}
      pagination={false}
    />
    </div>
  );
});

ExpensesTable.propTypes = {
  pnl: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default ExpensesTable;