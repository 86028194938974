import React, { useState, useEffect } from 'react';
import { CardBody } from 'reactstrap';
import { Table } from 'antd';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopSellersRequest } from '../../../store/woocommerce/actions';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import secureSection from '../../../MangeSecurity/MemberSecurity';
import { formatDate } from '../../GoogleAnalytics/common/HelperFunctions/utils';

const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function WooTopSellers(props) {
  secureSection();
  const dispatch = useDispatch();
  const { topSellers } = useSelector((state) => state.woocommerce);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const newStartDate = formatDate(dateRange.selection1.startDate);
    const newEndDate = formatDate(dateRange.selection1.endDate);

    setIsLoading(true);
    dispatch(fetchTopSellersRequest(newStartDate, newEndDate))
      setIsLoading(false); // Set loading to false once data fetch completes
   
  }, [dateRange, dispatch]);

  // Define columns for Ant Design table
  const columns = [
    {
      title: props.t("IMAGE"),
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        <img src={image} alt="Product" style={{ maxWidth: '100px', maxHeight: '90px' }} />
      ),
    },
    {
      title: props.t("NAME"),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: props.t("QUANTITY"),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    }
  ];

  // Pagination options
  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'],
    style: {
      backgroundColor: '#F4F2FF',
      padding: '10px 15px',
      marginTop: '2px',
      borderRadius: '6px',
      fontSize: '14px',
      fontWeight: '700',
      color: '#6E6893',
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
          <title>{props.t("woo top sellers")} | Live-Metrics - E-com SaaS</title>
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("WooCommerce")}
            breadcrumbItem={props.t("Top Sellers")}
            pageHeading={props.t("WooCommerce")}
            style={{ fontSize: '15px', fontWeight: '700' }}
            image={WooCommercepIcon}
          />
          <div style={{ marginTop: '40px' }}>
            <CardBody className="px-0">
              <Table
                columns={columns}
                loading={isLoading}
                dataSource={topSellers}
                pagination={paginationOptions}
              
                className="custom-table"
              />
            </CardBody>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

WooTopSellers.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WooTopSellers));
