import gql from 'graphql-tag';

const getData = gql`
  query (
    $startDate: String!
    $endDate: String!
    $compareStartDate: String
    $compareEndDate: String
  ) {
    get(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      report {
      day
      totalCost
      Revenue
      totalConversions
      ROAS
      cpa 
      conversionRate
      totalImpressions
      totalClicks
      AOV
      totalLandingPageView
      totalcpc
      totalctr
      totalreach
      }
    }
  }
`;

export { getData }