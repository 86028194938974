import { put, call, takeEvery } from 'redux-saga/effects';
import {
  getfbPageData,
  getfbPagePosts,
} from '../../queries/FacebookSocialMedia/queries';
import urls from '../../routes/apiUrls';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import {
  GET_FACEBOOKPAGE_DATA_PER_DATE,
  getFacebookPageDataPerDateSuccess,
  getFacebookPageDataPerDateFailure,
  GET_FACEBOOKPAGE_POST_DATA,
  getFacebookPagePostDataSuccess,
  getFacebookPagePostDataFailure,
} from './actions';

const ApolloCliGetfbSocialMedia = new ApolloClient({
  link: new HttpLink({
    uri: urls.facebookSocialMedia,
           headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function* getFacebookPageDataPerDate(action) {
  try {
    const variables = {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    };

    if (action.payload.compareStartDate) {
      variables.compareStartDate = action.payload.compareStartDate;
    }

    if (action.payload.compareEndDate) {
      variables.compareEndDate = action.payload.compareEndDate;
    }
    const { data } = yield call(ApolloCliGetfbSocialMedia.query, {
      query: getfbPageData,
      variables,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data) {
      yield put(getFacebookPageDataPerDateSuccess(data.getfbPageData));
    } else {
      yield put(getFacebookPageDataPerDateFailure('err'));
    }
  } catch (error) {
    yield put(getFacebookPageDataPerDateFailure(error));
  }
}

function* getFacebookPagePostData(action) {
  try {
    const { data } = yield call(ApolloCliGetfbSocialMedia.query, {
      query: getfbPagePosts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (data) {
      yield put(getFacebookPagePostDataSuccess(data.getfbPagePosts));
    } else {
      yield put(getFacebookPagePostDataFailure('err'));
    }
  } catch (error) {
    yield put(getFacebookPagePostDataFailure(error));
  }
}

export default function* facebookPageSaga() {
  yield takeEvery(GET_FACEBOOKPAGE_DATA_PER_DATE, getFacebookPageDataPerDate);
  yield takeEvery(GET_FACEBOOKPAGE_POST_DATA, getFacebookPagePostData);
}
