export const GET_STORE_CURRENCY_REQUEST = 'GET_STORE_CURRENCY_REQUEST';
export const GET_STORE_CURRENCY_SUCCESS = 'GET_STORE_CURRENCY_SUCCESS';
export const GET_STORE_CURRENCY_FAILURE = 'GET_STORE_CURRENCY_FAILURE';

export const SET_GOOGLE_ADS_CURRENCY_REQUEST =
  'SET_GOOGLE_ADS_CURRENCY_REQUEST';
export const SET_GOOGLE_ADS_CURRENCY_SUCCESS =
  'SET_GOOGLE_ADS_CURRENCY_SUCCESS';
export const SET_GOOGLE_ADS_CURRENCY_FAILURE =
  'SET_GOOGLE_ADS_CURRENCY_FAILURE';

export const GET_GOOGLE_ADS_CURRENCY_REQUEST =
  'GET_GOOGLE_ADS_CURRENCY_REQUEST';
export const GET_GOOGLE_ADS_CURRENCY_SUCCESS =
  'GET_GOOGLE_ADS_CURRENCY_SUCCESS';
export const GET_GOOGLE_ADS_CURRENCY_FAILURE =
  'GET_GOOGLE_ADS_CURRENCY_FAILURE';

export const SET_META_ADS_CURRENCY_REQUEST = 'SET_META_ADS_CURRENCY_REQUEST';
export const SET_META_ADS_CURRENCY_SUCCESS = 'SET_META_ADS_CURRENCY_SUCCESS';
export const SET_META_ADS_CURRENCY_FAILURE = 'SET_META_ADS_CURRENCY_FAILURE';

export const GET_META_ADS_CURRENCY_REQUEST = 'GET_META_ADS_CURRENCY_REQUEST';
export const GET_META_ADS_CURRENCY_SUCCESS = 'GET_META_ADS_CURRENCY_SUCCESS';
export const GET_META_ADS_CURRENCY_FAILURE = 'GET_META_ADS_CURRENCY_FAILURE';

export const setGoogleAdsCurrencyRequest = (currency) => ({
  type: SET_GOOGLE_ADS_CURRENCY_REQUEST,
  payload: { currency },
});
export const setGoogleAdsCurrencySuccess = (currency) => ({
  type: SET_GOOGLE_ADS_CURRENCY_SUCCESS,
  payload: currency,
});
export const setGoogleAdsCurrencyFailure = (error) => ({
  type: SET_GOOGLE_ADS_CURRENCY_FAILURE,
  payload: { error },
});

export const getGoogleAdsCurrencyRequest = () => ({
  type: GET_GOOGLE_ADS_CURRENCY_REQUEST,
  payload: {},
});
export const getGoogleAdsCurrencySuccess = (currency) => ({
  type: GET_GOOGLE_ADS_CURRENCY_SUCCESS,
  payload: currency,
});
export const getGoogleAdsCurrencyFailure = (error) => ({
  type: GET_GOOGLE_ADS_CURRENCY_FAILURE,
  payload: { error },
});
export const setMetaAdsCurrencyRequest = (currency) => ({
  type: SET_META_ADS_CURRENCY_REQUEST,
  payload: { currency },
});

export const setMetaAdsCurrencySuccess = (currency) => ({
  type: SET_META_ADS_CURRENCY_SUCCESS,
  payload: currency,
});

export const setMetaAdsCurrencyFailure = (error) => ({
  type: SET_META_ADS_CURRENCY_FAILURE,
  payload: { error },
});

export const getMetaAdsCurrencyRequest = () => ({
  type: GET_META_ADS_CURRENCY_REQUEST,
  payload: {},
});

export const getMetaAdsCurrencySuccess = (currency) => ({
  type: GET_META_ADS_CURRENCY_SUCCESS,
  payload: currency,
});

export const getMetaAdsCurrencyFailure = (error) => ({
  type: GET_META_ADS_CURRENCY_FAILURE,
  payload: { error },
});

export const getStoreCurrencyRequest = () => ({
  type: GET_STORE_CURRENCY_REQUEST,
  payload: {},
});

export const getStoreCurrencySuccess = (currency) => ({
  type: GET_STORE_CURRENCY_SUCCESS,
  payload: currency,
});

export const getStoreCurrencyFailure = (error) => ({
  type: GET_STORE_CURRENCY_FAILURE,
  payload: { error },
});
