import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { getInvoice } from '../../../../queries/Users/userQuery';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import BillingHistory from './BillingHistory';
import urls from '../../../../routes/apiUrls';
import BillingInformationForm from './BillingInformationForm';

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.user,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const Subscription = () => {
  const [loadingState, setLoadingState] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const user = useSelector((state) => state.editProfile);
  const expirationDate = user?.user?.expirationDate
    ? format(new Date(user.user.expirationDate), "MMM do, yyyy")
    : '';

  const getInvoices = async () => {
    setLoadingState(true);
    try {
      const response = await ApploCLI.query({
        query: getInvoice,
        variables: {},
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
        errorPolicy: 'all',
      });
      setLoadingState(false);
      return response.data.getInvoice.map((invoice) => ({
        invoiceId: invoice.payment_id,
        date: invoice.DatePaiement.split('T')[0],
        billing_name: `${invoice.User.firstname} ${invoice.User.lastname}`,
        Plan_Type: invoice.Plan,
        Amount: `TND${(parseInt(invoice.Amount) / 1000).toFixed(2)}`,
        status: invoice.status,
        method: invoice.method,
      }));
    } catch (error) {
      console.error('verification error', error);
      return [];
    }
  };

  useEffect(() => {
    getInvoices().then(setInvoices);
  }, []);

  return (
    <div className="subscription-page">
      <h4 style={{ fontWeight: 'bold', marginBottom: '30px', fontSize: '20px' }}>
        Subscription
      </h4>

      {/* Plan Section */}
      <div className="plan-section mb-4">
        <h5 style={{ fontWeight: '600', fontSize: '17px', marginBottom: '20px' }}>Plan</h5>
        <div className="d-flex justify-content-between planStyle-subscription">
          <div>
            <p className="mb-2">
              Current Plan<span style={{ fontWeight: 'bold', marginLeft: '20px' }}>{user.user.PlanType}</span>
            </p>
            <p className="mb-2">Billed Monthly</p>
            <p className="mb-2">Next Payment: {expirationDate}</p>
          </div>
          <div className="d-flex flex-column">
            <Link to="/settings?tab=3#" className="link-subscription mb2">
              Upgrade Plan
            </Link>
            <Link to="/settings?tab=3#" className="mb-2 link-subscription">
              Switch To A Lower Plan
            </Link>
          </div>
        </div>
      </div>

      {/* Billing Information */}
      <div className="billing-info-section mb-4">
        <h5 style={{ fontWeight: '600', fontSize: '17px', marginBottom: '20px' }}>Billing Information</h5>
        <div className="d-flex justify-content-between planStyle-subscription">
          <div>
            <p className="mb-2" style={{ fontWeight: '500' }}>ConvergenAgency</p>
            <p>Accounts@Convergen.io</p>
          </div>
          <div className="d-flex flex-column">
            <a href="#" onClick={showModal} className="link-subscription mb2">
              Billing history
            </a>
            <a href="#" onClick={showModal}  className="link-subscription">
              Change billing information
            </a>
          </div>
        </div>
      </div>

      {/* Payment Details */}
      <div className="payment-details-section mb-4">
        <h5 style={{ fontWeight: '600', fontSize: '17px', marginBottom: '20px' }}>Payment Details</h5>
        <div className="d-flex justify-content-between planStyle-subscription">
          <div className="d-flex align-items-center">
            <i className="fas fa-credit-card" style={{ marginRight: '10px', color: '#0064E0' }}></i>
            <p className="mb-0">************9432</p>
          </div>
          <div>
            <a href="#" className="link-subscription">Update payment details</a>
          </div>
        </div>
      </div>

      {/* Billing History Modal */}
      <BillingHistory
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        invoices={invoices}
        loadingState={loadingState}
      />
        <BillingInformationForm
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        invoices={invoices}
        loadingState={loadingState}
      />

      {/* Support Contact */}
      <p className="support-info">
        For More Information Please Contact <a href="mailto:admin@live-metrics.tn">admin@live-metrics.tn</a>
      </p>
    </div>
  );
};

export default Subscription;
