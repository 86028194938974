import React, { useEffect } from 'react';
import BarChartVertical from '../common/BarChartVertical';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGAPurchaseJourneyCompraisonRequest,
  getGAPurchaseJourneyRequest,
} from '../../../store/actions';
import {
  formatDate,
  calculateRententionRate,
} from '../common/HelperFunctions/utils';

const GAPurchaseJourney = () => {
  const dispatch = useDispatch();
  const { loading, gaPurchaseJourney } = useSelector(
    (state) => state.googleAnalytics
  );
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);
      if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
        const compareStartDateFormatted = formatDate(
          dateRange.selection2.startDate
        );
        const compareEndDateFormatted = formatDate(
          dateRange.selection2.endDate
        );

        dispatch(
          getGAPurchaseJourneyCompraisonRequest(
            newStartDate,
            newEndDate,
            compareStartDateFormatted,
            compareEndDateFormatted
          )
        );
      } else {
        dispatch(getGAPurchaseJourneyRequest(newStartDate, newEndDate));
      }
    }
  }, [dateRange]);

  // Extract categories from the response object keys
  let categories = [];
  let dataSumsFirst = [];
  let dataSumsSecond = [];

  if (
    gaPurchaseJourney &&
    gaPurchaseJourney.length > 0 &&
    gaPurchaseJourney[0].length > 0
  ) {
    categories = Object.keys(gaPurchaseJourney[0][0]).filter(
      (key) => key !== 'day' && key !== '__typename'
    );

    // Calculate the sums for each category for the first set
    dataSumsFirst = categories.map((category) => {
      return gaPurchaseJourney[0].reduce(
        (sum, item) => sum + item[category],
        0
      );
    });

    if (gaPurchaseJourney[1] && gaPurchaseJourney[1].length > 0) {
      // Calculate the sums for each category for the second set
      dataSumsSecond = categories.map((category) => {
        return gaPurchaseJourney[1].reduce(
          (sum, item) => sum + item[category],
          0
        );
      });
    }
  }

  //rentation rate for the real data
  const retentionRateFirst = calculateRententionRate(dataSumsFirst);

  //rentation rate for the compare data
  const retentionRateSecond = calculateRententionRate(dataSumsSecond);

  const finalCategories = [
    'Session Start',
    'View Product',
    'Add to Cart',
    'Begin Checkout',
    'Purchase',
  ];
  const dataSeries = [
    {
      name: 'Active Users',
      categories: finalCategories,
      data: dataSumsFirst,
      retentionRate: retentionRateFirst,
    },
    {
      name: 'Compare Active Users',
      categories: finalCategories,
      data: dataSumsSecond,
      retentionRate: retentionRateSecond,
    },
  ];

  return (
    <div className="mt-5 mb-4">
      <BarChartVertical dataSeries={dataSeries} />
    </div>
  );
};

export default GAPurchaseJourney;
