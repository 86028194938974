import React, { useCallback, useEffect, useState } from 'react';
import ActiveAccountBar from '../GoogleAnalytics/common/ActiveAccountBar';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import urls from '../../routes/apiUrls';
import { getInstagramocialMediaAccounts } from '../../queries/instagramSocialMedia/queries';


const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.instagramSocialMedia,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});
const InstagramActiveBar = () => {
  const [activeAccount, setActiveAccount] = useState(null);
  const fetchInstagramAccounts = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getInstagramocialMediaAccounts,
        context: {
          headers: {
            Authorization: `Bearer ${
              window.localStorage.getItem('token') || ''
            }`,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      });

      if (data.instagramSocialMediaAccounts) {
        const active = data.instagramSocialMediaAccounts.find(
          (account) => account.active
        );
        setActiveAccount(active || null);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    fetchInstagramAccounts();
  }, [fetchInstagramAccounts]);
  return (
    activeAccount && (
      <ActiveAccountBar
        accountName={activeAccount['instagram_username']}
        switchTabNumber={3}
      />
    )
  );
};

export default InstagramActiveBar;
