import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, CardBody, CardHeader } from 'reactstrap';
import { Button, message, Dropdown, Menu ,Spin} from 'antd';
import ExpensesTable from './ExpensesTable';
import BarChart from '../../GoogleAnalytics/common/BarChart';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import urls from '../../../routes/apiUrls';
import { getInformations } from '../../../queries/Reports/PnLQuery';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OverallCard from '../../OverviewDashboard/overallCardPieChart';
import CostsIcon from '../../../assets/images/CostsIcon.svg';
import ExportIcon from '../../../assets/images/ExportIcon.svg';
import CalenderIcon from '../../../assets/images/CalenderIcon.svg';
import ImportIcon from '../../../assets/images/ImportIcon.svg';
import GrossProfitCards from './GrossProfitCards';
import jsPDF from 'jspdf'; // Import jsPDF
import html2canvas from 'html2canvas'; // Import html2canvas
import autoTable from 'jspdf-autotable';
import logo from '../../../assets/images/Live_Metrics_Logo.png';

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.report,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const dateFormat = (dateString) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateString);
  return months[date.getMonth()];
};

function NProfitAndLoss(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [channel, setChannel] = useState('all');
  const [loadingState, setLoadingState] = useState(true);
  const [pnl, setPnl] = useState([]);
  const [newMinCosts, setNewMinCosts] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const loadingSection = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Spin size="large" />
    </div>
  );

  // Refs to capture charts and tables
  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);
  const chart3Ref = useRef(null);
  const expensesTableRef = useRef();
  const grossProfitCardsRef = useRef();


  const dateFormat = (dateString) => {
    const months = [
      props.t('January'),
      props.t('February'),
      props.t('March'),
      props.t('April'),
      props.t('May'),
      props.t('June'),
      props.t('July'),
      props.t('August'),
      props.t('September'),
      props.t('October'),
      props.t('November'),
      props.t('December'),
    ];
    const date = new Date(dateString);
    return months[date.getMonth()];
  };

  const getData = (channel, selectedYear) => {
    setLoadingState(true);
    ApploCLI.query({
      query: getInformations,
      variables: { years: selectedYear + '', channel },
      context: {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
      fetchPolicy: 'network-only',
    }).then((data) => {
      setLoadingState(false);
      setPnl(data.data.pnl);
      console.log('PNL Data:', data.data.pnl); // Log the full pnl data
      setNewMinCosts(
        data.data.pnl && data.data.pnl.miscCosts ? data.data.pnl.miscCosts : []
      );
    });
  };

  useEffect(() => {
    getData(channel, selectedYear);
  }, [selectedYear, channel]);

  const colors = [
    '#1F77B4',
    '#FF7F0E',
    '#2CA02C',
    '#D62728',
    '#9467BD',
    '#8C564B',
    '#E377C2',
    '#7F7F7F',
    '#BCBD22',
    '#17BECF',
    '#AEC7E8',
    '#FFBB78',
  ];

  const finalData = pnl.map((item, index) => ({
    name: dateFormat(item.created).slice(0, 3),
    value: +item.gross.toFixed(2),
    itemStyle: {
      color: colors[index],
    },
  }));

  const prepareSalesData = () => {
    return pnl.map((item) => [dateFormat(item.created), parseInt(item.sales)]);
  };

  const prepareOrdersAndUnitsData = () => {
    return pnl.map((item) => [
      dateFormat(item.created),
      parseInt(item.orders),
      parseInt(item.units),
    ]);
  };

  const handleMenuClick = (e) => {
    message.info('Year has been changed to ' + e.key);
    setSelectedYear(e.key);
  };

  const menuItems = [
    { label: '2023', key: '2023' },
    { label: '2024', key: '2024' },
  ];

  const menu = (
    <Menu onClick={handleMenuClick}>
      {menuItems.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const captureChart = async (chartRef, yOffset, pdf, width, height, x = 15) => {
    const pageHeight = pdf.internal.pageSize.height;
    const margin = 10;

    // Capture the chart as an image
    const chartCanvas = await html2canvas(chartRef.current, {
      scale: 2,
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true,
    });

    const imgData = chartCanvas.toDataURL('image/png');

    // Calculate image dimensions
    const imgWidth = width || pdf.internal.pageSize.width - 20; // Default width with margins
    const imgHeight = height || (chartCanvas.height * imgWidth) / chartCanvas.width;

    // Check if the current chart fits the remaining space on the page
    if (yOffset + imgHeight > pageHeight - margin) {
      pdf.addPage(); // Add a new page
      yOffset = margin; // Reset yOffset to the top margin
    }

    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', x, yOffset, imgWidth, imgHeight, "", "SLOW");

    // Return the updated yOffset
    return yOffset + imgHeight + 10; // Add spacing between charts
  };

  const exportPDF = async () => {
    if (isExporting) return;
    setIsExporting(true);
  
    const pdf = new jsPDF();
    let yOffset = 15;
  
    // Add logo to the first page
    const imageBase64 = await fetch(logo)
      .then((res) => res.blob())
      .then(
        (blob) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          })
      );
  
    pdf.addImage(imageBase64, 'PNG', 60, 10, 90, 20, "", "SLOW"); // Centered logo
    yOffset += 35; // Adjust spacing after the logo
  
    // Add title for the report
    pdf.setFontSize(18);
    pdf.text('Profit & Loss Report', pdf.internal.pageSize.getWidth() / 2, yOffset, {
      align: 'center',
    });
    yOffset += 15;
  
    // Export Charts with titles
    if (chart1Ref.current) {
      pdf.setFontSize(14);
      pdf.setFont("DM Sans", "bold");
      pdf.setTextColor(218, 100, 37)
      pdf.text('Total Sales Over Time', 15, yOffset);
      yOffset += 5;
      yOffset = await captureChart(chart1Ref, yOffset, pdf, 190, 70);
    }
  
    if (chart2Ref.current) {
      pdf.setFontSize(14);
      pdf.text('Total Monthly Orders/Units', 15, yOffset);
      yOffset += 5;
      yOffset = await captureChart(chart2Ref, yOffset, pdf, 140, 110);
    }
  
    pdf.addPage();
    yOffset = 15;
  
    if (chart3Ref.current) {
      pdf.setFontSize(14);
      pdf.text('Gross Profit Distribution', 15, yOffset);
      yOffset += 10;
      yOffset = await captureChart(chart3Ref, yOffset, pdf, 190, 110);
    }
  
    yOffset += 5;
    // Prepare Rows and Columns for Transposed Table
    const months = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC',
    ];
    const rows = ['Expenses', ...months, 'Total', 'Average'];
  
    const transposedData = [];
  
    // Add Data
    const addRow = (label, rowData) => {
      const total = rowData.reduce((sum, value) => sum + value, 0); // Sum of all months
      const average = total / 12; // Average of total
      const row = [
        label,
        ...rowData.map((value) =>
          value !== undefined && value !== null && value !== 0
            ? value.toFixed(2)
            : '-'
        ),
        total !== 0 ? total.toFixed(2) : '-', // Replace 0 with "-"
        average !== 0 ? average.toFixed(2) : '-', // Replace 0 with "-"
      ];
      transposedData.push(row);
    };
  
    // Calculate Miscellaneous Costs Row
    const miscTotals = Array(12).fill(0);
    pnl.forEach((month, index) => {
      const miscSum = month.miscCosts.reduce(
        (sum, cost) => sum + (cost.total || 0),
        0
      );
      miscTotals[index] = miscSum;
    });
  
    // Add Miscellaneous Costs Row (Exclude its children)
    addRow('Miscellaneous Costs', miscTotals);
  
    // Add Other Expenses
    const otherExpenses = [
      { name: 'Direct Cost Of Goods', key: 'costOfGoods' },
      { name: 'Shipping & Handling', key: 'SnH' },
      { name: 'Refunds & Returns', key: 'refunds' },
      { name: 'Discounts', key: 'discounts' },
      { name: 'Ads', key: 'ads' },
    ];
  
    otherExpenses.forEach((expense) => {
      const monthlyTotals = Array(12).fill(0);
      pnl.forEach((month, index) => {
        monthlyTotals[index] = month[expense.key] || 0;
      });
      addRow(expense.name, monthlyTotals);
    });
  
    // Add Total Row
    const totalRow = Array(12).fill(0);
    pnl.forEach((month, index) => {
      totalRow[index] =
        (month.costOfGoods || 0) +
        (month.SnH || 0) +
        (month.refunds || 0) +
        (month.discounts || 0) +
        (month.ads || 0) +
        month.miscCosts.reduce((sum, cost) => sum + (cost.total || 0), 0);
    });
    addRow('Total', totalRow);
  
    // Transpose the Data
    const transposedTable = rows.map((_, columnIndex) =>
      [rows[columnIndex], ...transposedData.map((row) => row[columnIndex] || '-')]
    );
  
    // Generate Transposed Table
    pdf.setFontSize(16);
    pdf.text('Expenses Table', 15, yOffset);
    yOffset += 10;
  
    autoTable(pdf, {
      head: [['Category', ...transposedTable[0].slice(1)]], // First row as headers
      body: transposedTable.slice(1), // Remaining rows as data
      startY: yOffset,
      theme: 'grid',
      headStyles: { fillColor: [0, 128, 249] },
      styles: { fontSize: 10 },
    });

    pdf.addPage();
    yOffset = 15;
    // Capture GrossProfitCards component
    if (grossProfitCardsRef.current) {
      pdf.setFontSize(14);
      pdf.text('Gross Profit', 15, yOffset);
      yOffset += 10;
      yOffset = await captureChart(grossProfitCardsRef, yOffset, pdf, 185, 50);
    }
    
  
    pdf.save('PnL_Report.pdf');
    setIsExporting(false);
  };
  

  return (
    <div className="page-content">
      <Breadcrumbs
        title={'Profit & Loss Report'}
        breadcrumbItem={'Profit & Loss'}
        pageHeading={'Reports'}
        image={CostsIcon}
      />
      {loadingState ? (
        loadingSection()
      ) : (
        <>
          <Row>
            <Col xl={12}>
              <CardHeader className="d-flex justify-content-between align-items-center mt-4">
                <h2 style={{ fontWeight: '400', color: 'black', fontSize: '15px' }}>
                  {props.t('Total Sales Over Time')}
                </h2>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button
                      onClick={(e) => e.preventDefault()} // Prevents default behavior
                      className='calendar-button'
                    >
                      <img
                        src={CalenderIcon}
                        alt="Export"
                        style={{ marginRight: 10 }}
                      />
                      {selectedYear}
                    </Button>
                  </Dropdown>
                  <Button
                    type="primary"
                    onClick={exportPDF}
                    disabled={isExporting} // Disable the button while exporting
                    className='export-button'
                  >
                    <img src={ExportIcon} alt="Export" style={{ marginRight: 10 }} />
                    {isExporting ? props.t('Exporting...') : props.t('Export PDF')}
                  </Button>
                  <Button
                    onClick={() => setModalVisible(true)}
                    className='import-button'
                    disabled={true} 
                  >
                    <img src={ImportIcon} alt="Export" style={{ marginRight: 10 }} />
                    {props.t('Import Data')}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div ref={chart1Ref}>
                  <BarChart
                    data={prepareSalesData()}
                    dataKey="Sales"
                    comparedData={[]}
                    vertical={true}
                    width="100%"
                    color="#0080F9"
                  />
                </div>
              </CardBody>
            </Col>
          </Row>

          <Row>
            <Col xl={5}>
              <div
                className="d-flex align-items-center justify-content-center mb-4 mt-4"
                ref={chart2Ref}
              >
                <OverallCard
                  data={finalData}
                  type="percentage"
                  style={{ width: '500px', height: '500px' }}
                />
              </div>
            </Col>
            <Col xl={7}>
              <CardHeader className="d-flex justify-content-between align-items-center mt-4">
                <span style={{ fontWeight: '400', color: 'black', fontSize: '15px' }}>
                  {props.t('Total Monthly Orders/Units')}
                </span>
              </CardHeader>
              <CardBody>
                <div ref={chart3Ref}>
                  <BarChart
                    data={prepareOrdersAndUnitsData()}
                    dataKey="Orders"
                    comparedData={prepareOrdersAndUnitsData().map((item) => [
                      item[0],
                      item[2],
                    ])}
                    comparedDataKey="Units"
                    vertical={true}
                    width="100%"
                    color="#F6B43B"
                    comparedColor="#EC1A74"
                  />
                </div>
              </CardBody>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <CardHeader className="d-flex justify-content-between align-items-center mt-4 mb-3">
                <h2
                  style={{ fontWeight: 'bold', color: 'black' }}
                >
                  {props.t(' Expenses ')}
                </h2>
              </CardHeader>
              <CardBody>
                <ExpensesTable
                  ref={expensesTableRef}
                  pnl={pnl}
                  t={props.t}
                  additionalCosts={newMinCosts}
                  className="table-container"
                />
              </CardBody>
            </Col>
          </Row>

          <Row style={{ marginTop: '15px' }}>
            <Col xl={12}>
              <CardHeader className="d-flex justify-content-between align-items-center mt-4 mb-3">
                <h2
                  style={{ fontWeight: 'bold', color: 'black'}}
                >
                  {props.t(' Profit ')}
                </h2>
              </CardHeader>
              <CardBody>
                <div ref={grossProfitCardsRef}>
                  <GrossProfitCards pnl={pnl} t={props.t} />
                </div>
              </CardBody>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

NProfitAndLoss.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NProfitAndLoss));
