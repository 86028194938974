import React, { useState, useEffect } from 'react';
import { Row, Container } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import secureSection from '../../MangeSecurity/MemberSecurity';
import {
  getInstaPageData,
  getInstaPostData,
  getInstaAudienceData,
  getCompareInstaPageData,
} from '../../store/instagram/actions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
import InstagramSocialMediaTable from './InstagramSocialMediaTable';
import InstagramSocialMediaCards from './InstagramSocialMediaCards';
import InstagramSocialMediaGender from './InstagramSocialMediaGender';
import i18next from 'i18next';
import InstagramSocialMediaAge from './InstagramSocialMediaAge';
import { logoMap } from '../../assets/integrations_images/logoMap';
import InstagramActiveBar from './InstagramActiveBar';

const InstagramDashboard = (props) => {
  secureSection();
  const dispatch = useDispatch();
  const { instaPageData, instaPostData, instaAudienceData, isLoading } =
    useSelector((state) => state.Instagram);

  const [tableData, setTableData] = useState([]);
  const [instaPagePosts, setinstaPagePosts] = useState([]);
  const [reach, setreach] = useState([]);
  const [compareReach, setCompareReach] = useState([]);
  const [impressions, setimpressions] = useState([]);
  const [compareImpressions, setCompareImpressions] = useState([]);
  const [follower_count, setfollower_count] = useState([]);
  const [compareFollower_count, setCompareFollower_count] = useState([]);
  const [profile_views, setprofile_views] = useState([]);
  const [compareProfile_views, setCompareProfile_views] = useState([]);
  const [website_clicks, setwebsite_clicks] = useState([]);
  const [compareWebsite_clicks, setCompareWebsite_clicks] = useState([]);

  const [genderdata, setgenderdata] = useState(null);
  const [fans_gender_age, setFans_gender_age] = useState([]);
  const [image, setImage] = useState(null);

  let newTotals = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getInstaAudienceData());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (instaAudienceData.length > 0) {
      setFans_gender_age(instaAudienceData[0].fans_gender_age);
      setImage(instaAudienceData[0].profile_picture_url);

      // Calculate totals for each gender (F, M, U)
      newTotals = instaAudienceData[0].fans_gender_age.reduce(
        (acc, entry) => {
          const gender = entry.dimension_values[0];
          const value = entry.value;

          // Update the corresponding counter based on gender
          acc[gender] += value;

          return acc;
        },
        { F: 0, M: 0, U: 0 }
      );

      // Log the totals
      setgenderdata(newTotals);
    }
  }, [instaAudienceData]);

  // CREATE THE CHART DATA
  const dataChart = [
    { name: i18next.t('Female'), value: genderdata?.F || 0 },
    { name: i18next.t('Male'), value: genderdata?.M || 0 },
    { name: i18next.t('Unknown'), value: genderdata?.U || 0 },
  ];
  // CREATE THE AGE GROUP CHART DATA
  const groupedData = {};

  // Check if fans_gender_age is not null and is an array before iterating
  if (fans_gender_age && Array.isArray(fans_gender_age)) {
    fans_gender_age.forEach((entry) => {
      const ageGroup = entry.dimension_values[1]; // Assuming age is at index 1

      // Initialize the group if it doesn't exist
      if (!groupedData[ageGroup]) {
        groupedData[ageGroup] = { F: 0, M: 0, U: 0 };
      }

      // Update the counts for each gender
      const gender = entry.dimension_values[0];
      const value = entry.value;
      groupedData[ageGroup][gender] += value;
    });
  } else {
    console.log('fans_gender_age is null or not an array');
  }

  // Now groupedData contains the total counts for each age group and gender

  const ageGroups = Object.keys(groupedData);
  const chartData = {
    labels: ageGroups,
    datasets: [
      {
        label: i18next.t('Female'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.F || 0),
        backgroundColor: 'rgb(42, 181, 125)', // Female
      },
      {
        label: i18next.t('Male'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.M || 0),
        backgroundColor: 'rgb(59, 91, 152)', // Male
      },
      {
        label: i18next.t('Unknown'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.U || 0),
        backgroundColor: 'rgb(75, 166, 239)', // Unknown
      },
    ],
  };
  // GET ALL PAGE POST DATA
  useEffect(() => {
    dispatch(getInstaPostData());
  }, []);

  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  // GET PAGE DATA
  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      var startDate = formatDate(dateRange.selection1.startDate);
      var endDate = formatDate(dateRange.selection1.endDate);
      if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
        var compareStartDate = formatDate(dateRange.selection2.startDate);
        var compareEndDate = formatDate(dateRange.selection2.endDate);
        dispatch(
          getCompareInstaPageData(
            startDate,
            endDate,
            compareStartDate,
            compareEndDate
          )
        );
      } else dispatch(getInstaPageData(startDate, endDate));
    }
  }, [dateRange]);
  useEffect(() => {
    if (instaPageData && instaPageData.length > 0) {
      let total_reach = [];
      let total_impressions = [];
      let total_follower_count = [];
      let total_profile_views = [];
      let total_website_clicks = [];

      instaPageData[0].forEach((item) => {
        total_reach.push(item.reach);
        total_impressions.push(item.impressions);
        total_follower_count.push(item.follower_count);
        total_profile_views.push(item.profile_views);
        total_website_clicks.push(item.website_clicks);
      });
      setreach(total_reach);
      setimpressions(total_impressions);
      setfollower_count(total_follower_count);
      setprofile_views(total_profile_views);
      setwebsite_clicks(total_website_clicks);

      let compare_total_reach = [];
      let compare_total_impressions = [];
      let compare_total_follower_count = [];
      let compare_total_profile_views = [];
      let compare_total_website_clicks = [];

      if (instaPageData[1] && instaPageData[1].length > 0) {
        instaPageData[1].forEach((item) => {
          compare_total_reach.push(item.reach);
          compare_total_impressions.push(item.impressions);
          compare_total_follower_count.push(item.follower_count);
          compare_total_profile_views.push(item.profile_views);
          compare_total_website_clicks.push(item.website_clicks);
        });
      }
      setCompareReach(compare_total_reach);
      setCompareImpressions(compare_total_impressions);
      setCompareFollower_count(compare_total_follower_count);
      setCompareProfile_views(compare_total_profile_views);
      setCompareWebsite_clicks(compare_total_website_clicks);
    }
  }, [instaPageData]);

  const exportToPDF = async () => {
    try {
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 10; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  const [secCards, setsecCards] = useState([
    {
      id: 1,
      name: 'Reach',
      data: reach,
      compareData: compareReach,
    },
    {
      id: 2,
      name: 'Impressions',
      data: impressions,
      compareData: compareImpressions,
    },
    {
      id: 3,
      name: 'New followers',
      data: follower_count,
      compareData: compareFollower_count,
    },
    {
      id: 4,
      name: 'Profile views',
      data: profile_views,
      compareData: compareProfile_views,
    },
    {
      id: 5,
      name: 'Website clicks',
      data: website_clicks,
      compareData: compareWebsite_clicks,
    },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 1,
        name: i18next.t('Reach'),
        data: reach,
        compareData: compareReach,
      },
      {
        id: 2,
        name: i18next.t('Impressions'),
        data: impressions,
        compareData: compareImpressions,
      },
      {
        id: 3,
        name: i18next.t('New followers'),
        data: follower_count,
        compareData: compareFollower_count,
      },
      {
        id: 4,
        name: i18next.t('Profile views'),
        data: profile_views,
        compareData: compareProfile_views,
      },
      {
        id: 5,
        name: i18next.t('Website clicks'),
        data: website_clicks,
        compareData: compareWebsite_clicks,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    reach,
    impressions,
    follower_count,
    profile_views,
    website_clicks,
    compareReach,
    compareImpressions,
    compareFollower_count,
    compareProfile_views,
    compareWebsite_clicks,
  ]);

  return (
    <div className="page-content">
        <title>
          {i18next.t('Instagram Social Media Dashboard')} | Convergen-Metrics -
          E-com SaaS
        </title>
      <Container fluid className="print p-4">
        <InstagramActiveBar />
        <Breadcrumbs
          title={i18next.t('Instagram Social Media Dashboard')}
          breadcrumbItem={i18next.t('Instagram Media Dashboard')}
          image={logoMap['Instagram Social Media']}
        />
        <Row className=" justify-content-center my-5">
          <InstagramSocialMediaCards secCards={secCards} />
        </Row>
        <Breadcrumbs
          title={i18next.t('Instagram Posts Last 30 Days')}
          breadcrumbItem={i18next.t('Instagram Media Dashboard')}
          image={logoMap['Instagram Social Media']}
        />
        <Row className="mb-4">
          <InstagramSocialMediaTable
            instaPostData={instaPostData}
            loading={isLoading}
          />
        </Row>

        <div className="d-flex align-items-center justify-content-center gap-5  ">
          <div style={{ flex: 1 }}>
            <Breadcrumbs
              title={i18next.t('Instagram Audience Gender')}
              breadcrumbItem={i18next.t('Instagram Media Dashboard')}
              image={logoMap['Instagram Social Media']}
            />
            <InstagramSocialMediaGender dataChart={dataChart} />
          </div>
          <div style={{ flex: 2 }}>
            <Breadcrumbs
              title={i18next.t('Gender and age group Bracket')}
              breadcrumbItem={i18next.t('Instagram Media Dashboard')}
              image={logoMap['Instagram Social Media']}
            />
            <InstagramSocialMediaAge chartData={chartData} />
          </div>
          {/* <div className="d-print-none mt-4">
            <div className="float-end">
              <button
                type="button"
                onClick={exportToPDF}
                className="btn btn-primary waves-effect waves-light w-md"
              >
                <div className="d-flex align-items-center">
                  {' '}
                  <i
                    className="mdi mdi-download d-block font-size-16"
                    style={{ color: 'white' }}
                  ></i>
                  <span className="ms-2">{i18next.t('Download')}</span>{' '}
                </div>
              </button>
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};
InstagramDashboard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InstagramDashboard));
