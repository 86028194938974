import React,{useState} from 'react';

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

// ** Icons Imports



// const AppShop = new ApolloClient({
//   uri: urls.shopify
// })
// const ApploCLI = new ApolloClient({
//   uri: urls.shopify
// });



function ShopifyCard(props) {
//   const [info, setInfo] = useState(null);
   const [step, setStep] = useState(1);
//   const [url, setUrl] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [startEdit, setStartEdit] = useState(true);
//   const [reachedFinalStep, setReachedFinalStep] = useState(false);
//   const [filterStr, setFilterStr] = useState("");
//   const [deleteLoading, setDeleteLoading] = useState(false);
//   const [refetch, setRefetch] = useState(false);
//   const [stores, setStores] = useState([]);
//   const [inputData, setInputData] = useState("");
//   const [modal_standard, setmodal_standard] = useState(false);
//   const [isShopify, setIsShopify] = useState(false);
//   const [validation, setValidation] = useState({
//     unm: null,
//     fnm: null,
//     lnm: null,
//   })
//   const data = [
//     {
//       icon: <Link2 className='icon' />,
//       title: props.t('Allow Access 🤟🏻'),
//       subtitle: props.t('Allow LiveMetrics to access to your shopify account')
//     },
//     {
//       icon: <Key className='icon' />,
//       title: props.t('Add Your Domain Store 👩🏻‍💻'),
//       subtitle: props.t('Just add your domain store ')
//     },
//     {
//       icon: <Check className='icon' />,
//       title: props.t('Your Account is ready for use 🎉'),
//       subtitle: props.t('You  will get 7 days free trial')
//     }
//   ]
//   //ShopifyIcon = require(`@src/assets/images/integrations/${shopifyLogo}`).default
//  useEffect(() => {
//   AppShop.query({
//     query: checkSales,
//     context: {
//       headers: {
//         Authorization: window.localStorage.getItem("token")
//           ? `Bearer ${window.localStorage.getItem("token")}`
//           : "",
//       },
//     },
//     errorPolicy: "all",
//     fetchPolicy: "network-only",
//   }).then((data) => {
//     if (data.data.getIntegration) {
//       if (data.data.getIntegration.type == 1) {
//         setStep(2)
//         setReachedFinalStep(true)
//         setIsShopify(true)
//         setStores(data.data.getIntegration)
       
//       }
//     }
//   });
//  }, [stores]);
//   const integrateShopify = (raw_data) => {
//     console.log(raw_data);
//     try {
//       console.log("HEREz")
//       const data = JSON.parse(raw_data);
//       console.log("DATA", data);
//       ApploCLI.mutate({
//         mutation: getFromShopify,
//         context: {
//           headers: {
//             Authorization: window.localStorage.getItem("token")
//               ? `Bearer ${window.localStorage.getItem("token")}`
//               : "",
//           },
//         },
//         variables: {
//           code: data.code,
//           host: data.host,
//           hmac: data.hmac,
//           shop: data.shop,
//           timestamp: data.timestamp,
//         },
//         errorPolicy: "all",
//       }).then((data) => {
//         if (data.data.integration !== null) {
//           if (data.data.integration.error) {
//             SweetAlert.fire({
//               icon: 'error',
//               title: 'Integration Error',
//               text: data.data.integration.error,
//             })
//           } else {
//             setStep(2);
//             setReachedFinalStep(true);
//           }
//         }
//       });
//     } catch (error) {
//     }
//   };
  
//   const openPopup = () => {
//     const apiKey = '2967d0d5ad9a22ad58535f52e77436ce';
// const redirectUri = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? 'http://localhost:3000/integrations': `https://${window.location.hostname}/integrations`;
// const permissionUrl = `/oauth/authorize?client_id=${apiKey}&scope=read_checkouts,read_shopify_payments_payouts,read_all_orders,read_content,write_content,read_themes,write_themes,read_products,write_products,read_customers,write_customers,read_orders,write_orders,read_script_tags,write_script_tags,read_fulfillments,write_fulfillments,read_shipping,write_shipping,read_inventory,write_inventory&redirect_uri=${redirectUri}`;
    
// let setLoading = true;
// let windowObjectReference = null;
// let previousUrl = null;
// let interval = null;
// const strWindowFeatures = "toolbar=no, menubar=no, width=1360, height=700, top=100, left=108";

// if (windowObjectReference === null || windowObjectReference.closed) {
//     windowObjectReference = window.open(`https://${inputData}.myshopify.com/admin${permissionUrl}`, "", strWindowFeatures);
//     var timer = setInterval(function () {
//         if (windowObjectReference.closed) {
//             setLoading = false;
//             clearInterval(timer);
//         }
//     }, 1000);
// } else if (previousUrl !== url) {
//     windowObjectReference = window.open(url, "", strWindowFeatures);
//     windowObjectReference.focus();
//     var timer = setInterval(function() {
//         if (windowObjectReference.closed) {
//             setLoading = false;
//             clearInterval(timer);
//         }
//     }, 1000);
// }
//     interval = window.setInterval(() => {
//       try {
//         let temp = windowObjectReference.location;
//         if
//           (temp.hostname === "localhost" || temp.hostname === "app.live-metrics.io") {
//           window.clearInterval(interval);
//           console.log(temp.href);
//           var result = '{';
//           var test = temp.href.split("?")[1];
//           for (let i in test.split("&")) {
//             let item = test.split("&")[i];
//             result = result + `"${item.split("=")[0]}":"${item.split("=")[1]}"`;
//             if (i < (test.split("&").length - 1)) result = result + ","
//           }
//           result = result + '}'
//           try {
//             JSON.parse(result);
//             integrateShopify(result);
//             windowObjectReference.close();
//           setLoading(false);
//           setStep(2);
//           setReachedFinalStep(true);    
//           window.location.reload();
       
//           } catch (e) {
//             windowObjectReference.close();
//             SweetAlert.fire({
//               title: props.t("Shopify Integration Error"),
//               icon: props.t("error"),
//               text: props.t("There was a technical error when integrating your store. Please contact our support at: support@livemetrics.tn")
//             }).then((val) => {
//               setLoading(false);
//             })
//           }
//         }
//       } catch (error) {
//       }
//     }, 1000);
//     previousUrl = url;
//   };
//   const deleteConnection = () => {
//     SweetAlert.fire({
//       title: props.t("Are you sure?"),
//       text: props.t("You won't be able to revert this!"),
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: props.t("Yes, delete it!"),
//     }).then((result) => {
//       if (result.value) {
//         ApploCLI.mutate({
//           mutation: deletePlatform,
//           context: {
//             headers: {
//               Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
//             }
//           },
//           errorPolicy: 'all',
//         }).then((data) => {
//           if (data.data.delete.status) {
//             setInfo(null);
//             setStep(1);
//             SweetAlert.fire(
//               props.t("Deleted!"),
//               props.t("Your Store has been deleted."),
//               props.t("success")
//             )
//             window.location.replace('/integrations');
//           }
//         })
//       }
//     })
//   };
//   function tog_standard() {
//     setmodal_standard(!modal_standard)
//     removeBodyCss()
//   }
//   function removeBodyCss() {
//     document.body.classList.add("no_padding")
//   }
  const firstStep = () => {
    return (
      <React.Fragment>
   
</React.Fragment>
    );
  }
  // const filteredAccounts = stores.filter((e) =>
  //   e.name.toUpperCase().includes(filterStr.toUpperCase())
  // );
  const secondStep = () => {
    return (
      <React.Fragment>
        
      </React.Fragment >
    )
  };
  const thirdStep = () => {
    return (
      <React.Fragment>
 
</React.Fragment>
    )
  }
  const loadingSection = () => {
    return (
    <>
    </>
    );
  };
  const renderSections = () => {
    switch (step) {
      case 3:
        return thirdStep();
      case 0:
        return loadingSection();
      case 1:
        return firstStep();
      case 2:
        return secondStep();
      default:
        return loadingSection();
    }
  };
  return <div>{renderSections()}</div>;
}
ShopifyCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(ShopifyCard));
//export default ShopifyCard;
