import React, { useState, useEffect, useRef } from 'react';
import WooDataCards from './WooDataCards';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Col, Container, Row, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

import urls from '../../routes/apiUrls';
import { getData } from '../../queries/Dashboard/WooDashboardQ';
import { getSalesPlatform } from '../../queries/Shopify/queries';
import { alert } from '../Alerts/NotLinkedAccountAlert';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import secureSection from '../../MangeSecurity/MemberSecurity';
import ReactEcharts from 'echarts-for-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../assets/scss/theme.scss';
import ChartFilter from './ChartFilter';
import GoogleAdsChart from '../GoogleDashboard/Google Ads Overview/GoogleAdsChart/GoogleAdsChart';
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

// const ApploShopifyCLI = new ApolloClient({
//   link: new HttpLink({
//     uri: urls.shopify,
//     headers: {
//       Authorization: window.localStorage.getItem('token')
//         ? `Bearer ${window.localStorage.getItem('token')}`
//         : '',
//     },
//     credentials: 'include', // Include cookies for cross-origin requests
//   }),
//   cache: new InMemoryCache(),
// });

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.woodashboard,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate)); // Push a new date to the array
    currentDate.setDate(currentDate.getDate() + 1); // Increment the date by one day
  }

  return dates;
};

function WooDashboard(props) {
  secureSection();

  const { dateRange } = useSelector((state) => state.dateRangeReducer);



  const [metrics, setMetrics] = useState({
    sales: [],
    compareSales: [],
    GrossProfit: [],
    compareGrossProfit: [],
    NumberOrders: [],
    compareNumberOrders: [],
    AOV: [],
    compareAOV: [],
  });

  const [dataDashboard, setDataDashboard] = useState([]);
  const [loadingState, setLoadingState] = useState(false);

  const newCompareStartDate = formatDate(dateRange.selection2.startDate);
  const newCompareEndDate = formatDate(dateRange.selection2.endDate);
  const newStartDate = formatDate(dateRange.selection1.startDate);
  const newEndDate = formatDate(dateRange.selection1.endDate);
  useEffect(() => {
    if (dateRange?.selection1?.startDate && dateRange?.selection1?.endDate) {
      setLoadingState(true);

      var variables = { startDate: newStartDate, endDate: newEndDate };

      if (dateRange.selection2?.startDate && dateRange.selection2?.endDate) {
        variables.compareEndDate = newCompareEndDate;
        variables.compareStartDate = newCompareStartDate;
      }
      ApploCLI.query({
        query: getData,
        variables,
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      }).then((data) => {
        setDataDashboard(data.data.get);
      });
      setLoadingState(false);
    }
  }, [dateRange]);
  useEffect(() => {
    if (dataDashboard?.report?.length > 0) {
      const updatedMetrics = {
        sales: [],
        GrossProfit: [],
        NumberOrders: [],
        AOV: [],
      };
      const updatedCompareMetrics = {
        compareSales: [],
        compareGrossProfit: [],
        compareNumberOrders: [],
        compareAOV: [],
      };

      // First Report (Current Data)
      if (dataDashboard.report[0]?.length > 0) {
        dataDashboard.report[0].forEach((item) => {
          updatedMetrics.sales.push(item.sales || 0);
          updatedMetrics.GrossProfit.push(item.GrossProfit || 0);
          updatedMetrics.NumberOrders.push(item.NumberOrders || 0);
          updatedMetrics.AOV.push(item.AOV || 0);
        });
      }

      // Second Report (Comparison Data)
      if (
        dataDashboard.report.length > 1 &&
        dataDashboard.report[1]?.length > 0
      ) {
        dataDashboard.report[1].forEach((item) => {
          updatedCompareMetrics.compareSales.push(item.sales || 0);
          updatedCompareMetrics.compareGrossProfit.push(item.GrossProfit || 0);
          updatedCompareMetrics.compareNumberOrders.push(
            item.NumberOrders || 0
          );
          updatedCompareMetrics.compareAOV.push(item.AOV || 0);
        });
      }

      setMetrics((prevMetrics) => ({
        ...prevMetrics,
        ...updatedMetrics,
        ...updatedCompareMetrics,
      }));
    }
  }, [dataDashboard]);
  

  const series = [
    {
      name: 'sales',
      data: metrics.sales,
      compareData: metrics.compareSales,
      value: 'sales',
    },
    {
      name: 'grossProfit',
      data: metrics.GrossProfit,
      compareData: metrics.compareGrossProfit,
      value: 'grossProfit',
    },

    {
      name: 'nmbrOfOrders',
      data: metrics.NumberOrders,
      compareData: metrics.compareNumberOrders,
      value: 'nmbrOfOrders',
    },
  ];
   const colors = [
     {
       lineColor: '#9B5C8F',
       fillColor: [
         { offset: 0, color: '#D740BA' }, // Top gradient color
         { offset: 0.5, color: '#FFB7F1' }, // Middle gradient color
         { offset: 1, color: 'rgba(252, 229, 229, 0.31)' }, // Bottom color
       ],
     },
     {
       lineColor: '#F6B43B',
       fillColor: [
         {
           offset: 0,
           color: ' #F6B43B',
         },
         {
           offset: 1,
           color: '#D9D9D9',
         },
       ],
     },
   ];

  
  return (
    <React.Fragment>
      <div className="page-content">
        <title>
          {props.t('WooCommerce Dashboard')} | LiveMetrics - E-COM SASS
        </title>
        <Container fluid>
          {
            <Breadcrumbs
              title={props.t('Dashboards')}
              breadcrumbItem={props.t('WooCommerce Dashboard')}
              pageHeading={props.t('Woocommerce Charts')}
              image={WooCommercepIcon}
            />
          }
          <Row>
              <GoogleAdsChart series={series} colors={colors} />
          </Row>
          <Row>
            <div>
              {' '}
              {dateRange !== '' && (
                <div
                  style={{
                    display: 'flex',
                    marginTop: '50px',
                    borderRadius: '5px',
                    width: 'max-content',
                    height: '30px',
                    padding: '15px',
                  }}
                >
                  <section
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '15px',
                    }}
                  >
                    <div
                      style={{
                        width: '26px',
                        height: '26px',
                        background: '#F6B43B',
                        borderRadius: '4px',
                        marginRight: '5px',
                        marginTop: '-2px',
                      }}
                    ></div>
                    <span
                      style={{
                        color: '#0C1426',
                        fontSize: '15px',
                        fontWeight: 'bold',
                      }}
                    >
                      {newStartDate} {newEndDate}
                    </span>
                  </section>
                  {dateRange.selection2.startDate && (
                    <section
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '15px',
                      }}
                    >
                      <div
                        style={{
                          width: '26px',
                          height: '26px',
                          background: '#EC1A74',
                          borderRadius: '4px',
                          marginRight: '5px',
                          marginTop: '-2px',
                        }}
                      ></div>
                      <span
                        style={{
                          color: '#0C1426',
                          fontSize: '15px',
                          fontWeight: 'bold',
                        }}
                      >
                        {newCompareStartDate} {newCompareEndDate}
                      </span>
                    </section>
                  )}
                </div>
              )}
              <br></br>
            </div>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
          
            <WooDataCards metrics={metrics} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
WooDashboard.propTypes = {
  children: PropTypes.element.isRequired,
};
export default withRouter(withTranslation()(React.memo(WooDashboard)));
//export default React.memo(WooDashboard);
