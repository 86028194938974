import React, { useState, useEffect, useRef } from 'react';
import { Card } from "semantic-ui-react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import { getMetricsByCampaignsPerDate } from '../../../store/googleAds/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, CardBody, FormGroup,
  InputGroup,
} from 'reactstrap';
import { DateRange } from "react-date-range";
import { Pie } from "react-chartjs-2"
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;

function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
export default function GoogleCampaignsDetails(props) {
  const dispatch = useDispatch();
  const { metrcisbyCompaign, ads } = useSelector((state) => state.GoogleAds)
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date:
      [
        window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
        window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone }))

      ]
  });
  const [activeBtn, setActiveBtn] = useState(window.localStorage.getItem("activeBtn") ? JSON.parse(window.localStorage.getItem("activeBtn")) : {
    today: 1,
    yesterday: 0,
    last7days: 0,
    last30day: 0,
    last60day: 0,
    month: 0,
    other: 0,
  });


  const [chosedDate, setChosedDate] = useState([{
    startDate: window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    endDate: window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    key: 'selection'
  }]);
  console.log("chosedDate", chosedDate)
  // const [metrcisbyCompaign, setMetrcisbyCompaign] = useState([])
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([{
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }]);
    setToggleDate(false)
    window.localStorage.setItem("start", startDate);
    window.localStorage.setItem("end", endDate);
  };
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(new Date(chosedDate[0].startDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" }))),
          new Date(new Date(chosedDate[0].endDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" })))
        ]
      });
      setToggleDate(false)
    }
  }, [currentFocus])

  console.log("metrcisbyCompaign", metrcisbyCompaign)
  const [activeTab, setActiveTab] = useState('1');
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleCampaignChange = (event) => {
    const selectedCampaignId = event.target.value;
    const campaign = metrcisbyCompaign.find((element) => element.campaign_id === selectedCampaignId);
    setSelectedCampaign(campaign || null);
  };
  const dataMapProductCategoryName = metrcisbyCompaign.map((item) => { return item.campaign_name })
  const dataMapProductCategoryCount = metrcisbyCompaign.map((item) => { return item.metrics.totalConversions })
 
  // Function to generate dynamic background and hover colors
function generateColors(count) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    // Generate a random color or use a color library
    // Here's a simple example using random colors
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    colors.push(randomColor);
  }
  return colors;
}

// Use the function to generate dynamic colors
const backgroundColor = generateColors(dataMapProductCategoryCount.length);
const hoverBackgroundColor = backgroundColor.slice(); // Use the same colors for hover

  useEffect(() => {
    window.localStorage.setItem("start", chosedDate[0].startDate);
    window.localStorage.setItem("end", chosedDate[0].endDate);
  }, [chosedDate]);
  useEffect(() => {
    setSelectedCampaign(null);
    var newStartDate = datePattern.exec(chosedDate[0].startDate && chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(chosedDate[0].endDate && chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    dispatch(getMetricsByCampaignsPerDate(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1]))
    setSelectedCampaign(null);



  }, [dateRage, chosedDate, dispatch])
  const data = {
    labels: dataMapProductCategoryName,
    datasets: [
      {
        data: dataMapProductCategoryCount,
        backgroundColor,
        hoverBackgroundColor,
        hoverBorderColor: "#fff",
      },
    ],
  }
  return (
    <React.Fragment>
      <div className="page-content">
          <title>GoogleAds Dashbaord | Convergen-Metrics - E-com SaaS</title>

        <div className="container-fluid">
          <Breadcrumbs
            title="Google Dashboard"
            breadcrumbItem="Google Campaigns Details"
            pageHeading="Google Dashboard"
          />
          <Row>

            <Col md={3}>
              <FormGroup className="mb-4 ">
                <InputGroup>
                  <div className="DateContainer">
                    <div>
                      <div className="divDateContainer" >
                        <div ref={wrapperRef}>
                          <button
                            className="btn btn-primary w-50 waves-effect waves-light"
                            onClick={() => setToggleDate(!toggleDate)}
                          >
                            {chosedDate[0] !== null
                              ? [
                                chosedDate[0].startDate.toLocaleString("en", {
                                  month: "short",
                                }),
                                " ",
                                chosedDate[0].startDate.getDate(),
                                " ",
                                chosedDate[0].startDate.getFullYear(),
                                "  -  ",
                                chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString("en", {
                                  month: "short",
                                }),
                                " ",
                                chosedDate[0].endDate && chosedDate[0].endDate.getDate(),
                                " ",
                                chosedDate[0].endDate && chosedDate[0].endDate.getFullYear(),
                              ]
                              : null}
                          </button>
                          {toggleDate && (
                            <div style={{ position: "relative", display: "flex" }}>
                              <div className="calenderDivStyle">\
                                <div className="btnsCalendar">
                                  <DateRange
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    onRangeFocusChange={item => { setCurrentFocus(item) }}
                                    onChange={item => { setChosedDate([item.selection]); setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 }); window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 })) }}
                                    ranges={chosedDate}
                                    dragSelectionEnabled={true}
                                    rangeColors={["#6689F2"]}
                                    months={2}
                                    direction="horizontal"
                                  />
                                </div>
                                <div className="calendarDiv">
                                  <div className="btnCalendarContainer">

                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <br />

                        </div>
                      </div>
                    </div>
                  </div>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <select className="btn btn-primary" value={selectedCampaign ? selectedCampaign.campaign_id : "1"} onChange={handleCampaignChange}>
            <option value="1">Select campaign</option>
            {metrcisbyCompaign.map((campaign) => (
              <option key={campaign.campaign_id} value={campaign.campaign_id}>
                {campaign.campaign_name}
              </option>
            ))}
          </select>

          <Row>
            <div className="mt-5">
              <Col xl={11} md={4}>
                <Row>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Cost
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="  mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalCost
                                    ? selectedCampaign.metrics.totalCost.toFixed(2)
                                    : '-'}
                                  duration={10}


                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Clicks
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalClicks
                                    ? selectedCampaign.metrics.totalClicks
                                    : '-'}
                                  duration={10}

                                />
                              </h4>

                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Conversions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalConversions
                                    ? selectedCampaign.metrics.totalConversions.toFixed(2)
                                    : '-'}
                                  duration={10}

                                />
                              </h4>

                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Impressions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalImpressions
                                    ? selectedCampaign.metrics.totalImpressions
                                    : '-'}
                                  duration={10}
                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xl={11} md={4}>
                <Row>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            New sessions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalNewSessions
                                    ? selectedCampaign.metrics.totalNewSessions
                                    : '-'}
                                  duration={10}
                                  decimals={2}

                                  prefix=""

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Avg.Cost
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalAverageCost
                                    ? selectedCampaign.metrics.totalAverageCost.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  decimals={2}

                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Avg.Cpc
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalAverageCpc
                                    ? selectedCampaign.metrics.totalAverageCpc.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  decimals={2}

                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Avg.Ctr
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.averageCtr
                                    ? selectedCampaign.metrics.averageCtr.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  decimals={2}

                                  prefix="%"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </Col>
              <Col xl={11} md={4}>
                <Row>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            interactions
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalInteractions
                                    ? selectedCampaign.metrics.totalInteractions
                                    : '-'}
                                  duration={10}


                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            Search Impr. Share
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalSearchImpressionShare
                                    ? selectedCampaign.metrics.totalSearchImpressionShare.toFixed(2)
                                    : '-'}
                                  duration={10}

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            conversions value
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.totalConversionsValue
                                    ? selectedCampaign.metrics.totalConversionsValue.toFixed(2)
                                    : '-'}
                                  duration={10}

                                  prefix="$"
                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            ROAs
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.ROAs
                                    ? selectedCampaign.metrics.ROAs.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  prefix="x"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                </Row>
              </Col>
              <Col xl={11} md={4}>
                <Row>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            conversion Rate
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.conversionRate
                                    ? selectedCampaign.metrics.conversionRate.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  decimals={2}
                                  prefix="%"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="card-h-100">
                      <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                          <span className="my-0 text-primary">
                            <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                            AOV
                          </span>
                          <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={6}>
                            <div className="mb-3">
                              <h4>
                                <CountUp
                                  start={0}
                                  end={selectedCampaign && selectedCampaign.metrics.AOV
                                    ? selectedCampaign.metrics.AOV.toFixed(2)
                                    : '-'}
                                  duration={10}
                                  decimals={2}
                                  prefix="$"

                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xl={10} md={4}>
                <Row>
                  <CardBody>
                    <Pie width={474} height={260} data={data} />
                  </CardBody>
                </Row>
              </Col>



            </div>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
