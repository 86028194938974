import React, { useEffect, useState } from 'react';

import { Col } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomisableMetricCards from './CustomisableMetricCards';
import CampaignTable from '../Socials/campaignTable';
import {
  calculateGrowthPercentage,
  formatNumberWithSpaces,
  getExchangeRateDuo,
} from '../GoogleAnalytics/common/HelperFunctions/utils';

const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

const FacebookAdsOverview = (props) => {
  const [conversionRate, setConversionRate] = useState(1);
  useEffect(() => {
    const fetchConversionRates = async () => {
      const rate = await getExchangeRateDuo(
        props?.facebook?.[0]?.spend_currency_code,
        props?.facebook?.[0]?.revenue_currency_code
      );
      setConversionRate(rate);
    };
    if (
      props?.facebook?.[0]?.spend_currency_code &&
      props?.facebook?.[0]?.revenue_currency_code
    )
      fetchConversionRates();
  }, [
    props?.facebook?.[0]?.spend_currency_code,
    props?.facebook?.[0]?.revenue_currency_code,
  ]);
  let CPA, AOV, CR, ROAS, CPC, CTR;
  let CPA_compare;
  let AOV_compare;
  let CR_compare;
  let ROAS_compare;
  let CPC_compare;
  let CTR_compare;
  if (props.facebook && props.facebook.length > 0) {
    CPA = props.facebook[0].totalCost / props.facebook[0].totalConversions;
    AOV =
      props.facebook[0].totalconversionValue /
      props.facebook[0].totalConversions;
    CR =
      (props.facebook[0].totalConversions /
        props.facebook[0].totalLandingPageViews) *
      100;
    ROAS =
      (props.facebook[0].totalconversionValue * conversionRate) /
      props.facebook[0].totalCost;
    CPC = props.facebook[0].totalCost / props.facebook[0].totalClicks;
    CTR =
      (props.facebook[0].totalClicks / props.facebook[0].totalImpressions) *
      100;
    if (props.facebook && props.facebook.length > 1) {
      CPA_compare =
        props.facebook[1].totalCost / props.facebook[1].totalConversions;
      AOV_compare =
        props.facebook[1].totalconversionValue /
        props.facebook[1].totalConversions;
      CR_compare =
        (props.facebook[1].totalConversions /
          props.facebook[1].totalLandingPageViews) *
        100;
      ROAS_compare =
        props.facebook[1].totalconversionValue / props.facebook[1].totalCost;
      CPC_compare = props.facebook[1].totalCost / props.facebook[1].totalClicks;
      CTR_compare =
        (props.facebook[1].totalClicks / props.facebook[1].totalImpressions) *
        100;
    }
  }

  const optionGroup = [
    {
      key: 'spend',
      label: 'Spend',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        props.facebook[0]?.totalCost != null
          ? `${formatNumberWithSpaces(
              Number(props.facebook[0].totalCost).toFixed(2)
            )} ${props.facebook[0].spend_currency_code}`
          : '0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalCost,
        props?.facebook?.[1]?.totalCost
      ),
    },
    {
      key: 'Revenue',
      label: 'Revenue',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        props.facebook[0]?.totalconversionValue != null
          ? `${formatNumberWithSpaces(
              Number(props.facebook[0].totalconversionValue).toFixed(2)
            )} ${props.facebook[0].revenue_currency_code}`
          : '$0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalconversionValue,
        props?.facebook?.[1]?.totalconversionValue
      ),
    },
    {
      key: 'Clicks',
      label: 'Clicks',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        props.facebook[0]?.totalClicks != null
          ? formatNumberWithSpaces(props.facebook[0].totalClicks)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalClicks,
        props?.facebook?.[1]?.totalClicks
      ),
    },
    {
      key: 'Conversions',
      label: 'Conversions',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        props.facebook[0]?.totalConversions != null
          ? formatNumberWithSpaces(props.facebook[0].totalConversions)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalConversions,
        props?.facebook?.[1]?.totalConversions
      ),
    },
    {
      key: 'Impressions',
      label: 'Impressions',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        props.facebook[0]?.totalImpressions != null
          ? formatNumberWithSpaces(props.facebook[0].totalImpressions)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalImpressions,
        props?.facebook?.[1]?.totalImpressions
      ),
    },
    {
      key: 'Reach',
      label: 'Reach',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        props.facebook[0]?.totalReach != null
          ? formatNumberWithSpaces(props.facebook[0].totalReach)
          : '0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalReach,
        props?.facebook?.[1]?.totalReach
      ),
    },
    {
      key: 'CPA',
      label: 'CPA',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        CPA != null &&
        isFinite(CPA)
          ? `$${Number(CPA).toFixed(2)}`
          : '$0',
      percentage: calculateGrowthPercentage(
        props?.facebook?.[0]?.totalconversionValue,
        props?.facebook?.[1]?.totalconversionValue
      ),
    },
    {
      key: 'CPC',
      label: 'CPC',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        CPC != null &&
        !isNaN(CPC)
          ? `$${Number(CPC).toFixed(2)}`
          : '$0',
      percentage: calculateGrowthPercentage(CPC, CPC_compare),
    },
    {
      key: 'AOV',
      label: 'AOV',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        AOV != null &&
        !isNaN(AOV)
          ? `$${Number(AOV).toFixed(2)}`
          : '$0',
      percentage: calculateGrowthPercentage(AOV, AOV_compare),
    },
    {
      key: 'CR',
      label: 'CR%',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        CR != null &&
        !isNaN(CR)
          ? `${Number(CR).toFixed(2)}%`
          : '0',
      percentage: calculateGrowthPercentage(CR, CR_compare),
    },
    {
      key: 'ROAS',
      label: 'ROAS',
      value:
        props?.facebook &&
        props?.facebook?.length > 0 &&
        ROAS != null &&
        !isNaN(ROAS)
          ? `${Number(ROAS).toFixed(2)}`
          : '0',
      percentage: calculateGrowthPercentage(ROAS, ROAS_compare),
    },
  ];

  return (
    <div className="mt-5">
      <Col xl={12}>
        <Breadcrumbs image={FacebbokAdsIcon} title="Facebook Ads performance" />
        <CustomisableMetricCards metricsOption={optionGroup} />

        <CampaignTable overview={true} />
      </Col>
    </div>
  );
};

FacebookAdsOverview.propTypes = {
  t: PropTypes.func.isRequired,
  facebook: PropTypes.array.isRequired,
  dateRange: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(FacebookAdsOverview));