import {
  INTEGRATE_GOOGLE_ANALYTICS_REQUEST,
  INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
  INTEGRATE_GOOGLE_ANALYTICS_FAILURE,
  GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST,
  GET_GOOGLE_ANALYTICS_PRODUCTS_SUCCESS,
  GET_GOOGLE_ANALYTICS_PRODUCTS_FAILURE,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_SUCCESS,
  GET_GOOGLE_ANALYTICS_GAPLATFORM_FAILURE,
  GET_GOOGLE_ANALYTICS_USERS_FAILURE,
  GET_GOOGLE_ANALYTICS_USERS_SUCCESS,
  GET_GOOGLE_ANALYTICS_USERS_REQUEST,
  GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST,
  GET_GOOGLE_ANALYTICS_ACCOUNTS_SUCCESS,
  GET_GOOGLE_ANALYTICS_ACCOUNTS_FAILURE,
  GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST,
  GET_GOOGLE_ANALYTICS_AUDIENCE_SUCCESS,
  GET_GOOGLE_ANALYTICS_AUDIENCE_FAILURE,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_SUCCESS,
  GET_GOOGLE_ANALYTICS_AFFILIATIONS_FAILURE,
  GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST,
  GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_SUCCESS,
  GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_FAILURE,
} from './actionsTypes';

//INTEGRATE GOOGLE ANALYTICS
export const integrateGoogleAnalyticsRequest = (code) => ({
  type: INTEGRATE_GOOGLE_ANALYTICS_REQUEST,
  payload: { code },
});

export const integrateGoogleAnalyticsSuccess = (data) => ({
  type: INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
  payload: data,
});

export const integrateGoogleAnalyticsFailure = (error) => ({
  type: INTEGRATE_GOOGLE_ANALYTICS_FAILURE,
  payload: error,
});

//get google analytics accounts

export const getGoogleAnalyticsAccountsRequest = () => ({
  type: GET_GOOGLE_ANALYTICS_ACCOUNTS_REQUEST,
});

export const getGoogleAnalyticsAccountsSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_ACCOUNTS_SUCCESS,
  payload: data,
});

export const getGoogleAnalyticsAccountsFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_ACCOUNTS_FAILURE,
  payload: error,
});

export const getGoogleAnalyticsProductsRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_PRODUCTS_REQUEST,
  payload: { startDate, endDate },
});

export const getGoogleAnalyticsProductsSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_PRODUCTS_SUCCESS,
  payload: data,
});

export const getGoogleAnalyticsProductsFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_PRODUCTS_FAILURE,
  payload: error,
});

export const getGAUsersRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_USERS_REQUEST,
  payload: { startDate, endDate },
});

export const getGAUsersSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_USERS_SUCCESS,
  payload: data,
});

export const getGAUsersFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_USERS_FAILURE,
  payload: error,
});

export const getGAAudienceRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST,
  payload: { startDate, endDate },
});

export const getGAAudienceCompraisonRequest = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_GOOGLE_ANALYTICS_AUDIENCE_REQUEST,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});

export const getGAAudienceSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_AUDIENCE_SUCCESS,
  payload: data,
});

export const getGAAudienceFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_AUDIENCE_FAILURE,
  payload: error,
});

export const getGAPlatformRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST,
  payload: { startDate, endDate },
});

export const getGAPlatformSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_GAPLATFORM_SUCCESS,
  payload: data,
});

export const getGAPlatformFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_GAPLATFORM_FAILURE,
  payload: error,
});

export const getGAPlatformCompraisonRequest = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_GOOGLE_ANALYTICS_GAPLATFORM_REQUEST,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});

export const getGAAffiliationsRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST,
  payload: { startDate, endDate },
});

export const getGAAffiliationsSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_AFFILIATIONS_SUCCESS,
  payload: data,
});

export const getGAAffiliationsFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_AFFILIATIONS_FAILURE,
  payload: error,
});

export const getGAAffiliationsCompraisonRequest = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_GOOGLE_ANALYTICS_AFFILIATIONS_REQUEST,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});

export const getGATrafficAcquisitionRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST,
  payload: { startDate, endDate },
});
export const getGATrafficAcquisitionCompraisonRequest = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_REQUEST,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});

export const getGATrafficAcquisitionSuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS,
  payload: data,
});

export const getGATrafficAcquisitionFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_TRAFFIC_ACQUISITION_SUCCESS,
  payload: error,
});

export const getGAPurchaseJourneyRequest = (startDate, endDate) => ({
  type: GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST,
  payload: { startDate, endDate },
});
export const getGAPurchaseJourneyCompraisonRequest = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_REQUEST,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});

export const getGAPurchaseJourneySuccess = (data) => ({
  type: GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_SUCCESS,
  payload: data,
});

export const getGAPurchaseJourneyFailure = (error) => ({
  type: GET_GOOGLE_ANALYTICS_PURCHASE_JOURNEY_FAILURE,
  payload: error,
});

export const getGAUsersCompraisonRequest = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_GOOGLE_ANALYTICS_USERS_REQUEST,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});
