import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select"
import ReactApexChart from "react-apexcharts"
import { Link, withRouter } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, FormGroup,
  InputGroup,
} from 'reactstrap';
import { default as NumberFormat } from 'react-number-format';
import { getFacebookCampaignsPerDate } from '../../../store/facebookAds/actions';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DateRange } from "react-date-range";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
//Import Image
import { useSelector, useDispatch } from 'react-redux';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;



function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const InvoiceDetailfacebook = (props) => {
  const { metrcisbyCompaign } = useSelector((state) => state.facebookAds)

  const wrapperRef = useRef(null);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const dispatch = useDispatch();
  const { dataDashboard } = useSelector((state) => state.facebookAds);

  const currencyrevenuerate = localStorage.getItem("metaads_currency_revenue_rate")

  const exportToPDF = async () => {
    const capture = document.querySelector('.print');

    // Calculate the width and height of the HTML element you're capturing
    const captureWidth = capture.offsetWidth;
    const captureHeight = capture.offsetHeight;

    const padding = 10; // Define the padding here

    html2canvas(capture, { width: captureWidth, height: captureHeight }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format for better quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      // Calculate the aspect ratio of the capture and the PDF page
      const aspectRatio = captureWidth / captureHeight;
      const imgWidth = pdfWidth - 2 * padding; // Adjust padding as needed
      const imgHeight = imgWidth / aspectRatio;

      // Center the image horizontally on the PDF page
      const x = (pdfWidth - imgWidth) / 2;

      // Adjust the y position to remove the top padding
      const y = padding; // Remove top padding, you can adjust this value as needed

      // Add the image to the PDF
      doc.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight);

      doc.save('Campaigns-meta-Ads.pdf');
    });
  };




  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  const handleSelectChange = (selectedOptions) => {
    const ids = selectedOptions.map(option => option.value);
    setSelectedCampaignIds(ids);
  };

  const [toggleDate, setToggleDate] = useState(false);
  const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
  const series = [
    {
      data: metrcisbyCompaign?.map((campaign) => campaign.spend),
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#0044BD"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: metrcisbyCompaign?.map((campaign) => campaign.campaign_name),
    },
  }
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date:
      [
        window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
        window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone }))

      ]
  });
  const [activeBtn, setActiveBtn] = useState(window.localStorage.getItem("activeBtn") ? JSON.parse(window.localStorage.getItem("activeBtn")) : {
    today: 1,
    yesterday: 0,
    last7days: 0,
    last30day: 0,
    last60day: 0,
    month: 0,
    other: 0,
  });


  const [chosedDate, setChosedDate] = useState([{
    startDate: window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    endDate: window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    key: 'selection'
  }]);


  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([{
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }]);
    setToggleDate(false)
    window.localStorage.setItem("start", startDate);
    window.localStorage.setItem("end", endDate);
  };
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(new Date(chosedDate[0].startDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" }))),
          new Date(new Date(chosedDate[0].endDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" })))
        ]
      });
      setToggleDate(false)
    }
  }, [currentFocus])

  // GET ALL CAMPAIGNS
  useEffect(() => {
    setSelectedCampaign(null);
    var newStartDate = datePattern.exec(chosedDate[0].startDate && chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(chosedDate[0].endDate && chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    if (newStartDate && newEndDate) {
      dispatch(getFacebookCampaignsPerDate(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1]));
    }
  }, [dateRage, chosedDate]);



  // CALCULATE ROAS
  let totalSpendRoas = 0;
  let totalActionValuesRoas = 0;
  let Roas = 0;

  if (selectedCampaignIds.length > 0) {
    selectedCampaignIds.forEach(campaignId => {
      const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);

      if (selectedCampaign && selectedCampaign.action_values && selectedCampaign.spend) {
        totalSpendRoas += selectedCampaign.spend;
        totalActionValuesRoas += selectedCampaign.action_values;
      }
    });

    if (totalSpendRoas > 0) {
      Roas = (totalActionValuesRoas / totalSpendRoas).toFixed(2); // Calculate ROAS
    }
  }

  // CALCULATE CPA
  let totalSpendCpa = 0;
  let totalActions = 0;
  let cpa = 0;

  if (selectedCampaignIds.length > 0) {
    selectedCampaignIds.forEach(campaignId => {
      const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);

      if (selectedCampaign && selectedCampaign.spend && selectedCampaign.actions) {
        totalSpendCpa += selectedCampaign.spend;
        totalActions += selectedCampaign.actions;
      }
    });

    if (totalActions > 0) {
      cpa = (totalSpendCpa / totalActions);
    }
  }





  // CALCULATE CR% 
  let totalActionsCR = 0;
  let totalLandingPageViews = 0;
  let cr = 0;
  if (selectedCampaignIds.length > 0) {
    selectedCampaignIds.forEach(campaignId => {
      const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);

      if (selectedCampaign && selectedCampaign.actions && selectedCampaign.landingPageViewAction) {
        totalActionsCR += selectedCampaign.actions;
        totalLandingPageViews += selectedCampaign.landingPageViewAction;
      }
    });

    if (totalActionsCR > 0) {
      cr = (totalActionsCR / totalLandingPageViews);
    }
  }
//Calculate CTR
let totalClicks = 0;
let totalImpressions = 0;
let ctr = 0;

if (selectedCampaignIds.length > 0) {
  selectedCampaignIds.forEach(campaignId => {
    const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);

    if (selectedCampaign && selectedCampaign.clicks && selectedCampaign.impressions) {
      totalClicks += selectedCampaign.clicks;
      totalImpressions += selectedCampaign.impressions;
    }
  });

  if (totalImpressions > 0) {
    ctr = (totalClicks / totalImpressions) * 100; // Calculate CTR as a percentage
  }
}



  // CALCULATE AOV
  let totalActionValuesAOV = 0;
  let totalActionsAOV = 0;
  let aov = 0;
  if (selectedCampaignIds.length > 0) {
    selectedCampaignIds.forEach(campaignId => {
      const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);

      if (selectedCampaign && selectedCampaign.action_values && selectedCampaign.actions) {
        totalActionValuesAOV += selectedCampaign.spend;
        totalActionsAOV += selectedCampaign.actions;
      }
    });

    if (totalActionsAOV > 0) {
      aov = (totalActionValuesAOV / totalActionsAOV);
    }
  }


  // Function to handle image selection



  return (
    <React.Fragment>
      <div className="page-content">
          <title>
          {props.t("Invoice Detail")} | Convergen-Metrics - E-com SaaS
          </title>
        <Container fluid >

          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Invoices")} breadcrumbItem={props.t("Campaing meta  Report")} />
          <Row >
            <Col lg="12">

              <Card >
                <CardBody>
                  <div className="print">
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="">
                            <Col md={3}>
                              <FormGroup className="mb-4 ">
                                <InputGroup>
                                  <div className="DateContainer">
                                    <div>
                                      <div className="divDateContainer" >
                                        <div ref={wrapperRef}>
                                          <button
                                            className="btn btn-primary w-50 waves-effect waves-light"
                                            onClick={() => setToggleDate(!toggleDate)}
                                          >
                                            {chosedDate[0] !== null
                                              ? [
                                                chosedDate[0].startDate.toLocaleString("en", {
                                                  month: "short",
                                                }),
                                                " ",
                                                chosedDate[0].startDate.getDate(),
                                                " ",
                                                chosedDate[0].startDate.getFullYear(),
                                                "  -  ",
                                                chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString("en", {
                                                  month: "short",
                                                }),
                                                " ",
                                                chosedDate[0].endDate && chosedDate[0].endDate.getDate(),
                                                " ",
                                                chosedDate[0].endDate && chosedDate[0].endDate.getFullYear(),
                                              ]
                                              : null}
                                          </button>
                                          {toggleDate && (
                                            <div style={{ position: "relative", display: "flex" }}>
                                              <div className="calenderDivStyle">\
                                                <div className="btnsCalendar">
                                                  <DateRange
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    onRangeFocusChange={item => { setCurrentFocus(item) }}
                                                    onChange={item => { setChosedDate([item.selection]); setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 }); window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 })) }}
                                                    ranges={chosedDate}
                                                    dragSelectionEnabled={true}
                                                    rangeColors={["#6689F2"]}
                                                    months={2}
                                                    direction="horizontal"
                                                  />
                                                </div>
                                                <div className="calendarDiv">
                                                  <div className="btnCalendarContainer">

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <br />

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <div className="col-lg-12 col-md-6">
                              <div className="mb-3">

                                <Select
                                  isMulti
                                  options={metrcisbyCompaign?.map((campaign) => ({
                                    value: campaign.campaign_id,
                                    label: campaign.campaign_name,
                                  }))}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={handleSelectChange}
                                  placeholder="Select Campaign"
                                />
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                    {props.t("Spend")}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {selectedCampaignIds.length > 0 &&
                                          selectedCampaignIds.reduce((sum, campaignId) => {
                                            const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);
                                            if (selectedCampaign && typeof selectedCampaign.spend === 'number') {
                                              return sum + selectedCampaign.spend;
                                            }
                                            return sum;
                                          }, 0).toFixed(2)}$
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                    {props.t("Conversions")}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {totalActions}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      ROAs
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        <NumberFormat value={Roas} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'x'} renderText={value => <h4>{value}</h4>} />
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CPA
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        <NumberFormat value={cpa} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <h4>{value}</h4>} />
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CR%
                                    </span>
                                    <h4 className="mb-3">

                                      <span className="counter-value">
                                        <NumberFormat value={cr} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} renderText={value => <h4>{value}</h4>} />
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      AOV
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        <NumberFormat value={aov} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <h4>{value}</h4>} />
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                    {props.t("Revenue")}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {selectedCampaignIds.length > 0 &&
                                          selectedCampaignIds.reduce((sum, campaignId) => {
                                            const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);
                                            if (selectedCampaign && typeof selectedCampaign.action_values === 'number') {
                                              return sum + selectedCampaign.action_values;
                                            }
                                            return sum;
                                          }, 0)}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                    {props.t("Clicks")}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {selectedCampaignIds.length > 0 &&
                                          selectedCampaignIds.reduce((sum, campaignId) => {
                                            const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);
                                            if (selectedCampaign && typeof selectedCampaign.clicks === 'number') {
                                              return sum + selectedCampaign.clicks;
                                            }
                                            return sum;
                                          }, 0)}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>



                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                    {props.t("Impressions")}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {selectedCampaignIds.length > 0 &&
                                          selectedCampaignIds.reduce((sum, campaignId) => {
                                            const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);
                                            if (selectedCampaign && typeof selectedCampaign.impressions === 'number') {
                                              return sum + selectedCampaign.impressions;
                                            }
                                            return sum;
                                          }, 0)}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                    {props.t("Reach")}
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {selectedCampaignIds.length > 0 &&
                                          selectedCampaignIds.reduce((sum, campaignId) => {
                                            const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);
                                            if (selectedCampaign && typeof selectedCampaign.reach === 'number') {
                                              return sum + selectedCampaign.reach;
                                            }
                                            return sum;
                                          }, 0)}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CTR
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                      {ctr.toFixed(2)}%
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={4}>
                        <Card className="card-h-100 tamour">
                          <CardBody>
                            <Row className="align-items-center">
                              <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-primary mb-3 lh-1 d-block text-truncate">
                                      CPC
                                    </span>
                                    <h4 className="mb-3">
                                      <span className="counter-value">
                                        {selectedCampaignIds.length > 0 &&
                                          (selectedCampaignIds.reduce((sum, campaignId) => {
                                            const selectedCampaign = metrcisbyCompaign.find(campaign => campaign.campaign_id === campaignId);
                                            if (selectedCampaign && typeof selectedCampaign.cpc === 'number') {
                                              return sum + selectedCampaign.cpc;
                                            }
                                            return sum;
                                          }, 0) / selectedCampaignIds.length).toFixed(2)}
                                      </span>
                                    </h4>
                                  </div>
                                  <img src={GoogleAdsIcon} alt="" height="50" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>


                    </Row>
                    <hr className="my-4" />
                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <button onClick={exportToPDF} className="btn btn-primary w-md ">
                        {props.t("Download")}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
InvoiceDetailfacebook.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(InvoiceDetailfacebook));
//export default withRouter(InvoiceDetailfacebook);
