import React from 'react';
import { getTotal } from '../GoogleAnalytics/common/HelperFunctions/utils';
import { tooltipMap } from '../../locales/tooltip/tooltip';
import GoogleAdsCard from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCard';

const PrestaShopCards = ({ data, colors }) => {
  const findDataByName = (name, value) => {
    const metric = data.find((item) => item.name === name);
    return metric ? metric[value] : [];
  };
  const generateTotalValue = (item, data) => {
    const TNumberOrders = getTotal(findDataByName('NumberOrders', data));
    const TgrossProfit = getTotal(findDataByName('GrossProfit', data));
    const Tsales = getTotal(findDataByName('Sales', data));

    if (item.name === 'NumberOrders')
      return {
        data: TNumberOrders,
        display: TNumberOrders,
      };
    if (item.name === 'GrossProfit')
      return {
        data: TgrossProfit,
        display: `${TgrossProfit.toFixed(2)}`,
      };
    if (item.name === 'Sales')
      return {
        data: Tsales,
        display: `${Tsales.toFixed(2)}`,
      };
    if (item.name === 'AOV') {
      const AOV = Tsales / TNumberOrders;
      return {
        data: AOV,
        display: `${AOV.toFixed(2)}`,
      };
    }
  };
  const finalData = data.map((item) => {
    const TotalValue = generateTotalValue(item, 'data');

    if (item?.compareData.length > 0) {
      const OldTotalValue = generateTotalValue(item, 'compareData');
      return {
        name: item.name,
        totalValue: TotalValue.display,
        percentage: (
          ((TotalValue.data - OldTotalValue.data) / OldTotalValue.data) *
          100
        ).toFixed(2),
        series: [
          { name: item.name, data: item.data },
          { name: `compare ${item.name}`, data: item.compareData },
        ],
        tooltip: tooltipMap[item.name].tooltip,
        formule: tooltipMap[item.name].formula,
      };
    } else
      return {
        tooltip: tooltipMap[item.name]?.tooltip,
        formule: tooltipMap[item.name]?.formula,
        name: item.name,
        totalValue: TotalValue.display,
        series: [{ name: item.name, data: item.data }],
      };
  });
  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default PrestaShopCards;
