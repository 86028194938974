/* eslint-disable array-callback-return */
import { takeLatest, put, call} from 'redux-saga/effects';
import {GetTrackingStatus,GetTrackingNumbers,getall,checkSales,deleteAramexIntegration,addingAramexAccount} from '../../queries/Aramex/Queries';
import { alert,successAlert, } from '../../pages/Alerts/NotLinkedAccountAlert';
import urls from '../../routes/apiUrls';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import {
  CHECK_INTEGRATION_REQUEST,
  checkIntegrationSuccess,
  checkIntegrationFailure,
  FETCH_DATA_REQUEST,
  fetchDataSuccess,
  fetchDataFailure,
  GET_ALL_TRACKING_STATUS_REQUEST,
  getAllTrackingStatusSuccess,
  getAllTrackingStatusFailure,
  DELETE_INTEGRATION_ARAMEX_REQUEST,
  deleteIntegrationAramexSuccess,
  deleteIntegrationAramexFailure,
  INTEGRATION_ARAMEX_REQUEST,
  integrationAramexSuccess,
  integrationAramexFailure,
} from './action';



  const ApploCLI = new ApolloClient({
    link: new HttpLink({
      uri: urls.Aramex,
         headers: {
        Authorization: window.localStorage.getItem('token')
          ? `Bearer ${window.localStorage.getItem('token')}`
          : '',
      },
      credentials: 'include', // Include cookies for cross-origin requests
    }),
    cache: new InMemoryCache(),
  });

function* checkIntegration() {
try {
  const {data}= yield call(ApploCLI.query, {
    query: checkSales,
    context: {
      headers: {
        Authorization: window.localStorage.getItem('token')
          ? `Bearer ${window.localStorage.getItem('token')}`
          : '',
      }
    },
    errorPolicy: "all",
        fetchPolicy: "no-cache",
  })
  if(data.get == null){
    alert().then((data) => {
      window.location.replace('/integrations');
     });
      yield put (checkIntegrationFailure())
  }
      else{
        yield put(checkIntegrationSuccess(data.get,'active'));
       
      }
} catch (error) {

  yield put(checkIntegrationFailure(error));

} 
}
 function* getAllTrackingStatus() {
  try {
    yield put({ type: "GET_TRACKING_STATUS_REQUEST" });
    yield call (ApploCLI.query, {
      query: GetTrackingNumbers,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        }
      }
    })
     const response =  yield   call(ApploCLI.query, {
      query: GetTrackingStatus,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
    })
    yield put(getAllTrackingStatusSuccess(response.data));
    window.location.reload()

  } catch (error) {
    yield put(getAllTrackingStatusFailure(error));
    window.location.reload()
  }
}
function* fetchData(action) {
  try {
    const { limit, page } = action.payload;
  const {data}= yield call(ApploCLI.query, {
  query: getall,
  variables: { limit, page },
  context: {
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    }
  },
  errorPolicy: "all",
      fetchPolicy: "no-cache",
});

  let ShipmentChargesPaid = 0;
  let SupportingDocumentReturnedtoShipper = 0;
  let ReturnedtoShipper = 0;
  let Delivered = 0;
  let emailofreturnedshipper=[];
  if (data && data.AramexAll && data.AramexAll.length > 0) {
    data.AramexAll.forEach(element => {
      element.aramex.trackingNumber.map((item) => {
        if (item.updateddiscription === "Shipment charges paid") {
          ShipmentChargesPaid++;
        }
        else if (item.updateddiscription === "Supporting Document Returned to Shipper") {
          SupportingDocumentReturnedtoShipper++
        }
        else if (item.updateddiscription === "Returned to Shipper") {
          emailofreturnedshipper.push(item.email)
          ReturnedtoShipper++
        }
        else if (item.updateddiscription === "Delivered") {
          Delivered++;
        }
      });
    });
  }
  yield put(fetchDataSuccess(data.AramexAll, ShipmentChargesPaid, SupportingDocumentReturnedtoShipper, ReturnedtoShipper, Delivered,emailofreturnedshipper));
  

  // yield put(fetchDataSuccess(data.AramexAll));

  } catch (error) {
  yield put(fetchDataFailure(error));
  }
}
function* deleteIntegrationAramex(action) {
  try{
    const {data} = yield call(ApploCLI.mutate, {
      mutation: deleteAramexIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
      },
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",

    });

    if(data){
      yield put(deleteIntegrationAramexSuccess(data));
      successAlert().then(() => {
        window.location.replace("/integrations");
      });
    }
  }catch (error){
    yield put(deleteIntegrationAramexFailure(error));
  }
}
 function* integrationAramex(action) {
  try{
    const {data} = yield call(ApploCLI.mutate, {
      mutation: addingAramexAccount,
      variables:{
        UserName: action.payload.UserName,
            Password: action.payload.Password,
            AccountNumber:  action.payload.AccountNumber,
            AccountPin: action.payload.AccountPin,
            AccountEntity: action.payload.AccountEntity,
            AccountCountryCode: action.payload.AccountCountryCode,
        
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
      },
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",

    })
    console.log('**************data*******************',data)
    
    if(data){

      yield put(integrationAramexSuccess(data));
        window.location.replace("/integrations");
    }

  }catch (error){
    yield put(integrationAramexFailure(error));
  }
 }

export default function* getAllTrackingStatussaga() {
  
 
  yield takeLatest(FETCH_DATA_REQUEST, fetchData);
  yield takeLatest(CHECK_INTEGRATION_REQUEST, checkIntegration);
  yield takeLatest(GET_ALL_TRACKING_STATUS_REQUEST, getAllTrackingStatus);
  yield takeLatest(DELETE_INTEGRATION_ARAMEX_REQUEST, deleteIntegrationAramex);
  yield takeLatest(INTEGRATION_ARAMEX_REQUEST, integrationAramex);
}




 
