import {
    takeLatest,
    put,
    call
} from 'redux-saga/effects';
import {
    getInformations
} from "../../../queries/Reports/StrategicQuery";
import urls from '../../../routes/apiUrls';
import {
    GET_STRATEGIC_REPORTS_REQUEST,
    getStrategicCDataSuccess,
    getStrategicCDataFailure,

} from './action';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const ApploCLI = new ApolloClient({
    link: new HttpLink({
        uri: urls.report,
         headers: {
        Authorization: window.localStorage.getItem('token')
          ? `Bearer ${window.localStorage.getItem('token')}`
          : '',
      },
      credentials: 'include', // Include cookies for cross-origin requests
    }),
    cache: new InMemoryCache(),
  });
function* getStrategicData(action) {
    try {
        const {
            data
        } = yield call(ApploCLI.query, {
            query: getInformations,
            variables: {
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                channel: action.payload.channel,
            },
            context: {
                headers: {
                    Authorization: window.localStorage.getItem('token') ?
                        `Bearer ${window.localStorage.getItem('token')}` :
                        '',
                }
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache",
        })
        if (data.strat) {
            var strat = data.strat;
            const avgTest = data.strat && data.strat.map((el) => el.aov);
            var avg = data.strat &&
                data.strat.length > 0 &&
                data.strat.map((el) => el.aov).reduce((a, b) => a + b) /
                avgTest.length;
            var daily = data.strat && data.strat.length > 0 && data.strat.map((el) => el.sales).reduce((a, b) => a + b) /       avgTest.length
            const avgNetP =
                data.strat && data.strat.map((el) => el.netProfit);
            var avgNetProfit = data.strat && data.strat.length > 0 && data.strat.map((el) => el.netProfit).reduce((a, b) => a + b) / avgNetP.length;

            const avgcps = data.strat && data.strat.map((el) => el.cps);
            var avgCPS = data.strat && data.strat.length > 0 && data.strat.map((el) => el.cps).reduce((a, b) => a + b) / avgcps.length;

            const avgroa = data.strat && data.strat.map((el) => el.roa);
            var avgROA = data.strat && data.strat.length > 0 && data.strat.map((el) => el.roa).reduce((a, b) => a + b) / avgroa.length;

            const avgacpo =
                data.strat && data.strat.map((el) => el.avgCPO);
            var avgACPO = data.strat && data.strat.length > 0 && data.strat.map((el) => el.avgCPO).reduce((a, b) => a + b) / avgacpo.length;
            const avgmm =
                data.strat && data.strat.map((el) => el.magicMetrics);
            var avgMM = data.strat && data.strat.length > 0 && data.strat.map((el) => el.magicMetrics).reduce((a, b) => a + b) / avgmm.length;

            const avgAdCostt =
                data.strat && data.strat.map((el) => el.facebook);
            var avgAdCost = data.strat && data.strat.length > 0 && data.strat.map((el) => el.facebook + el.google).reduce((a, b) => a + b) / avgAdCostt.length;
            var avgFacebook = data.strat && data.strat.length > 0 && data.strat.map((el) => el.facebook).reduce((a, b) => a + b) / avgAdCostt.length
            var avgGoogle = data.strat && data.strat.length > 0 && data.strat.map((el) => el.google).reduce((a, b) => a + b) / avgAdCostt.length;
            var totalFacebook = data.strat && data.strat.length > 0 && data.strat.map((el) => el.facebook).reduce((a, b) => a + b);
            var totalGoogle = data.strat && data.strat.length > 0 && data.strat.map((el) => el.google).reduce((a, b) => a + b);
            var totalNetProfit = data.strat && data.strat.length > 0 && data.strat.map((el) => el.netProfit).reduce((a, b) => a + b);
            var totalCps = data.strat && data.strat.length > 0 && data.strat.map((el) => el.cps).reduce((a, b) => a + b);
            var totalRoas = data.strat && data.strat.length > 0 && data.strat.map((el) => el.roa).reduce((a, b) => a + b);
            var totalAcpo = data.strat && data.strat.length > 0 && data.strat.map((el) => el.avgCPO).reduce((a, b) => a + b);
            var totalMm = data.strat && data.strat.length > 0 && data.strat.map((el) => el.magicMetrics).reduce((a, b) => a + b);
            var totalAdCost = data.strat && data.strat.length > 0 && data.strat.map((el) => el.facebook + el.google).reduce((a, b) => a + b);
            var totalAov = data.strat && data.strat.length > 0 && data.strat.map((el) => el.sales).reduce((a, b) => a + b);
            var Taov = [];
            var TempSeries = [];


            const time = data.strat.map((elem, idx) => {
                const created = new Date(elem.created);
                const formattedDate = created.getDate() + " " + created.toLocaleString('default', {
                    month: 'short'
                });
                Taov.push(elem.aov ? elem.aov : 0)
                return formattedDate;
            });
console.log("time",time)

            TempSeries.push(time)
            var series = TempSeries
            var tableaov = Taov
            console.log("dailyaaaaa ", daily)

            yield put(getStrategicCDataSuccess(
                strat,
                    series,
                avg,
                daily,
                avgNetProfit,
                avgCPS,
                avgROA,
                avgACPO,
                avgMM,
                avgAdCost,
                totalFacebook,
                totalGoogle,
                avgFacebook,
                avgGoogle,
                totalNetProfit,
                totalAdCost,
                totalAov,
                totalCps,
                totalRoas,
                totalAcpo,
                totalMm,
                tableaov

            ));


        }


    } catch (error) {
        yield put(getStrategicCDataFailure(error));

    }
}

export default function* strategicSaga() {
    yield takeLatest(GET_STRATEGIC_REPORTS_REQUEST, getStrategicData);
}
