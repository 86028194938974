import React from 'react';
import { Modal, Table } from 'antd';
import downloadicon from '../../../../assets/images/download-icon.svg';
import success from '../../../../assets/images/success.svg';
import error from '../../../../assets/images/error.svg';

const BillingHistory = ({ isModalOpen, handleOk, handleCancel, invoices, loadingState }) => {

  const columns = [
    {
      title: "REFERENCE",
      dataIndex: "invoiceId",
      sort: true,
      render: (cellContent, invoices) => <>{invoices.invoiceId}</>,
    },
    {
      title: "DATE",
      dataIndex: "date",
      sort: true,
      render: (cellContent, invoices) => <>{invoices.date}</>,
    },
    {
      title: "PLAN TYPE",
      dataIndex: "Plan_Type",
      sort: true,
      render: (cellContent, invoices) => <>{invoices.Plan_Type}</>,
    },
    {
      title: "METHOD",
      dataIndex: "method",
      sort: true,
      render: (cellContent, invoices) => <>{invoices.method}</>,
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (cellContent, invoices) => <>{invoices.Amount}</>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (cellContent, invoices) => (
        <>
          {invoices.status === "SUCCESS" ? (
            <img src={success} alt="success" />
          ) : (
            <img src={error} alt="error" />
          )}
        </>
      ),
    },
    {
      title: 'DOWNLOAD',
      key: 'download',
      render: () => (
        <img src={downloadicon} alt="download" />
      ),
    },
  ];

  return (
    <Modal
      title={<h2 style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: "20px" }}>Invoices</h2>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      footer={null}
    >
      <Table
        scroll={{ y: 70 * 5 }}
        columns={columns}
        loading={loadingState}
        dataSource={invoices}
        pagination={false}
      />
    </Modal>
  );
};

export default BillingHistory;
