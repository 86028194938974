import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getExchangeRateDuo,
  getTotal,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsCard from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCard';
import { tooltipMap } from '../../locales/tooltip/tooltip';

const MetaAdsCards = ({ data, colors }) => {
  const { metaAdsCurrency } = useSelector((state) => state.CurrencyReducer);
  const [ROAS, setROAS] = useState(0);
  const [compareRoas, setCompareRoas] = useState(0);

  const findDataByName = (name, value) => {
    const metric = data.find((item) => item.name === name);
    return metric ? metric[value] : [];
  };

  useEffect(() => {
    const fetchExchangeRate = async (type) => {
      try {
        const totalConversionsValue = getTotal(findDataByName('Revenue', type));
        const totalCost = getTotal(findDataByName('Ad Spend', type));

        const rate = await getExchangeRateDuo(
          metaAdsCurrency.spend,
          metaAdsCurrency.revenue
        );

        const roasValue =
          totalCost !== 0 ? (totalConversionsValue * rate) / totalCost : 0;
        if (type === 'compareData') {
          setCompareRoas(roasValue);
        } else if (type === 'data') {
          setROAS(roasValue);
        }
      } catch (error) {
        console.error('Failed to fetch exchange rate:', error);
      }
    };

    if (data?.length > 0 && metaAdsCurrency.revenue !== '') {
      fetchExchangeRate('data');
      if (findDataByName('Revenue', 'compareData').length > 0) {
        fetchExchangeRate('compareData');
      }
    }
  }, [metaAdsCurrency, data]);

  const generateTotalValue = (item, data) => {
    // Extract relevant data from data dynamically
    const totalClicks = getTotal(findDataByName('Clicks', data));
    const totalCost = getTotal(findDataByName('Ad Spend', data));
    const totalConversions = getTotal(findDataByName('Conversions', data));
    const totalImpressions = getTotal(findDataByName('Impressions', data));
    const totalConversionsValue = getTotal(findDataByName('Revenue', data));
    const TladingPage = getTotal(
      findDataByName('Total Landing Page View', data)
    );
    const TReach = getTotal(findDataByName('Total Reach', data));
    // Calculate the values based on the metric
    if (item.name === 'Ad Spend')
      return {
        data: totalCost,
        display: `${totalCost.toFixed(2)} ${metaAdsCurrency.spend}`,
      };
    if (item.name === 'Revenue')
      return {
        data: totalConversionsValue,
        display: `${totalConversionsValue.toFixed(2)} ${
          metaAdsCurrency.revenue
        }`,
      };

    if (item.name === 'Conversions')
      return {
        data: totalConversions,
        display: `${totalConversions.toFixed(2)}`,
      };
    if (item.name === 'Impressions')
      return { data: totalImpressions, display: `${totalImpressions}` };
    if (item.name === 'Clicks')
      return { data: totalClicks, display: `${totalClicks}` };
    if (item.name === 'ROAS') {
      if (data === 'data') {
        return { data: ROAS, display: ROAS.toFixed(2) };
      } else if (data === 'compareData') {
        return { data: compareRoas, display: compareRoas.toFixed(2) };
      }
    }
    if (item.name === 'CPA') {
      const cpa = totalConversions !== 0 ? totalCost / totalConversions : 0;
      return { data: cpa, display: `$${cpa.toFixed(2)}` };
    }
    if (item.name === 'CPC') {
      const cpc = totalClicks !== 0 ? totalCost / totalClicks : 0;
      return { data: cpc, display: `$${cpc.toFixed(2)}` };
    }
    if (item.name === 'CTR') {
      const ctr =
        totalImpressions !== 0 ? (totalClicks / totalImpressions) * 100 : 0;
      return { data: ctr, display: '%' + ctr.toFixed(2) };
    }
    if (item.name === 'Conversion Rate') {
      const conversionRate =
        totalClicks !== 0 ? (totalConversions / totalClicks) * 100 : 0;
      return { data: conversionRate, display: '%' + conversionRate.toFixed(2) };
    }
    if (item.name === 'AOV') {
      const aov =
        totalConversions !== 0 ? totalConversionsValue / totalConversions : 0;
      return { data: aov, display: aov.toFixed(2) };
    }
    if (item.name === 'Total Landing Page View') {
      return { data: TladingPage, display: `${TladingPage}` };
    }
    if (item.name === 'Total Reach') {
      return { data: TReach, display: `${TReach}` };
    }

    // Default fallback
    return 'N/A';
  };

  const finalData = data.map((item) => {

    const TotalValue = generateTotalValue(item, 'data');

    if (item?.compareData.length > 0) {
      const OldTotalValue = generateTotalValue(item, 'compareData');
      return {
        name: item.name,
        totalValue: TotalValue.display,
        percentage: (
          ((TotalValue.data - OldTotalValue.data) / OldTotalValue.data) *
          100
        ).toFixed(2),
        series: [
          { name: item.name, data: item.data },
          { name: `compare ${item.name}`, data: item.compareData },
        ],
        tooltip: tooltipMap[item.name]?.tooltip,
        formule: tooltipMap[item.name]?.formula,
      };
    } else
      return {
        tooltip: tooltipMap[item.name]?.tooltip,
        formule: tooltipMap[item.name]?.formula,
        name: item.name,
        totalValue: TotalValue.display,
        series: [{ name: item.name, data: item.data }],
      };
  });

  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default MetaAdsCards;
