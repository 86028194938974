
export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

//get user info
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

/* reset password*/
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

//login with Google
export const LOGIN_WITH_GOOGLE_REQUEST = 'LOGIN_WITH_GOOGLE_REQUEST';
export const LOGIN_WITH_GOOGLE_SUCCESS = 'LOGIN_WITH_GOOGLE_SUCCESS';
export const LOGIN_WITH_GOOGLE_FAILURE = 'LOGIN_WITH_GOOGLE_FAILURE';
//check is connected request
export const IS_CONNECTED_REQUEST = 'IS_CONNECTED_REQUEST';
export const IS_CONNECTED_SUCCESS = 'IS_CONNECTED_SUCCESS';
export const IS_CONNECTED_FAILURE = 'IS_CONNECTED_FAILURE';

//register
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

//register with google

export const REGISTER_WITH_GOOGLE_REQUEST = 'REGISTER_WITH_GOOGLE_REQUEST';
export const REGISTER_WITH_GOOGLE_SUCCESS = 'REGISTER_WITH_GOOGLE_SUCCESS';
export const REGISTER_WITH_GOOGLE_FAILURE = 'REGISTER_WITH_GOOGLE_FAILURE';

//verif Code

export const VERIF_CODE_REQUEST = 'VERIF_CODE_REQUEST';
export const VERIF_CODE_SUCCESS = 'VERIF_CODE_SUCCESS';
export const VERIF_CODE_FAILURE = 'VERIF_CODE_FAILURE';


// check if user is verified

export const IS_VERIFIED_REQUEST = 'IS_VERIFIED_REQUEST';
export const IS_VERIFIED_SUCCESS = 'IS_VERIFIED_SUCCESS';
export const IS_VERIFIED_FAILURE = 'IS_VERIFIED_FAILURE';

//forget password
export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';

//refresh access token

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';


//logout 
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


//get user info
export const getUserInfoRequest = () => ({
  type: GET_USER_INFO_REQUEST,
});

export const getUserInfoSuccess = (data) => ({
  type: GET_USER_INFO_SUCCESS,
  payload: data,
});

export const getUserInfoFailure = (error) => ({
  type: GET_USER_INFO_FAILURE,
  payload: error,
});

export const editProfileRequest = (data) => ({
  type: EDIT_PROFILE_REQUEST,
  payload: data,
});

export const editProfileSuccess = (Userupdated) => ({
  type: EDIT_PROFILE_SUCCESS,
  payload: Userupdated,
});

export const editProfileFailure = (error) => ({
  type: EDIT_PROFILE_FAILURE,
  payload: error,
});

/*reset password*/

export const resetPasswordRequest = (password) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: password,
});
export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

/*reset password*/

export const forgetPasswordRequest = (email) => ({
  type: FORGET_PASSWORD_REQUEST,
  payload: email,
});
export const forgetPasswordSuccess = () => ({
  type: FORGET_PASSWORD_SUCCESS,
});

export const forgetPasswordFailure = (error) => ({
  type: FORGET_PASSWORD_FAILURE,
  payload: error,
});

//login
export const loginRequest = (email, password) => ({
  type: LOGIN_REQUEST,
  payload: { email, password },
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

//LOGIN WITH GOOGLE

export const loginWithGoogleRequest = (token) => ({
  type: LOGIN_WITH_GOOGLE_REQUEST,
  payload: token,
  
});

export const loginWithGoogleSuccess = (data) => ({
  type: LOGIN_WITH_GOOGLE_SUCCESS,
  payload: data,
});

export const loginWithGoogleFailure = (error) => ({
  type: LOGIN_WITH_GOOGLE_FAILURE,
  payload: error,
});

// verif code

export const verifCodeRequest = (id, verifCode,realCode) => ({
  type: VERIF_CODE_REQUEST,
  payload: { id, verifCode ,realCode},
});

export const verifCodeSuccess = (data) => ({
  type: VERIF_CODE_SUCCESS,
  payload: data,
});

export const verifCodeFailure = (error) => ({
  type: VERIF_CODE_FAILURE,
  payload: error,
});

//check is connected
export const isConnectedRequest = () => ({
  type: IS_CONNECTED_REQUEST,
});

export const isConnectedSuccess = (data) => ({
  type: IS_CONNECTED_SUCCESS,
  payload: data,
});

export const isConnectedFailure = (error) => ({
  type: IS_CONNECTED_FAILURE,
});


// check if user is verified
export const isVerifiedRequest = (id) => ({
  type: IS_VERIFIED_REQUEST,
  payload: {id},
});

export const isVerifiedSuccess = (data) => ({
  type: IS_VERIFIED_SUCCESS,
  payload: data,
});

export const isVerifiedFailure = (error) => ({
  type: IS_VERIFIED_FAILURE,
  payload:error
});

//register
export const registerRequest = (email, name) => ({
  type: REGISTER_REQUEST,
  payload: { email, name },
});

export const registerSuccess = (data) => ({
  type: REGISTER_SUCCESS,
  payload: data,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

//register with google

export const registerWithGoogleRequest = (token) => ({
  type: REGISTER_WITH_GOOGLE_REQUEST,
  payload: token,
});

export const registerWithGoogleSuccess = (data) => ({
  type: REGISTER_WITH_GOOGLE_SUCCESS,
  payload: data,
});

export const registerWithGoogleFailure = (error) => ({
  type: REGISTER_WITH_GOOGLE_FAILURE,
  payload: error,
});

//refresh token
export const refreshTokenRequest = (token) => ({
  type: REGISTER_WITH_GOOGLE_REQUEST,
  payload: token,
});

export const refreshTokenSuccess = (data) => ({
  type: REGISTER_WITH_GOOGLE_SUCCESS,
  payload: data,
});

export const refreshTokenFailure = (error) => ({
  type: REGISTER_WITH_GOOGLE_FAILURE,
  payload: error,
});


//logout
export const logoutRequest = () => ({
  type:LOGOUT_REQUEST,
});

export const logoutSuccess = (data) => ({
  type:LOGOUT_SUCCESS,
  payload: data,
});

export const logoutFailure = (error) => ({
  type:LOGOUT_FAILURE,
});
