import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'; // Add translation import
import { getKeywordsRequest } from '../../store/googleAds/actions';
import GoogleAdsTable from './GoogleAdsTable';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { logoMap } from '../../assets/integrations_images/logoMap';

const KeywordsTable = () => {
  const { t } = useTranslation(); // Add translation hook
  const dispatch = useDispatch();
  const { gaKeywords, isLoading } = useSelector((state) => state.GoogleAds);

  useEffect(() => {
    dispatch(getKeywordsRequest());
  }, []);

  let dataMaparamexTrackingNumbers = [];
  if (gaKeywords?.gaKeywords) {
    dataMaparamexTrackingNumbers = gaKeywords.gaKeywords
      .filter((item) => item.cost !== 0 || item.conversions !== 0)
      .map((item) => {
        return item;
      })
      .flat();
  }

  const calculateROAs = (record) => {
    return (record.conversions_value / record.cost).toFixed(2);
     
  };

  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: t('Keyword ID'),
      dataIndex: 'keyword_text',
      key: 'keyword_text',
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Campaign'),
      dataIndex: 'campaign_name',
      align: 'center',
      key: 'campaign_name',
      width: '200px',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Conversions'),
      dataIndex: 'conversions',
      key: 'conversions',
      align: 'center',
      sorter: (a, b) => a.conversions - b.conversions,
      sortOrder:
        sortedInfo.columnKey === 'conversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.conversions.toFixed(2)}
        </strong>
      ),
    },
    {
      title: t('Conversion Value'),
      dataIndex: 'conversions_value',
      align: 'center',
      key: 'conversions_value',
      sorter: (a, b) => a.conversions_value - b.conversions_value,
      sortOrder:
        sortedInfo.columnKey === 'conversions_value' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.conversions_value.toFixed(2)}
        </strong>
      ),
    },
    {
      title: t('Cost'),
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      sorter: (a, b) => a.cost - b.cost,
      sortOrder: sortedInfo.columnKey === 'cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className="table-cell-text">{text.toFixed(2)}</strong>,
    },
    {
      title: t('Clicks'),
      dataIndex: 'clicks',
      key: 'clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Impressions'),
      dataIndex: 'impressions',
      align: 'center',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Roas'),
      dataIndex: 'ROAs',
      align: 'center',
      key: 'ROAs',
      sorter: (a, b) => a.ROAs - b.ROAs,
      sortOrder: sortedInfo.columnKey === 'ROAs' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{calculateROAs(record)}</strong>
      ),
    },
  ];

  const filters = [
    {
      type: 'keyword',
      value: 'keyword_text',
      placeholder: t('Keyword Name'),
    },
    {
      type: 'campaign',
      value: 'campaign_name',
      placeholder: t('Campaign Name'),
    },
  ];

  return (
    <div className="page-content">
      <Breadcrumb image={logoMap['Google Ads']} title={t('Keywords Table')} />
      <GoogleAdsTable
        columns={columns}
        data={dataMaparamexTrackingNumbers}
        sortedInfo={sortedInfo}
        setSortedInfo={setSortedInfo}
        isLoading={isLoading}
        filters={filters}
        mainSorterKey="conversions"
      />
    </div>
  );
};

export default KeywordsTable;
