import React from 'react';
import i18next from 'i18next';

const GoogleAdsChartCheckbox = ({
  value,
  id,
  selectedSeries,
  setSelectedSeries,
}) => {
  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    setSelectedSeries(value);
  };

  return (
    <div className="form-check d-flex align-items-center gap-1">
      <input
        type="radio"
        id={id}
        value={id}
        checked={selectedSeries === id}
        onChange={handleCheckboxChange}
        style={{
          borderRadius: '3px',
          width: '16px',
          height: '15px',
        }}
      />
      <label
        className="form-check-label"
        htmlFor={id}
      
      >
        {i18next.t(value)}
      </label>
    </div>
  );
};

export default GoogleAdsChartCheckbox;
