import React from 'react';
import {
  formatDuration,
  formatNumberWithSpaces,
  formatToPercentage,
  getTotal,
  getTotalFixed,
} from './HelperFunctions/utils';
import GoogleAdsCard from '../../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCard';

const GACardnew = ({ data }) => {
  const findDataByName = (name, value) => {
    const metric = data.find((item) => item.name === name);
    return metric ? metric[value] : [];
  };
  const displayedData = (item, data) => {
    switch (item.name) {
      case 'Users':
        return getTotal(findDataByName('Users', data));
      case 'New Users':
        return getTotal(findDataByName('New Users', data));
      case 'Screen Page Views Per User':
        return getTotalFixed(
          findDataByName('Screen Page Views Per User', data)
        );
      case 'User Engagement':
        return (
          getTotal(findDataByName('User Engagement', data)) /
          findDataByName('User Engagement', data).length
        );
      case 'Ecommerce Purchases':
        return getTotal(findDataByName('Ecommerce Purchases', data));
      case 'Purchaser Rate':
        return findDataByName('Purchaser Rate', data).length
          ? getTotal(findDataByName('Purchaser Rate', data)) /
              findDataByName('Purchaser Rate', data).length
          : 0;
      case 'Purchase Revenue':
        return getTotalFixed(findDataByName('Purchase Revenue', data));
      case 'Average Purchase Revenue':
        return findDataByName('Average Purchase Revenue', data).length
          ? (
              getTotalFixed(findDataByName('Average Purchase Revenue', data)) /
              findDataByName('Average Purchase Revenue', data).length
            ).toFixed(2)
          : 0;
      case 'Average Purchase Revenue Per Paying User':
        return findDataByName('Average Purchase Revenue Per Paying User', data)
          .length
          ? (
              getTotalFixed(
                findDataByName('Average Purchase Revenue Per Paying User', data)
              ) /
              findDataByName('Average Purchase Revenue Per Paying User', data)
                .length
            ).toFixed(2)
          : 0;
      default:
        return null;
    }
  };

  const renderContent = (item, data) => {
    if (item.name === 'User Engagement') {
      return formatDuration(displayedData(item, data));
    } else if (item.name === 'Purchaser Rate') {
      return formatToPercentage(displayedData(item, data));
    } else {
      return formatNumberWithSpaces(displayedData(item, data));
    }
  };

  const finalData = data.map((item) => {
    if (item?.compareData.length > 0) {
      return {
        name: item.name,
        totalValue: renderContent(item, 'data'),
        percentage: (
          ((displayedData(item, 'data') - displayedData(item, 'compareData')) /
            displayedData(item, 'compareData')) *
          100
        ).toFixed(2),
        series: [
          { name: item.name, data: item.data },
          { name: `compare ${item.name}`, data: item.compareData },
        ],
        tooltip: item.tooltip,
        formule: item.formule,
      };
    } else
      return {
        name: item.name,
        totalValue: renderContent(item, 'data'),
        series: [{ name: item.name, data: item.data }],
        tooltip: item.tooltip,
        formule: item.formule,
      };
  });
  const colors = [
    {
      lineColor: '#FE915B',
      fillColor: [
        {
          offset: 0,
          color: '#FE915B',
        },
        {
          offset: 0.47,
          color: 'rgba(254, 145, 91, 0.7)',
        },
        {
          offset: 1,
          color: 'rgba(254, 145, 91, 0.1)',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];

  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default GACardnew;
