import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_REFUNDS_REQUEST,
  FETCH_REFUNDS_SUCCESS,
  FETCH_REFUNDS_FAILURE,
  DELETE_INTEGRATION_PRESTASHOP_REQUEST,
  DELETE_INTEGRATION_PRESTASHOP_SUCCESS,
  DELETE_INTEGRATION_PRESTASHOP_FAILURE,
  INTEGRATION_PRESTASHOP_REQUEST,
  INTEGRATION_PRESTASHOP_SUCCESS,
  INTEGRATION_PRESTASHOP_FAILURE,
  FETCH_STORES_REQUEST,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_FAILURE,
  FETCH_STORE_REQUEST,
  FETCH_STORE_SUCCESS,
  FETCH_STORE_FAILURE,
  CHECK_THIRD_STEP_PRESTASHOP_REQUEST,
  CHECK_THIRD_STEP_PRESTASHOP_SUCCESS,
  CHECK_THIRD_STEP_PRESTASHOP_FAILURE,
  FETCH_PRESTASHOP_DATA_REQUEST,
  FETCH_PRESTASHOP_DATA_SUCCESS,
  FETCH_PRESTASHOP_DATA_FAILURE,
} from './actionsTypes';

const initialState = {
  loading: false,
  firststore: null,
  intergrationLoading: false,
  products: [],
  refunds: [],
  error: null,
  stores: [],
  step: null,
  reachedFinalStep: false,
  dataDashboard: [],
};

const prestashopReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_REFUNDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_REFUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        refunds: action.payload,
      };
    case FETCH_REFUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_INTEGRATION_PRESTASHOP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_INTEGRATION_PRESTASHOP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        step: 1,
      };
    case DELETE_INTEGRATION_PRESTASHOP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case INTEGRATION_PRESTASHOP_REQUEST:
      return {
        ...state,
        error: null,
        intergrationLoading: true,
      };
    case INTEGRATION_PRESTASHOP_SUCCESS:
      return {
        ...state,
        Store: action.payload,
        step: 2,
        reachedFinalStep: true,
        intergrationLoading: false,
      };
    case INTEGRATION_PRESTASHOP_FAILURE:
      return {
        ...state,
        error: action.payload,
        intergrationLoading: false,
      };
    case FETCH_STORES_REQUEST:
      return {
        ...state,
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      };
    case FETCH_STORES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_STORE_REQUEST:
      return {
        ...state,
      };
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        step: 2,
        reachedFinalStep: true,
        firststore: action.payload,
      };
    case FETCH_STORE_FAILURE:
      return {
        ...state,
        error: action.payload,
        reachedFinalStep: false,
      };
    case CHECK_THIRD_STEP_PRESTASHOP_REQUEST:
      return {
        ...state,
      };
    case CHECK_THIRD_STEP_PRESTASHOP_SUCCESS:
      return {
        ...state,
        step: 3,
        reachedFinalStep: true,
      };
    case CHECK_THIRD_STEP_PRESTASHOP_FAILURE:
      return {
        ...state,
        error: action.payload,
        reachedFinalStep: false,
      };
    case FETCH_PRESTASHOP_DATA_REQUEST:
      return {
        ...state,
      };
    case FETCH_PRESTASHOP_DATA_SUCCESS:
      return {
        ...state,
        dataDashboard: action.payload,
      };
    case FETCH_PRESTASHOP_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default prestashopReducer;
