import React, { useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGAAudienceCompraisonRequest,
  getGAAudienceRequest,
} from '../../../store/actions';
import { formatDate } from '../common/HelperFunctions/utils';
import AudienceDataHandler from './AudienceDataHandler';
import PropTypes from 'prop-types';
import i18next from 'i18next';
function GAAudience({ ...props }) {
  const dispatch = useDispatch();
  const { gaAudience } = useSelector((state) => state.googleAnalytics);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  useEffect(() => {
    const newStartDate = formatDate(dateRange.selection1.startDate);
    const newEndDate = formatDate(dateRange.selection1.endDate);
    if (dateRange.selection2.startDate && dateRange.selection2.endDate) {
      const compareStartDateFormatted = formatDate(
        dateRange.selection2.startDate
      );
      const compareEndDateFormatted = formatDate(dateRange.selection2.endDate);

      dispatch(
        getGAAudienceCompraisonRequest(
          newStartDate,
          newEndDate,
          compareStartDateFormatted,
          compareEndDateFormatted
        )
      );
    } else {
      dispatch(getGAAudienceRequest(newStartDate, newEndDate));
    }
  }, [dateRange]);

  return (
    <div className="d-flex align-items-center justify-content-center gap-5 ">
      <div style={{ flex: 1 }}>
        <AudienceDataHandler
          gaaudiences={gaAudience}
          dataKey="userGender"
          color="#EC1A74"
          title={i18next.t("Audience Gender")}
          width="100%"
        />
      </div>
      <div style={{ flex: 2 }}>
        <AudienceDataHandler
          gaaudiences={gaAudience}
          dataKey="userAgeBracket"
          color="#F6B43B"
          title={i18next.t("Audience Age Bracket")}
          width="100%"
        />
      </div>
    </div>
  );
}

GAAudience.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(GAAudience));
