import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Table, Switch, message, ConfigProvider, Input } from 'antd'; // Ant Design components
import { Card, CardBody, Col, Row, CardHeader } from 'reactstrap';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ApolloClient, InMemoryCache, HttpLink  } from '@apollo/client';

import {
  generateUrl,
  getGooglePlatform,
  getAccounts,
  deleteGoogle,
  toggleGoogle,
  integrate,
} from '../../../queries/Google/googleQueries';
import urls from '../../../routes/apiUrls';
// import GoogleAdsIcon from '../../../assets/images/GG Ads.png';
import GoogleAdsIcon from '../../../assets/integrations_images/Google-Ads-Logo-integration.svg';
import i18next from 'i18next';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Initialize ApolloClient

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.google,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

function GoogleAdsCard({ t }) {
  const [step, setStep] = useState(1); // Initial step is 1
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [inactiveAccounts, setInactiveAccounts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const token = window.localStorage.getItem('token');

  // Fetch Google Platform
  const fetchGooglePlatform = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getGooglePlatform,
        context: { headers: { Authorization: token ? `Bearer ${token}` : '' } },
        fetchPolicy: 'network-only',
      });

      if (!data?.get) {
        generateUrlFx();
        setStep(1);
      } else if (data.get?.type !== 4) {
        setStep(2);
      }
    } catch (error) {
      console.error('Error fetching Google platform', error);
    }
  }, [token]);

  // Generate URL for Google OAuth
  const generateUrlFx = async () => {
    try {
      const { data } = await ApploCLI.query({
        query: generateUrl,
        context: { headers: { Authorization: token ? `Bearer ${token}` : '' } },
        fetchPolicy: 'network-only',
      });
      setUrl(data?.url?.url);
    } catch (error) {
      console.error('Error generating URL', error);
    }
  };

  // Fetch Google Ads Accounts
  const fetchAccounts = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getAccounts,
        context: { headers: { Authorization: token ? `Bearer ${token}` : '' } },
        fetchPolicy: 'network-only',
      });

      const activeAccount = data?.accounts?.filter((acc) => acc.active);
      const inactiveAccount = data?.accounts?.filter((acc) => !acc.active);

      setAccounts(data?.accounts || []);
      setActiveAccounts(activeAccount || []);
      setInactiveAccounts(inactiveAccount || []);

      if (activeAccount?.length > 0) {
        setStep(3);
      } else {
        setStep(2);
      }
    } catch (error) {
      console.error('Error fetching accounts', error);
    }
  }, [token]);

  useEffect(() => {
    fetchGooglePlatform();
    fetchAccounts();
  }, [fetchGooglePlatform, fetchAccounts]);

  // Open Google Integration Popup
  const openPopup = () => {
    setLoading(true);
    let windowObjectReference = null;
    const strWindowFeatures =
      'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    if (!windowObjectReference || windowObjectReference.closed) {
      windowObjectReference = window.open(url, '', strWindowFeatures);
    } else {
      windowObjectReference.focus();
    }

    const timer = setInterval(() => {
      if (windowObjectReference.closed) {
        clearInterval(timer);
        setLoading(false);
      }
    }, 1000);

    let interval = window.setInterval(() => {
      try {
        const temp = windowObjectReference.location;
        if (
          temp.hostname === 'localhost' ||
          temp.hostname === 'app.live-metrics.io'
        ) {
          window.clearInterval(interval);
          const code = temp.href.split('?')[1].split('&')[0].split('=')[1];
          integrateGoogle(code);
          windowObjectReference.close();
          setLoading(false);
          setStep(3);
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  // Integrate Google Ads
  const integrateGoogle = async (google_code) => {
    try {
      const { data } = await ApploCLI.mutate({
        mutation: integrate,
        context: { headers: { Authorization: token ? `Bearer ${token}` : '' } },
        variables: { code: google_code || '' },
        errorPolicy: 'all',
      });

      if (data?.integration) {
        setStep(3);
        fetchAccounts();
      }
    } catch (error) {
      console.error('Integration error', error);
    }
  };

  // Delete Google Connection
  const deleteConnection = () => {
    setDeleteLoading(true);
    ApploCLI.mutate({
      mutation: deleteGoogle,
      context: { headers: { Authorization: token ? `Bearer ${token}` : '' } },
      errorPolicy: 'all',
    })
      .then(({ data }) => {
        if (data?.delete?.isDeleted) {
          setDeleteLoading(false);
          setStep(1);
          setActiveAccounts([]);
        }
      })
      .catch((error) => {
        setDeleteLoading(false);
        console.error('Delete connection error', error);
      });
  };

  // Handle toggling of accounts between active and inactive using the toggleGoogle mutation
  const handleToggle = async (adAccountId) => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: toggleGoogle,
        variables: { adAccount: adAccountId },
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          },
        },
        errorPolicy: 'all',
      });

      if (data?.toggle) {
        const newState = data.toggle.active;

        // Display messages based on the new state
        if (newState) {
          message.success('Account activated successfully!');
        } else {
          message.success('Account deactivated successfully!');
        }

        // Update active and inactive accounts in state immediately
        if (newState) {
          // Move it to active and deactivate the other active account
          setActiveAccounts([data.toggle]); // Only one active account
          setInactiveAccounts((prev) => [
            ...prev,
            ...activeAccounts.filter((account) => account.id !== adAccountId),
          ]); // Move previously active to inactive
          setInactiveAccounts((prev) =>
            prev.filter((account) => account.id !== adAccountId)
          );
        } else {
          // If the account was active, move it to inactiveAccounts
          setInactiveAccounts((prev) => [...prev, data.toggle]);
          setActiveAccounts([]);
        }

        // Optionally, you can re-fetch the data to ensure the latest state from the backend
        await fetchAccounts(); // Replace this with your Google fetch function
      } else {
        message.error('Failed to toggle the account. Please try again.');
      }
    } catch (err) {
      message.error('An error occurred while toggling the account.');
      console.error('Toggle Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const [filteredData, setFilteredData] = useState(inactiveAccounts);

  useEffect(() => {
    setFilteredData(inactiveAccounts);
  }, [inactiveAccounts]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
  
    // Filter inactiveAccounts directly (not filteredData)
    const filtered = inactiveAccounts.filter((item) =>
      item.customer_name.toLowerCase().includes(value)
    );
  
    setFilteredData(filtered);
  };
console.log('filteredData',filteredData)
  // Table columns definition
  const columns = [
    {
      title: 'ACCOUNT NAME',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (text) => <span className="table-cell-text">{text}</span>,
    },
    {
      title: 'ACTION',
      key: 'action',
      width: 140, // Set a fixed width for the ACTION column
      align: 'center', // Align the content of the ACTION column to the center
      render: (_, record) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#5CCF5C',
              colorTextQuaternary: '#6E6893',
              opacityLoading: 1,
            },
          }}
        >
          <Switch
            checked={record.active}
            disabled={!record.active && activeAccounts.length > 0}
            onChange={() => handleToggle(record.id)}
            loading={loading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </ConfigProvider>
      ),
    },
  ];

  return (
    <div
      style={{ borderColor: '#D9D5EC', borderRadius: '3px', opacity: '1px' }}
    >
      <Row>
        <Col>
          <Card className="border text-secondary">
            <CardHeader style={{ borderBottom: 'none' }}>
              <img src={GoogleAdsIcon} alt="Google Ads" />
            </CardHeader>
            <CardBody>
              {step === 1 && ( 
                <>
                  <p style={{ fontSize: '16px', fontWeight: '400' }}>
                    {t('Connect your Google Ads Store within 30 seconds')}
                  </p>
                  <button
                    onClick={openPopup}
                    className="btn btn-primary"
                    style={{
                      width: '100%',
                      backgroundColor: '#0080F9',
                      borderRadius: '6px',
                      fontSize: '17px',
                      fontWeight: '500',
                      border: 'none',
                    }}
                  >
                    {t('Connect')}
                  </button>
                </>
              )}
              {step !== 1 && (
                <>
                  <h5
                    className=""
                    style={{ marginBottom: '20px', fontWeight: '600' }}
                  >
                    Choose Account
                  </h5>
                  <div
                    className="search-box"
                    style={{
                      flex: 1,
                      maxWidth: '523px',
                      position: 'relative',
                      backgroundColor: '#F4F2FF',
                      borderRadius: '6px',
                      padding: '10px 15px',
                      marginBottom: '10px',
                    }}
                  >
                    <Input
                      placeholder={i18next.t('Convergen - P')}
                      value={searchText}
                      className="custom-search-input"
                      onChange={handleSearchInputChange}
                    />
                    <i
                      className="bx bx-search-alt search-icon"
                      style={{
                        position: 'absolute',
                        left: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#7a7a7a',
                      }}
                    />
                  </div>

                  <Spin spinning={loading}>
                    {activeAccounts.length > 0 && (
                      <Table
                        bordered
                        columns={columns}
                        dataSource={activeAccounts}
                        rowKey="id"
                        pagination={false}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      />
                    )}
                    <Table
                      bordered
                      columns={columns}
                      dataSource={filteredData}
                      rowKey="id"
                      pagination={{
                        pageSize: 5,
                        showSizeChanger: false,
                        style: {
                          backgroundColor: '#F4F2FF',
                          padding: '10px 15px',
                          marginTop: '2px',
                          borderRadius: '6px',
                          fontsize: '14px',
                          fontweight: '700',
                          color: '#6E6893',
                        },
                      }}
                      headerCellStyle={{
                        backgroundColor: '#FF0000', // Change this to your desired color
                        color: '#333333', // Text color
                      }}
                    />
                  </Spin>
                  {activeAccounts.length === 0 ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        style={{
                          width: '100%',
                          backgroundColor: '#F4F2FF',
                          borderRadius: '6px',
                          fontSize: '17px',
                          fontWeight: '500',
                          border: 'none',
                          marginTop: '10px',

                          color: '#6E6893',
                        }}
                        onClick={() => deleteConnection()}
                      >
                        {t('Delete Connect ')}
                      </button>
                    </div>
                  ) : null}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

GoogleAdsCard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(GoogleAdsCard));
