import React, { useState, useEffect } from 'react';
import {
  Row,
  Container,
} from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';


import {
  getCompareFacebookPageDataPerDate,
  getFacebookPageDataPerDate,
  getFacebookPagePostData,
} from '../../store/facebookPage/actions';
import secureSection from '../../MangeSecurity/MemberSecurity';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import FbSocialMediaCards from './fbSocialMediaCards';
import FbSocialMediaPostsTable from './fbSocialMediaPostsTable';
import { formatDate } from '../GoogleAnalytics/common/HelperFunctions/utils';
import { useSelector, useDispatch } from 'react-redux';
import { logoMap } from '../../assets/integrations_images/logoMap';
import FbSocialMediaActiveBar from './fbSocialMediaActiveBar';



function FbSocialMediaDashboard(props) {
  secureSection();
  const dispatch = useDispatch();
  const { facebookPageData, facebookPagePostData } = useSelector(
    (state) => state.facebookPage
  );


  const [likes_numbre, setLikes_numbre] = useState([]);
  const [compareLikesNumber, setCompareLikesNumber] = useState([]);
  const [post_impressions, setpost_impressions] = useState([]);
  const [comparePostImpressions, setComparePostImpressions] = useState([]);
  const [post_engagements, setpost_engagements] = useState([]);
  const [comparePostEngagement, setComparePostEngagement] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [compareFollowers, setCompareFollowers] = useState([]);
  const [monthly_impressions, setMonthly_impressions] = useState([]);
  const [compareMonthlyImpressions, setCompareMonthlyImpressions] = useState(
    []
  );
  const [page_impressions_paid, setpage_impressions_paid] = useState([]);
  const [comparePageImpressionsPaid, setComparePageImpressionsPaid] = useState(
    []
  );
  const [page_posts_impressions_paid, setpage_posts_impressions_paid] =
    useState([]);

  const [comparePagePostsImpressionsPaid, setComparePagePostsImpressionsPaid] =
    useState([]);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
 
  useEffect(() => {
    dispatch(getFacebookPagePostData());
  }, []);

  // GET PAGE DATA
  useEffect(() => {
    if (
      facebookPageData &&
      facebookPageData.length > 0 &&
      facebookPageData[0].length > 0
    ) {
      let T_page_posts_impressions_paid = [];
      let T_monthly_impressions = [];
      let T_post_engagements = [];
      let T_post_impressions = [];
      let T_page_impressions_paid = [];
      let T_Likes_number = [];
      let T_Followers = [];

      facebookPageData[0].forEach((item) => {
        T_Followers.push(item.followers);
        T_Likes_number.push(item.likes_numbre);
        T_monthly_impressions.push(item.monthly_impressions);
        T_post_engagements.push(item.post_engagements);
        T_post_impressions.push(item.post_impressions);
        T_page_impressions_paid.push(item.page_impressions_paid);
        T_page_posts_impressions_paid.push(item.page_posts_impressions_paid);
      });

      setLikes_numbre(T_Likes_number);
      setFollowers(T_Followers);
      setpost_engagements(T_post_engagements);
      setpost_impressions(T_post_impressions);
      setMonthly_impressions(T_monthly_impressions);
      setpage_impressions_paid(T_page_impressions_paid);
      setpage_posts_impressions_paid(T_page_posts_impressions_paid);
      let TCompareLikesNumber = [];
      let TCompareFollowers = [];
      let TCompareMonthlyImpressions = [];
      let TComparePostEngagement = [];
      let TComparePostImpressions = [];
      let TComparePageImpressionsPaid = [];
      let TComparePagePostsImpressionsPaid = [];
      if (facebookPageData[1] && facebookPageData[1].length > 0) {
        facebookPageData[1].forEach((item) => {
          TCompareFollowers.push(item.followers);
          TCompareLikesNumber.push(item.likes_numbre);
          TCompareMonthlyImpressions.push(item.monthly_impressions);
          TComparePostEngagement.push(item.post_engagements);
          TComparePostImpressions.push(item.post_impressions);
          TComparePageImpressionsPaid.push(item.page_impressions_paid);
          TComparePagePostsImpressionsPaid.push(
            item.page_posts_impressions_paid
          );
        });
      }
      setCompareLikesNumber(TCompareLikesNumber);
      setCompareFollowers(TCompareFollowers);
      setCompareMonthlyImpressions(TCompareMonthlyImpressions);
      setComparePostEngagement(TComparePostEngagement);
      setComparePostImpressions(TComparePostImpressions);
      setComparePageImpressionsPaid(TComparePageImpressionsPaid);
      setComparePagePostsImpressionsPaid(TComparePagePostsImpressionsPaid);
    }
  }, [facebookPageData]);

  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      const newStartDate = formatDate(dateRange.selection1.startDate);
      const newEndDate = formatDate(dateRange.selection1.endDate);
      if (dateRange.selection2.startDate && dateRange.selection2.startDate) {
        const compareStartDateFormatted = formatDate(
          dateRange.selection2.startDate
        );
        const compareEndDateFormatted = formatDate(
          dateRange.selection2.endDate
        );
        dispatch(
          getCompareFacebookPageDataPerDate(
            newStartDate,
            newEndDate,
            compareStartDateFormatted,
            compareEndDateFormatted
          )
        );
      } else dispatch(getFacebookPageDataPerDate(newStartDate, newEndDate));
    }
  }, [dateRange]);

  const [secCards, setsecCards] = useState([
    {
      id: 2,
      name: 'Likes',
      data: likes_numbre,
      compareData: compareLikesNumber,
    },
    {
      id: 3,
      name: 'Followers',
      data: followers,
      compareData: compareFollowers,
    },

    {
      id: 4,
      name: 'Monthly Impressions',
      data: monthly_impressions,
      compareData: compareMonthlyImpressions,
    },
    {
      id: 5,
      name: 'Post Engagements',
      data: post_engagements,
      compareData: comparePostEngagement,
    },
    {
      id: 6,
      name: 'Post Impressions',
      data: post_impressions,
      compareData: comparePostImpressions,
    },
    {
      id: 7,
      name: 'Page Impressions Paid',
      data: page_impressions_paid,

      compareData: comparePageImpressionsPaid,
    },
    {
      id: 8,
      name: 'Page Posts Impressions Paid',
      data: page_posts_impressions_paid,
      compareData: comparePagePostsImpressionsPaid,
    },
  ]);

  const colors = [
    {
      lineColor: '#4A3AFF',
      fillColor: [
        {
          offset: 0,
          color: '#93AAFD',
        },
        {
          offset: 0.47,
          color: '#C6D2FD',
        },
        {
          offset: 1,
          color: '#E5EAFC4F',
        },
      ],
    },
    {
      lineColor: '#F6B43B',
      fillColor: [
        {
          offset: 0,
          color: ' #F6B43B',
        },

        {
          offset: 1,
          color: '#D9D9D9',
        },
      ],
    },
  ];

  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 2,
        name: 'Likes',
        data: likes_numbre,
        compareData: compareLikesNumber,
      },
      {
        id: 3,
        name: 'Followers',
        data: followers,
        compareData: compareFollowers,
      },

      {
        id: 4,
        name: 'Monthly Impressions',
        data: monthly_impressions,
        compareData: compareMonthlyImpressions,
      },
      {
        id: 5,
        name: 'Post Engagements',
        data: post_engagements,
        compareData: comparePostEngagement,
      },
      {
        id: 6,
        name: 'Post Impressions',
        data: post_impressions,
        compareData: comparePostImpressions,
      },
      {
        id: 7,
        name: 'Page Impressions Paid',
        data: page_impressions_paid,

        compareData: comparePageImpressionsPaid,
      },
      {
        id: 8,
        name: 'Page Posts Impressions Paid',
        data: page_posts_impressions_paid,
        compareData: comparePagePostsImpressionsPaid,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    likes_numbre,
    followers,
    monthly_impressions,
    post_impressions,
    post_engagements,
    page_impressions_paid,
    page_posts_impressions_paid,
    compareLikesNumber,
    compareFollowers,
    compareMonthlyImpressions,
    comparePostEngagement,
    comparePostImpressions,
    comparePageImpressionsPaid,
    comparePagePostsImpressionsPaid,
  ]);
  return (
    <>
        <title>
          {props.t('Facebook Social Media Dashboard')} | Live-Metrics -
          E-com SaaS
        </title>
      <div className="page-content">
        <FbSocialMediaActiveBar />
        <Container fluid className="print p-4">
          <Breadcrumbs
            title={props.t('Facebook Social Media Dashboard')}
            pageHeading={props.t('Facebook Page Dashboard')}
            image={logoMap['Facebook Social Media']}
            breadcrumbItem={props.t('Insights')}
          />
          <Row className=" justify-content-center my-5">
            <FbSocialMediaCards secCards={secCards} colors={colors} />
          </Row>
          <Breadcrumbs
            title={props.t('Facebook Page posts last 30 days')}
            pageHeading={props.t('Facebook Page Dashboard')}
            image={logoMap['Facebook Social Media']}
            breadcrumbItem={props.t('Posts')}
          />
          <Row>
            <FbSocialMediaPostsTable dataSource={facebookPagePostData} />
          </Row>
        </Container>
      </div>
    </>
  );
}
FbSocialMediaDashboard.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(FbSocialMediaDashboard));
//export default FbSocialMediaDashboard
