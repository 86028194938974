import React, { useEffect } from 'react';
import { Nav, CardBody, Row, Col, Card, CardHeader, Alert, Progress } from 'reactstrap';
import { alert } from '../Alerts/NotLinkedAccountAlert';
import { getAllTrackingStatusRequest, fetchDataRequest, checkIntegrationRequest } from '../../store/aramexState/action';
import { useDispatch, connect } from 'react-redux';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import secureSection from '../../MangeSecurity/MemberSecurity';
import "../PrestaDashbaord/datatables.scss";
import AramexIcon from "../Integrations/Aramex/aramex.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const AramexTracking = ({ checkIntegrationdata, trackingNStatus, isLoading, checkIntegration, getAllTrackingStatus, fetchData, ShipmentChargesPaid, Delivered, ReturnedtoShipper, SupportingDocumentReturnedtoShipper, t }) => {
  const history = useHistory();
  secureSection();
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (checkIntegrationdata === 'active') {
      dispatch(getAllTrackingStatus());
    } else {
      alert().then(() => {
        history.push("/integrations");
      });
    }
  };

  useEffect(() => {
    dispatch(checkIntegration());

    if (checkIntegrationdata === 'active') {
      dispatch(fetchData(500, 1));
    }
  }, [dispatch, checkIntegrationdata]);

  const columns = [
    {
      dataField: 'order_id',
      text: t("order Id"),
      sort: true
    },
    {
      dataField: 'shipping_number',
      text: t('Shipping Number'),
      sort: true
    },
    {
      dataField: 'username',
      text: t('Username'),
      sort: false
    },
    {
      dataField: 'email',
      text: t('Email'),
      sort: false
    },
    {
      dataField: 'updatedtcode',
      text: t('Updated Code'),
      sort: false,
    },
    {
      dataField: 'updateddiscription',
      text: t('Updated Description'),
      sort: false,
    }
  ];

  let dataMaparamexTrackingNumbers = [];
  if (trackingNStatus && Array.isArray(trackingNStatus)) {
    dataMaparamexTrackingNumbers = trackingNStatus.flatMap((item) => {
      if (item.aramex && Array.isArray(item.aramex.trackingNumber)) {
        return item.aramex.trackingNumber;
      }
      return [];
    });
  }

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: dataMaparamexTrackingNumbers.length,
    custom: true,
  };

  const selectRow = {
    mode: 'checkbox'
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <title>{t('Aramex Dashboard')} | Convergen Metrics - E-COM SASS</title>
        <div className="container-fluid">
          <Breadcrumbs title={t('Dashboards')} breadcrumbItem={t('Aramex Dashboard')} />
          <Row>
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t('Aramex Tracking Orders')}
                  <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip={t("This is last 30 days' data")}></i>
                </h4>
                <div className="flex-shrink-0">
                  <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                    <img className="img-intgration-icon" src={AramexIcon} alt="Aramex Icon" />
                  </Nav>
                </div>
              </div>
              <CardBody>
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    {isLoading ? (
                      <>
                        <Progress className="mt-3" value={80} color="success" style={{ width: '100%' }} animated />
                        <Alert color="success" className="mt-3">
                          {t("This might take a few minutes!")}
                        </Alert>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light ml-10"
                        onClick={handleClick}
                      >
                        {t("Click To Get Data")}
                      </button>
                    )}
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        {/* Render table here with appropriate library */}
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline"></div>
                      <div className="text-md-right ms-auto"></div>
                    </Col>
                  </Row>
                </React.Fragment>
              </CardBody>
            </div>
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">{t('Tracking Orders Status')}</h4>
                  </CardHeader>
                  <CardBody>
                    <Row className="g-4">
                      <Col sm={3}>
                        <Alert color="success" className="text-center">
                          <i className="mdi mdi-check-all d-block display-4 mt-2 mb-3 text-success"></i>
                          <h5 className="text-success">{Delivered}</h5>
                          <p>{t("Delivered")}</p>
                        </Alert>
                      </Col>
                      <Col sm={3}>
                        <Alert color="info" className="text-center">
                          <i className="mdi mdi-alert-circle-outline d-block display-4 mt-2 mb-3 text-info"></i>
                          <h5 className="text-info">{ShipmentChargesPaid}</h5>
                          <p>{t("Shipment Charges Paid")}</p>
                        </Alert>
                      </Col>
                      <Col sm={3}>
                        <Alert color="warning" className="text-center">
                          <i className="mdi mdi-alert-outline d-block display-4 mt-2 mb-3 text-warning"></i>
                          <h5 className="text-warning">{SupportingDocumentReturnedtoShipper}</h5>
                          <p>{t("Supporting Document Returned to Shipper")}</p>
                        </Alert>
                      </Col>
                      <Col sm={3}>
                        <Alert color="danger" className="text-center">
                          <i className="mdi mdi-block-helper d-block display-4 mt-2 mb-3 text-danger"></i>
                          <h5 className="text-danger">{ReturnedtoShipper}</h5>
                          <p>{t("Returned to Shipper")}</p>
                        </Alert>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

AramexTracking.propTypes = {
  t: PropTypes.func,
  checkIntegrationdata: PropTypes.string,
  trackingNStatus: PropTypes.array,
  isLoading: PropTypes.bool,
  checkIntegration: PropTypes.func,
  getAllTrackingStatus: PropTypes.func,
  fetchData: PropTypes.func,
  ShipmentChargesPaid: PropTypes.number,
  Delivered: PropTypes.number,
  ReturnedtoShipper: PropTypes.number,
  SupportingDocumentReturnedtoShipper: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isLoading: state.aramex.isLoading,
  trackingNStatus: state.aramex.trackingNStatus,
  ShipmentChargesPaid: state.aramex.ShipmentChargesPaid,
  SupportingDocumentReturnedtoShipper: state.aramex.SupportingDocumentReturnedtoShipper,
  ReturnedtoShipper: state.aramex.ReturnedtoShipper,
  Delivered: state.aramex.Delivered,
  checkIntegrationdata: state.aramex.checkIntegrationdata,
});

const mapDispatchToProps = {
  getAllTrackingStatus: getAllTrackingStatusRequest,
  fetchData: fetchDataRequest,
  checkIntegration: checkIntegrationRequest,
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AramexTracking)));
