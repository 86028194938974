import{
    GET_fACEBOOK_CAMPAIGNS_PER_DATE,
    GET_fACEBOOK_CAMPAIGNS_PER_DATE_SUCCESS,
    GET_fACEBOOK_CAMPAIGNS_PER_DATE_FAILURE,
    GET_FACEBOOK_CAMPAIGNS,
    GET_FACEBOOK_CAMPAIGNS_SUCCESS,
    GET_FACEBOOK_CAMPAIGNS_FAILURE,
    GET_FB_CAMPAIGN_METRICS,
    GET_FB_CAMPAIGN_METRICS_SUCCESS,
    GET_FB_CAMPAIGN_METRICS_FAILURE,
    GET_ADS_FACEBOOK,
    GET_ADS_FACEBOOK_SUCCESS,
    GET_ADS_FACEBOOK_FAILURE,
} from './actions';




const initialState = {

    metrcisbyCompaign: [],
    campaigns:[],
    dataDashboard:[],
    ads:[],
    isLoading: false,
};



export default function facebookAds(state = initialState, action) {
    switch(action.type){
        case GET_fACEBOOK_CAMPAIGNS_PER_DATE:
            return {
                ...state,
                isLoading: true,
            }
        case GET_fACEBOOK_CAMPAIGNS_PER_DATE_SUCCESS:
            return {
                ...state,
                metrcisbyCompaign: action.payload.metrcisbyCompaign,
                isLoading: false,
            }
        case GET_fACEBOOK_CAMPAIGNS_PER_DATE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case GET_FACEBOOK_CAMPAIGNS:
            return {
              ...state,
              isLoading: true,
            };
        case GET_FACEBOOK_CAMPAIGNS_SUCCESS:
            return {
              ...state,
              campaigns: action.payload.campaigns,
              isLoading: false,
            };
        case GET_FACEBOOK_CAMPAIGNS_FAILURE:
            return {
              ...state,
              error: action.payload,
              isLoading: false,
            };

        case GET_FB_CAMPAIGN_METRICS:
            return {
              ...state,
              isLoading: true,
            };
        case GET_FB_CAMPAIGN_METRICS_SUCCESS:
            return {
              ...state,
              dataDashboard: action.payload.dataDashboard,
              isLoading: false,
            };
        case GET_FB_CAMPAIGN_METRICS_FAILURE:
            return {
              ...state,
              error: action.payload,
              isLoading: false,
            };
        case GET_ADS_FACEBOOK:
            return {
              ...state,
              isLoading: true,
            };
        case GET_ADS_FACEBOOK_SUCCESS:
            return {
              ...state,
              ads: action.payload.ads,
              isLoading: false,
            };
        case GET_ADS_FACEBOOK_FAILURE:
            return {
              ...state,
              error: action.payload,
              isLoading: false,
            };
        default:
            return state;

    }
}