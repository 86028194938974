import PropTypes from 'prop-types';
import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { userRoutes, authRoutes } from './routes/allRoutes';
import AuthExpirationMiddleware from './routes/middleware/Authmiddleware';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import store from './store/index';
import './assets/scss/theme.scss';
import './assets/scss/preloader.scss';
import { ApolloClient, InMemoryCache, ApolloProvider ,HttpLink} from '@apollo/client';  // Updated import
import urls from './routes/apiUrls';
import Error404 from './pages/Utility/Error404';
import { google } from './config';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  // Initialize Apollo Client with correct imports
  const client = new ApolloClient({
    link: new HttpLink({
      uri: urls.login,  // Use your GraphQL endpoint here
      credentials: 'include', // Add cookies if needed
      
    }),
    cache: new InMemoryCache(),
  });

  const Layout = getLayout();
  const allowedPaths = [
    '/login',
    '/logout',
    '/forgot-password',
    '/register',
    '/pages-maintenance',
    '/pages-404',
    '/pages-500',
    '/settings',
  ];
  const restrictedPaths = [
    '/login',
    '/logout',
    '/forgot-password',
    '/register',
    '/pages-404',
    '/pages-500',
  ];

  return (
    <React.Fragment>
      <Router>
        <GoogleOAuthProvider clientId={google.GOOGLE_CLIENT_ID}>
          <Provider store={store}>
            <ApolloProvider client={client}>  {/* Updated ApolloProvider */}
              <Switch>
                {authRoutes.map((route, idx) => (
                  <AuthExpirationMiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    allowedPaths={allowedPaths}
                    restrictedPaths={restrictedPaths}
                    exact
                  />
                ))}

                {userRoutes.map((route, idx) => (
                  <AuthExpirationMiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    restrictedPaths={restrictedPaths}
                    allowedPaths={allowedPaths}
                    exact
                  />
                ))}
                <Route path="/*">
                  <Error404 />
                </Route>
              </Switch>
            </ApolloProvider>
          </Provider>
        </GoogleOAuthProvider>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    dateRange: state.dateRangeReducer,
  };
};

export default connect(mapStateToProps)(App);

