import gql from 'graphql-tag';

const updateUser = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $mobile: String!
    $image:String!
  ) {
    update(
      firstName: $firstName
      lastName: $lastName
      email: $email
      mobile: $mobile
      image:$image
    ) {
      data {
        firstname
        lastname
        email
        mobile
        Image
      }
      token
    }
  }
`;

const resetPass = gql`
  mutation ($password: String!) {
    reset(password: $password) {
      password
    }
  }
`;

const uploadimge = gql`
  mutation ($image: String!) {
    uploadImage(image: $image) {
      _id
    }
  }
`;

const changeImage = gql`
  mutation Image($img: Upload!, $token: String!) {
    image(img: $img, token: $token) {
      filename
    }
  }
`;

const newUser = gql`
  mutation ($email: String, $name: String) {
    add(email: $email, name: $name) {
      email
    }
  }
`;

const newUserWithGoogle = gql`
  mutation ($token: String) {
    addWithGoogle(token: $token) {
      email
    }
  }
`;

const globalCost = gql`
  mutation ($cogs_ratio: Float!, $shipping_ratio: Float!) {
    addPercentage(cogs_ratio: $cogs_ratio, shipping_ratio: $shipping_ratio) {
      token
    }
  }
`;

const forgetPass = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      email
    }
  }
`;

const getByEmail = gql`
  query ($email: String!) {
    getByEmail(email: $email) {
      status
    }
  }
`;
const getaccount = gql`
  query {
    get {
      new_account
      email
      firstname
      lastname
      fullName
      Image
      mobile
      PlanType
      expirationDate
      isVerified
    }
  }
`;
const isNewUser = gql`
  mutation {
    isNewUser {
      last_login
      new_account
    }
  }
`;
const Paiment = gql`
  mutation ($amount: Int!, $Type: String!,$Period : String!) {
    paiement(amount: $amount, Type: $Type ,Period : $Period) {
      link
      payment_id
    }
  }
`;

const verifPaiment = gql`
  query ($id_paiement: String!, $Type: String!, $Amount: String! ,$Period : String!) {
    verif_paiement(id_paiement: $id_paiement, Type: $Type, Amount: $Amount ,Period : $Period) {
      link
      payment_id
      success
      Plan
    }
  }
`;
const checkExpiration = gql`
  query {
    checkExpiration
  }
`;
const getInvoice = gql`
  query {
    getInvoice {
      status
      Amount
      Plan
      payment_id
      DatePaiement
      method
      User {
        lastname
        firstname
        email
      }
    }
  }
`;

export {
  resetPass,
  updateUser,
  changeImage,
  newUser,
  globalCost,
  getByEmail,
  isNewUser,
  getaccount,
  uploadimge,
  Paiment,
  verifPaiment,
  checkExpiration,
  getInvoice,
  forgetPass,
  newUserWithGoogle,
};
