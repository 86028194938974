// MetricCard.js
import React, { useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import GoogleAdsCardPercentage from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCardPercentage';
import {formatNumberWithSpaces} from '../GoogleAnalytics/common/HelperFunctions/utils';

const WooMetricCard = ({ title, value, percentageChange, seriesData, compareData }) => {
  const chartOptions = useMemo(() => ({
    chart: {
      toolbar: { show: false },
      type: "bar",
      height: 120,
      sparkline: { enabled: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        endingShape: 'rounded',
        borderRadius: 4,
      },
    },
    stroke: { show: true, width: 3, colors: ['transparent'] },
    dataLabels: { enabled: false },
    xaxis: { labels: { show: true }, tickPlacement: 'on' },
    yaxis: {
      show: true,
      labels: {
        show: true,
        formatter: function (value) {
          return Math.round(value); // Round to the nearest whole number
        },
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    legend: { show: false },
    grid: { show: true, borderColor: 'black', strokeDashArray: [1,4] },
    series: [
      { name: "Sales", data: seriesData },
      { name: "Compared To", data: compareData },
    ],
    colors: ["#F6B43B", "#EC1A74"],
  }), [seriesData, compareData]);

  return (
    <div className="dashboardMinorCardItem">
      <Card style={{ height: "431px" }}>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ fontSize: "15px", fontWeight: "500", color: "#9291A5" }}>{title}</span>
            {percentageChange !== null && (
              <GoogleAdsCardPercentage percentage={percentageChange} />
            )}
          </div>
         
              <h5 style={{ fontSize: "34px", fontWeight: "650", color: "#1E1B39", marginTop: "5px" }}>
               {formatNumberWithSpaces(value)}
              </h5>
           
         
          <ReactApexChart options={chartOptions} series={chartOptions.series} type="bar" width={290} height={290} />
        </CardBody>
      </Card>
    </div>
  );
};

WooMetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentageChange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seriesData: PropTypes.array.isRequired,
  compareData: PropTypes.array.isRequired,
};

export default React.memo(WooMetricCard);
