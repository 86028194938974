import React, { useState, useEffect } from "react";
import { Table, Placeholder } from "semantic-ui-react";
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import CheckBoxMembers from "./CheckBoxMembers";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import secureSection from "../../../MangeSecurity/MemberSecurity";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getMemberRequest, restGetMemberRequest, addMemberRequest } from '../../../store/Member/action';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MembreIcon from "../../../assets/images/ajouter.png";

function Members(props) {
    const { members, loadingState, successState, failuresate,error } = useSelector((state) => state.member);
    const dispatch = useDispatch();

    secureSection();
    const [addMember, setAddMember] = useState(false);
    const [editMember, setEditMember] = useState(false);
    
    const [selectedSections, setSelectedSections] = useState([]);
    const [email, setEmail] = useState('');
    const [edit, setEdit] = useState(true);

    useEffect(() => {
        dispatch(getMemberRequest());
    }, [addMember, editMember, props.selectedAccount, successState]);

    const resetState = () => {
        dispatch(restGetMemberRequest());
    };

    const optionMulti = [
        { label: "social", value: "social" },
        { label: "ads platform", value: "ads platform" },
        { label: "prestashop platform", value: "prestashop platform" },
        { label: "woocommerce platform", value: "woocommerce platform" },
        { label: "Aramex and emailing", value: "Aramex and emailing" },
    ];

    const openModal = () => {
        setAddMember(true);
    };

    const closeModal = () => {
        setAddMember(false);
    };

    const notifySuccess = () => {
        toast.success('Member Added successfully! 🤝 Please Logout and Login again! 🤟', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    const notifyError = (error) => {
        toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    const save = async () => {
        if (!validateEmail(email)) {
            toast.error('Invalid email address!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        const storeName = jwt.decode(window.localStorage.getItem("token")).account._id;
        const sections = selectedSections;
          dispatch(addMemberRequest(email, storeName, sections));
       if( failuresate ){
        notifyError(error);
       }
    };
   

    const handleCheckboxChange = (e) => {
        const section = e.target.value;
        if (e.target.checked) {
            setSelectedSections([...selectedSections, section]);
        } else {
            setSelectedSections(selectedSections.filter(item => item !== section));
        }
    };

    const validateEmail = (email) => {
        const regexEmailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexEmailPattern.test(String(email).toLowerCase());
    };

    return (
        <React.Fragment>
            <div className="page-content">
                    <title>Manage Members</title>
                <Container fluid>
                    <Breadcrumbs title="Manage Members" breadcrumbItem={props.t("Members")} />
                    <button className="btn btn-primary w-20 waves-effect waves-light" onClick={openModal}>
                        {props.t("+ Add Member")}
                    </button>
                    <Modal isOpen={addMember} toggle={closeModal} className='modal-dialog-centered modal-refer-earn'>
                        <ModalHeader className='bg-transparent' toggle={closeModal}>Member Access Form</ModalHeader>
                        <ModalBody className='pb-3 px-sm-0' style={{ maxWidth: '500px' }}>
                            <div className='text-center'>
                                <img style={{ height: '60px' }} src={MembreIcon} alt="Member Icon" />
                                <h3 className='text-center mb-2'>Members Access</h3>
                                <p className='text-center mb-4'>
                                    {props.t('Member Integration and Page Access Management System')}
                                </p>
                            </div>
                            <div className="d-flex flex-column align-items-center mb-3">
                                <label htmlFor="email">Enter Email:</label>
                                <input
                                    required
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    style={{ width: '400px', textAlign: 'center' }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                 {failuresate  && (
                        <div className="text-danger mt-2" style={{ width: '400px', textAlign: 'center' }}>
                            {error}
                        </div>
                    )}
                            </div>
                            <div className="text-center mb-3">
                                <label htmlFor="accessControl">Access Control:</label>
                            </div>
                            <div className="checkbox-container">
                                {optionMulti.map(option => (
                                    <div key={option.value} className="form-check mr-4 mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={option.value}
                                            value={option.value}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor={option.value}>{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={closeModal}>Cancel</Button>
                            <Button color="primary" type="button" onClick={save}>Save</Button>
                        </ModalFooter>
                    </Modal>
                    {loadingState
                        ? <div className="tab">
                            <Table className="table table-striped table-bordered">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={4} textAlign="left">{props.t("Email")}</Table.HeaderCell>
                                        <Table.HeaderCell width={4} textAlign="left">{props.t("Access")}</Table.HeaderCell>
                                        <Table.HeaderCell width={4} textAlign="left">{props.t("Last Active")}</Table.HeaderCell>
                                        <Table.HeaderCell width={4} textAlign="center">Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {members && Array.isArray(members) && members.length > 0
                                        ? members.map((user) => (
                                            user && <CheckBoxMembers
                                                user={user}
                                                key={user.email}
                                                editMember={setEditMember}
                                                selectedAccount={props.selectedAccount}
                                                reset={resetState}
                                            />
                                        ))
                                        : <tr>
                                            <td colSpan="4">
                                                <div style={{ backgroundColor: "white", borderRadius: '10px', padding: "10px", marginTop: "40px" }}>
                                                    <Placeholder fluid>
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                    </Placeholder>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                        : <div style={{ backgroundColor: "white", borderRadius: '10px', padding: "10px", marginTop: "40px" }}>
                            <Placeholder fluid>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        </div>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(Members));
