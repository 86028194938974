// SettingsTab.js

import React from 'react';
import SettingsTable from './SettingsTable';

const SettingsTab = () => {
    return (
        <div className='pricing-page'>
        
            <div className="pricing-header">
                <h1>Custom settings</h1>
                <p>Those settings will affect the data shown in the overall overview page</p>
            </div>
            <SettingsTable />

        </div>
        
    );
};

export default SettingsTab;  // Make sure this is a default export
