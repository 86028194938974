import React from 'react';
import robotImg from '../../assets/images/robot.png'; // Assuming you have a robot image
import logoImg from '../../assets/images/Live Metrics White Logo.png';  // Assuming you have a logo image

const CarouselRegister = () => {
    return (
        <React.Fragment>
            <div className="col-xxl-6 col-lg-6 col-md-7">
                <div className="auth-bg d-flex align-items-end justify-content-center">
                    <div className="position-absolute top-0 start-0" style={{marginLeft:'6vw', marginTop:'6vh'}}>
                    <a
                        href="https://live-metrics.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img 
                        src={logoImg} 
                        alt="Logo" 
                        className="img-fluid responsive-logo"  // Added a class for more flexible styling
                        style={{
                            width: '15vw',   // Use viewport width for scaling
                            maxWidth: '220px',  // Set a maximum width
                            maxHeight: '36px',  // Set a maximum height
                            height: 'auto',
                            objectFit: 'contain',
                        }} />
                    </a>
                    </div>

                    <div className="left-side-content text-center text-white">
                        <img 
                        src={robotImg} 
                        className="img-fluid robot-image" 
                        style={{
                            width: '24vw',   // Use viewport width for scaling
                            maxWidth: '600px',  // Set a maximum width
                            height: 'auto',  // Maintain aspect ratio
                            objectFit: 'contain', }} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CarouselRegister;
