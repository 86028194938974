import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import { useState } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import currencyIcon from '../../assets/integrations_images/currency.svg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { useDispatch, useSelector } from 'react-redux';
import {
  getGoogleAdsCurrencyRequest,
  getMetaAdsCurrencyRequest,
  getStoreCurrencyRequest,
  setGoogleAdsCurrencyRequest,
  setMetaAdsCurrencyRequest,
} from '../../store/currencyState/actions';

// Country flags can either be hosted locally or use a public API
const flagURLs = {
  USD: 'https://flagcdn.com/w320/us.png', // United States flag
  EUR: 'https://flagcdn.com/w320/eu.png', // European Union flag
  LYD: 'https://flagcdn.com/w320/ly.png', // Libya flag
  MAD: 'https://flagcdn.com/w320/ma.png', // Morocco flag
  TND: 'https://flagcdn.com/w320/tn.png', // Tunisia flag
};

function CurrencyChanger() {
  const location = useLocation();
  const disabled = location.pathname === '/overview-dashboard';
  const { googleAdsCurrency, metaAdsCurrency, storeCurrency } = useSelector(
    (state) => state.CurrencyReducer
  );
  const [selectedCurrency, setSelectedCurrency] = useState('');

  useEffect(() => {
    if (location.pathname === '/overview-dashboard') {
      dispatch(getStoreCurrencyRequest());
    }
    if (location.pathname === '/google-dashboard') {
      dispatch(getGoogleAdsCurrencyRequest());
    }
    if (location.pathname === '/ads-dashboard') {
      dispatch(getMetaAdsCurrencyRequest());
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/overview-dashboard') {
      setSelectedCurrency(storeCurrency);
    }
    if (location.pathname === '/google-dashboard') {
      setSelectedCurrency(googleAdsCurrency.revenue);
    }
    if (location.pathname === '/ads-dashboard') {
      setSelectedCurrency(metaAdsCurrency.revenue);
    }
  }, [metaAdsCurrency, googleAdsCurrency, location, storeCurrency]);

  const optionGroup = [
    {
      label: 'USD - United States Dollar',
      value: 'USD',
    },
    {
      label: 'EUR - Euro',
      value: 'EUR',
    },
    {
      label: 'LYD - Libyan Dinar',
      value: 'LYD',
    },
    {
      label: 'MAD - Moroccan Dirham',
      value: 'MAD',
    },
    {
      label: 'TND - Tunisian Dinar',
      value: 'TND',
    },
  ];

  const dispatch = useDispatch();

  const addGoogleAdsCurrency = (selectedOption) => {
    dispatch(setGoogleAdsCurrencyRequest(selectedOption));
  };

  const addMetaAdsCurrency = (selectedOption) => {
    dispatch(setMetaAdsCurrencyRequest(selectedOption));
  };
  const handleSelectGroup = async (selectedOption) => {
    setSelectedCurrency(selectedOption); // Update the selected currency

    if (location.pathname === '/google-dashboard') {
      addGoogleAdsCurrency(selectedOption);
    }
    if (location.pathname === '/ads-dashboard') {
      addMetaAdsCurrency(selectedOption);
    }
  };

  const menu = (
    <Menu
      style={{
        border: '1px solid #C6C2DE',
        borderRadius: '3px',
        padding: '10px',
      }}
    >
      {/* Add the "Select Your Website Currency" text */}
      <div
        style={{
          padding: '5px 20px',
          fontSize: '14px',

          color: '#6E6893',
        }}
      >
        Select Your Website Currency
      </div>

      {optionGroup.map((column) => (
        <Menu.Item
          key={column.value}
          onClick={() => handleSelectGroup(column.value)} // Select currency on click
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            backgroundColor:
              selectedCurrency === column.value ? '#f0f0f0' : 'white', // Highlight selected item
            margin: '5px 0',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          <img
            src={flagURLs[column.value]}
            alt={`${column.value} flag`}
            style={{ width: '20px', marginRight: '10px', borderRadius: '3px' }} // Style the flag image
          />
          {column.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      disabled={disabled}
      arrow={false}
    >
      <Button
        style={{
          backgroundColor: '#04044C',
          margin: '0 15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          minHeight: '40px',
          padding: '0 15px',
          color: 'white',
          borderRadius: '5px',
          fontSize: '14px',
        }}
      >
        {selectedCurrency ? (
          <>
            <img
              src={flagURLs[selectedCurrency]}
              alt={`${selectedCurrency} flag`}
              style={{
                width: '20px',
                marginRight: '10px',
                borderRadius: '3px',
              }} // Display the selected flag in the button
            />
            {optionGroup.find((opt) => opt.value === selectedCurrency).label}
          </>
        ) : (
          <>
            <img
              src={currencyIcon}
              style={{ marginRight: '10px' }}
              alt="currency icon"
            />
            Choose currency
          </>
        )}

        {!disabled && <DownOutlined style={{ marginLeft: '10px' }} />}
      </Button>
    </Dropdown>
  );
}

export default (withTranslation()(CurrencyChanger));
