// import React, { useMemo } from 'react';
// import { Table } from 'antd';
// import PropTypes from 'prop-types';
// import { formatNumberWithSpaces } from '../../GoogleAnalytics/common/HelperFunctions/utils';

// const GrossProfitTable = ({ pnl, t }) => {
//   // Safe number formatting function
//   const getTotalJSX = (value) => {
//     return formatNumberWithSpaces(value);
//   };

//   const monthColumns = [
//     "JAN", "FEB", "MAR", "APR", "MAY", "JUN", 
//     "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
//   ].map((month) => ({
//     title: <span style={{ fontWeight: 'bold' }}>{t(month)}</span>,
//     dataIndex: month.toLowerCase(),
//     key: month.toLowerCase(),
//     width: 120,
//     render: (value) => (
//       <span className="table-cell-text">
//         {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
//       </span>
//     ),
//   }));

//   const fixedColumns = [
//     {
//       title: t("TOTAL"),
//       dataIndex: 'total2',
//       key: 'total2',
//       width: 120,
//       fixed: 'right',
//       render: (value) => (
//         <span className="table-cell-text">
//           {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
//         </span>
//       ),
//     },
//     {
//       title: t("AVERAGE"),
//       dataIndex: 'average2',
//       key: 'average2',
//       width: 120,
//       fixed: 'right',
//       render: (value) => (
//         <span className="table-cell-text">
//           {value === 0 ? '-' : formatNumberWithSpaces(value?.toFixed(2))}
//         </span>
//       ),
//     },
//   ];

//   const columns = [
//     { title: t("GROSS PROFIT"), dataIndex: 'GrossProfit', fixed: 'left', key: 'GrossProfit', width: 220 },
//     ...monthColumns,
//     ...fixedColumns,
//   ];

//   const data = useMemo(() => {
//     const safeNumber = (value) => {
//       const num = Number(value);
//       return isNaN(num) ? 0 : num;
//     };

//     const rows = [
//       {
//         key: '1',
//         GrossProfit: <span className="table-cell-text">Gross Profit</span>,
//         ...monthColumns.reduce((acc, col, monthIndex) => {
//           const monthData = pnl[monthIndex];
//           const value = safeNumber(monthData?.gross);
//           acc[col.dataIndex] = value;
//           return acc;
//         }, {}),
//         total2: pnl.reduce((sum, item) => sum + safeNumber(item?.gross), 0),
//         average2: pnl.reduce((sum, item) => sum + safeNumber(item?.gross), 0) / 12
//       },
//     ];

//     return rows;
//   }, [pnl]);

//   return (
//     <Table
//       columns={columns}
//       dataSource={data}
//       scroll={{ x: 1500 }}
//       pagination={false}
//     />
//   );
// };

// GrossProfitTable.propTypes = {
//   pnl: PropTypes.array.isRequired,
//   t: PropTypes.func.isRequired,
// };

// export default GrossProfitTable;



import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import DeleteableMetricCard from '../../OverviewDashboard/DeleteableMetricCard';
import { formatNumberWithSpaces } from '../../GoogleAnalytics/common/HelperFunctions/utils';

const GrossProfitCards = ({ pnl, t }) => {
  const [selectedGroups, setSelectedGroups] = useState(
    [
      { key: 1, label: t('JAN'), value: formatNumberWithSpaces(pnl[0]?.gross.toFixed(2)) },
      { key: 2, label: t('FEB'), value: formatNumberWithSpaces(pnl[1]?.gross.toFixed(2)) },
      { key: 3, label: t('MAR'), value: formatNumberWithSpaces(pnl[2]?.gross.toFixed(2)) },
      { key: 4, label: t('APR'), value: formatNumberWithSpaces(pnl[3]?.gross.toFixed(2)) },
      { key: 5, label: t('MAY'), value: formatNumberWithSpaces(pnl[4]?.gross.toFixed(2)) },
      { key: 6, label: t('JUN'), value: formatNumberWithSpaces(pnl[5]?.gross.toFixed(2)) },
      { key: 7, label: t('JUL'), value: formatNumberWithSpaces(pnl[6]?.gross.toFixed(2)) },
      { key: 8, label: t('AUG'), value: formatNumberWithSpaces(pnl[7]?.gross.toFixed(2)) },
      { key: 9, label: t('SEP'), value: formatNumberWithSpaces(pnl[8]?.gross.toFixed(2)) },
      { key: 10, label: t('OCT'), value: formatNumberWithSpaces(pnl[9]?.gross.toFixed(2)) },
      { key: 11, label: t('NOV'), value: formatNumberWithSpaces(pnl[10]?.gross.toFixed(2)) },
      { key: 12, label: t('DEC'), value: formatNumberWithSpaces(pnl[11]?.gross.toFixed(2)) },
    ]
  );

  useEffect(() => {
    setSelectedGroups(
      [
      { key: 1, label: t('JAN'), value: formatNumberWithSpaces(pnl[0]?.gross.toFixed(2)) },
      { key: 2, label: t('FEB'), value: formatNumberWithSpaces(pnl[1]?.gross.toFixed(2)) },
      { key: 3, label: t('MAR'), value: formatNumberWithSpaces(pnl[2]?.gross.toFixed(2)) },
      { key: 4, label: t('APR'), value: formatNumberWithSpaces(pnl[3]?.gross.toFixed(2)) },
      { key: 5, label: t('MAY'), value: formatNumberWithSpaces(pnl[4]?.gross.toFixed(2)) },
      { key: 6, label: t('JUN'), value: formatNumberWithSpaces(pnl[5]?.gross.toFixed(2)) },
      { key: 7, label: t('JUL'), value: formatNumberWithSpaces(pnl[6]?.gross.toFixed(2)) },
      { key: 8, label: t('AUG'), value: formatNumberWithSpaces(pnl[7]?.gross.toFixed(2)) },
      { key: 9, label: t('SEP'), value: formatNumberWithSpaces(pnl[8]?.gross.toFixed(2)) },
      { key: 10, label: t('OCT'), value: formatNumberWithSpaces(pnl[9]?.gross.toFixed(2)) },
      { key: 11, label: t('NOV'), value: formatNumberWithSpaces(pnl[10]?.gross.toFixed(2)) },
      { key: 12, label: t('DEC'), value: formatNumberWithSpaces(pnl[11]?.gross.toFixed(2)) },
      ]
    );
  }, [pnl]);


  return (
    <div >
      <Row style={{ marginBottom: '20px' }}>
        {selectedGroups.slice(0, 6).map(item => (
            <DeleteableMetricCard item={item} key={item.key} setSelectedGroups={setSelectedGroups} deletable={false}/>
        ))}
      </Row>
      
      <Row style={{ marginBottom: '20px' }}>
        {selectedGroups.slice(6).map(item => (
            <DeleteableMetricCard item={item} key={item.key} setSelectedGroups={setSelectedGroups} deletable={false}/>
        ))}
      </Row>
    </div>
  );
};

export default GrossProfitCards;
