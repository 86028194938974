import React from 'react';
import { useTranslation } from 'react-i18next'; // Add translation import
import {
  formatNumberWithSpaces,
  getTotal,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsCard from '../GoogleDashboard/Google Ads Overview/GoogleAdsCard/GoogleAdsCard';

const FbSocialMediaCards = ({ secCards, colors }) => {
  const { t } = useTranslation(); // Add translation hook

  const findDataByName = (name, value) => {
    const metric = secCards.find((item) => item.name === name);
    return metric ? metric[value] : [];
  };

  const displayedData = (item, data) => {
    switch (item.name) {
      case t('Followers'):
        return (
          findDataByName(t('Followers'), data)[
            findDataByName(t('Followers'), data).length - 1
          ] || 0
        );
      case t('Likes'):
        return (
          findDataByName(t('Likes'), data)[
            findDataByName(t('Likes'), data).length - 1
          ] || 0
        );
      case t('Monthly Impressions'):
        return getTotal(findDataByName(t('Monthly Impressions'), data));
      case t('Post Engagements'):
        return getTotal(findDataByName(t('Post Engagements'), data));
      case t('Post Impressions'):
        return getTotal(findDataByName(t('Post Impressions'), data));
      case t('Page Impressions Paid'):
        return getTotal(findDataByName(t('Page Impressions Paid'), data));
      case t('Page Posts Impressions Paid'):
        return getTotal(findDataByName(t('Page Posts Impressions Paid'), data));
      default:
        return null;
    }
  };

  const renderContent = (item, data) => {
    return formatNumberWithSpaces(displayedData(item, data));
  };

  const finalData = secCards.map((item) => {
    if (item.compareData && item.compareData.length > 0) {
      return {
        name: t(item.name),
        totalValue: renderContent(item, 'data'),
        percentage: (
          ((displayedData(item, 'data') - displayedData(item, 'compareData')) /
            displayedData(item, 'compareData')) *
          100
        ).toFixed(2),
        series: [
          { name: t(item.name), data: item.data },
          { name: t('compare {{name}}', { name: item.name }), data: item.compareData },
        ],
      };
    } else
      return {
        name: t(item.name),
        totalValue: renderContent(item, 'data'),
        series: [{ name: t(item.name), data: item.data }],
      };
  });

  return (
    <>
      {finalData.map((item, index) => (
        <GoogleAdsCard item={item} key={index} colors={colors} />
      ))}
    </>
  );
};

export default FbSocialMediaCards;