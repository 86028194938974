import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Button } from 'reactstrap';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { addDays, format, startOfDay } from 'date-fns';
import { setDateRange } from '../../store/calenderState/actions'; // Import the action
 import  iconCompare from '../../assets/images/iconCompare.svg'; // Import the compare icon
import  iconCalender from '../../assets/images/iconCalender.svg'; // Import the calendar icon

export default function CalendarComponent() {
  const { t } = useTranslation(); // Get the translation function
  const calendarRef = useRef(null); // Ref for the calendar container
  const dispatch = useDispatch(); // Get the dispatch function
  const dateRange = JSON.parse(window.localStorage.getItem('dateRange'));
  // Set up default selections with valid start and end dates
  const defaultSelection1 = {
    startDate: addDays(new Date(), -3),
    endDate: addDays(new Date(), -1),
    key: 'selection1',
  };

  const defaultSelection2 = {
    startDate: null,
    endDate: null,
    key: 'selection2',
  };

  // Safeguard initialization by checking if dateRange exists and has the expected structure
  const initialSelection1 = dateRange?.selection1?.startDate
    ? {
        startDate: new Date(dateRange.selection1.startDate),
        endDate: new Date(dateRange.selection1.endDate),
        key: 'selection1',
      }
    : defaultSelection1;

  const initialSelection2 = dateRange?.selection2?.startDate
    ? {
        startDate: new Date(dateRange.selection2.startDate),
        endDate: new Date(dateRange.selection2.endDate),
        key: 'selection2',
      }
    : defaultSelection2;

  const [state, setState] = useState({
    selection1: initialSelection1,
    selection2: initialSelection2,
  });

  const [tempState, setTempState] = useState({
    selection1: initialSelection1,
    selection2: initialSelection2,
  });


  const [showCalendar, setShowCalendar] = useState(false);
  const initialCompareEnabled =
    JSON.parse(localStorage.getItem('compareEnabled')) || false;
  const [compareEnabled, setCompareEnabled] = useState(initialCompareEnabled);

  const handleOutsideClick = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const handleSelect = (ranges) => {
    if (compareEnabled) {
      setTempState((prevState) => ({
        selection1: prevState.selection1,
        selection2: ranges.selection2,
      }));
    } else {
      setTempState((prevState) => ({
        selection1: ranges.selection1,
        selection2: defaultSelection2, // Reset selection2 only when compareEnabled is false
      }));
    }
  };

  const formatDateRange = (selection) => {
    const { startDate, endDate } = selection;
    return `${format(startDate, 'MMM dd yyyy')} - ${format(
      endDate,
      'MMM dd yyyy'
    )}`;
  };

  const handleApply = () => {
    window.localStorage.setItem('dateRange', JSON.stringify(tempState));
    window.localStorage.setItem('compareEnabled', compareEnabled);
    setState(tempState);
    dispatch(setDateRange(tempState)); // Dispatch the date range action
    setShowCalendar(false);
  };

  useEffect(() => {
    handleApply();
  }, []);
  const handleCancel = () => {
    setTempState(state); // Revert to previous state
    setShowCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const toggleCompare = () => {
    setCompareEnabled((prev) => {
      const newCompareEnabled = !prev;

      // Reset selection2 to null when compare is turned off
      if (!newCompareEnabled) {
        setTempState((prevState) => ({
          ...prevState,
          selection2: { startDate: null, endDate: null, key: 'selection2' },
        }));
      }

      return newCompareEnabled;
    });

    setShowCalendar(true); // Ensure the calendar opens when toggling "Compare"
  };

  // Define missing style objects here
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#000',
    width: 'fit-content',
    transition: 'background-color 0.3s ease',
  };


  const caretIconStyle = {
    marginLeft: '10px',
    marginRight: '0',
  };

  const compareLabelStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px', // Spacing to the left of the date picker
    fontSize: '14px',
    cursor: 'pointer',
    
  };

  const calendarControlsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderTop: '1px solid #e5e5e5', // Optional: to separate the buttons from the calendar
    backgroundColor: '#f9f9f9', // Optional: to highlight the buttons area
  };

  return (
    <div className='form-group'>
      <div
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <div
          style={buttonStyle}
          onClick={toggleCalendar}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = '#f0f0f0')
          }
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#fff')}
        >
        <img src={iconCalender} alt="iconCalender" style={{width:'20px',height:'20px',marginRight:"8px"}} /> {/* Add the calendar icon */}
          {formatDateRange(state.selection1)}
          {compareEnabled &&
            state.selection2.startDate &&
            ` | ${formatDateRange(state.selection2)}`}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-caret-down-fill"
            viewBox="0 0 16 16"
            style={caretIconStyle}
          >
            <path d="M7.247 11.14 2.451 5.658a.5.5 0 0 1 .416-.791h10.267a.5.5 0 0 1 .416.791l-4.796 5.482a.5.5 0 0 1-.778 0z" />
          </svg>
      
        </div>
        <Button
      onClick={toggleCompare} // Call the toggle function when clicked
      style={{
        ...compareLabelStyle, // Use the existing styles
        backgroundColor:'#3ECF8E', // Change button color based on state
        color: 'white', // Button text color
        border: 'none', // Remove border if desired
        cursor: 'pointer', // Pointer to indicate it's clickable
        padding: '10px 20px', // Add some padding for better UX
        borderRadius: '5px', // Optional: round the corners
      }}
    >
          <img src={iconCompare } alt="iconCompare" style={{width:'20px',height:'20px',marginRight:"8px"}} /> {/* Add the compare icon */}
      {t('Compare')} {/* Dynamic text based on state */}
    </Button>
    
      </div>
      {showCalendar && (
        <div
          ref={calendarRef}
          style={{
            position: 'absolute',
            zIndex: 1000,
            marginTop: '10px',
            left: '40%',
            animation: 'fadeIn 0.3s ease-in-out',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DateRangePicker
              onChange={handleSelect}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={
                compareEnabled
                  ? [
                      { ...state.selection1, disabled: true }, // Disable the blue date range when comparing
                      tempState.selection2, // Only the green range is editable
                    ]
                  : [tempState.selection1] // Only the blue range is editable
              }
              direction="horizontal"
              maxDate={startOfDay(new Date())} // Disable future dates
            />
            <div style={calendarControlsStyle}>
              <Button color="primary" onClick={handleApply}>
                {t('Apply')}
              </Button>
              <Button color="secondary" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
