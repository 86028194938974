import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import whitelogo from '../../assets/images/Live Metrics White Logo.png';
import whiteicon from '../../assets/images/White-logo_LiveMetrics.svg';
import ToggleIconB from '../../assets/images/ToggleIcon_Black.svg';
import ToggleIconW from '../../assets/images/ToggleIcon_White.svg';
//i18n
import { withTranslation } from 'react-i18next';
// Toggle full screen
import CurrencyChanger from './CurrencyChanger';
import CalendarComponent from './CalendarComponent';
import { Offcanvas } from 'react-bootstrap';
import { ConfigProvider, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode,
} from '../../store/actions';

const Header = (props) => {
  const { onChangeLayoutMode } = props;
  const [search, setsearch] = useState(false);
  const [isClick, setClick] = useState(true);
  const [iconColor, setIconColor] = useState('white');
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const hideCalendarComponent = [
    '/integrations',
    '/settings-profile',
    '/billing',
    '/settings-members',
  ];
  const showCalendarComponent = !hideCalendarComponent.includes(
    location.pathname
  );

  const onDrawerClose = () => {
    setOpen(false);
  };

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(''); // initial state is empty
  const [isChecked, setIsChecked] = useState(false);
  const [storechecked, setStorechecked] = useState(false);
  const [fbchecked, setFbchecked] = useState(false);
  const [fbpagechecked, setFbpagechecked] = useState(false);
  const [isGoogleAdsChecked, setIsGoogleAdsChecked] = useState(false);
  const [instagrampagechecked, setInstagrampagechecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handelStoreToggle = () => {
    setStorechecked(!storechecked);
  };

  const handlefbToggle = () => {
    setFbchecked(!fbchecked);
  };

  const handlefbpageToggle = () => {
    setFbpagechecked(!fbpagechecked);
  };

  const handleGoogleAdsToggle = () => {
    setIsGoogleAdsChecked(!isGoogleAdsChecked);
  };

  const handleinstagrampageToggle = () => {
    setInstagrampagechecked(!instagrampagechecked);
  };

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    setIconColor(isClick ? 'gray' : 'white'); // Toggle color
    if (isClick === true) {
      body.classList.add('sidebar-enable');
      document.body.setAttribute('data-sidebar-size', 'sm');
    } else {
      body.classList.remove('sidebar-enable');
      document.body.setAttribute('data-sidebar-size', 'lg');
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/overview-dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={whiteicon} alt="" height="27" />
                </span>
                <span className="logo-lg">
                  <img src={whitelogo} alt="" height="27" />
                </span>
              </Link>

              <Link to="/prestashop-dashboard" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={whiteicon} alt="" height="25" /> */}
                </span>
                <span className="logo-lg">
                  <img src={whitelogo} alt="" height="27" />
                </span>
              </Link>
            </div>

            <button
              onClick={tToggle}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              {isClick ? (
                <img src={ToggleIconW} alt="" height="14" />
              ) : (
                <img src={ToggleIconB} alt="" height="14" />
              )}
            </button>
          </div>

          <div
            className="d-flex align-items-center"
            style={{ marginRight: '23px' }}
          >
            {showCalendarComponent && <CalendarComponent t={props.t} />}

            <CurrencyChanger />

            <div className="d-flex align-items-start">
              <div className="flex-shrink-0">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    margin: '0 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '70px',
                    cursor: 'pointer', // Added to indicate it's clickable
                  }}
                  onClick={toggleOffcanvas} // Add the onClick handler
                >
                  <g clipPath="url(#clip0_73_5307)">
                    <mask
                      id="mask0_73_5307"
                      style={{ maskType: 'luminance' }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="26"
                      height="26"
                    >
                      <path
                        d="M0.666504 0.333233H25.9998V25.6666H0.666504V0.333233Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_73_5307)">
                      <path
                        d="M13.3331 9.10754C11.1835 9.10754 9.44076 10.8502 9.44076 12.9999C9.44076 15.1496 11.1835 16.8922 13.3331 16.8922C15.4828 16.8922 17.2255 15.1496 17.2255 12.9999C17.2255 10.8502 15.4828 9.10754 13.3331 9.10754ZM24.2582 10.2223L23.2044 11.0899C22.0022 12.0795 22.0022 13.9203 23.2044 14.9099L24.2582 15.7775C24.6317 16.0849 24.7281 16.6173 24.4863 17.0363L22.4053 20.6406C22.1634 21.0595 21.6541 21.2422 21.2012 21.0725L19.9229 20.5936C18.4648 20.0474 16.8706 20.9677 16.6146 22.5036L16.3902 23.8501C16.3107 24.3272 15.8978 24.677 15.4141 24.677H11.2521C10.7684 24.677 10.3556 24.3272 10.2761 23.8501L10.0516 22.5036C9.79567 20.9677 8.2015 20.0474 6.7434 20.5936L5.46516 21.0725C5.01213 21.2422 4.50284 21.0595 4.26093 20.6406L2.17999 17.0363C1.93813 16.6173 2.03457 16.0849 2.40804 15.7775L3.46194 14.9099C4.66404 13.9203 4.66404 12.0795 3.46194 11.0899L2.40804 10.2223C2.03457 9.91485 1.93813 9.38245 2.17999 8.96351L4.26093 5.3592C4.50284 4.94026 5.01213 4.75758 5.46516 4.9273L6.7434 5.40616C8.2015 5.95241 9.79567 5.03204 10.0516 3.49616L10.2761 2.14968C10.3556 1.67256 10.7684 1.32284 11.2521 1.32284H15.4141C15.8978 1.32284 16.3107 1.67256 16.3902 2.14968L16.6146 3.49616C16.8706 5.03204 18.4648 5.95241 19.9229 5.40616L21.2012 4.9273C21.6541 4.75758 22.1634 4.94026 22.4053 5.3592L24.4863 8.96351C24.7281 9.38245 24.6317 9.91485 24.2582 10.2223Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_73_5307">
                      <rect
                        width="25.3333"
                        height="25.3333"
                        fill="white"
                        transform="translate(0.666504 0.333252)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <Offcanvas
                  show={isOffcanvasOpen}
                  onHide={toggleOffcanvas}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      {props.t('Custom Settings')}
                    </span>
                  </Offcanvas.Header>

                  <hr />
                  <Offcanvas.Body>
                    <div className="modal-header"></div>
                    <div
                      className="form-check  mb-3 d-flex justify-content-between align-items-center"
                      dir="ltr"
                    >
                      <label
                        className="form-check-label me-3"
                        htmlFor="customSwitch1"
                      >
                        Show General Overview
                      </label>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                          },
                        }}
                      >
                        <Switch
                          checked={isChecked}
                          onChange={handleToggle}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </ConfigProvider>
                    </div>

                    {/* Show Store Overview */}
                    <div
                      className="form-check  mb-3 d-flex justify-content-between align-items-center"
                      dir="ltr"
                    >
                      <label
                        className="form-check-label me-3"
                        htmlFor="customSwitch2"
                      >
                        Show Store Overview
                      </label>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                          },
                        }}
                      >
                        <Switch
                          checked={storechecked}
                          onChange={handelStoreToggle}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </ConfigProvider>
                    </div>

                    {/* Show Facebook Overview */}
                    <div
                      className="form-check  mb-3 d-flex justify-content-between align-items-center"
                      dir="ltr"
                    >
                      <label
                        className="form-check-label me-3"
                        htmlFor="customSwitch3"
                      >
                        Show Facebook Overview
                      </label>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                          },
                        }}
                      >
                        <Switch
                          checked={fbchecked}
                          onChange={handlefbToggle}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </ConfigProvider>
                    </div>

                    {/* Show Facebook Page Overview */}
                    <div
                      className="form-check  mb-3 d-flex justify-content-between align-items-center"
                      dir="ltr"
                    >
                      <label
                        className="form-check-label me-3"
                        htmlFor="customSwitch4"
                      >
                        Show Facebook Page Overview
                      </label>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                          },
                        }}
                      >
                        <Switch
                          checked={fbpagechecked}
                          onChange={handlefbpageToggle}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </ConfigProvider>
                    </div>

                    {/* Show Google Ads Overview */}
                    <div
                      className="form-check mb-3 d-flex justify-content-between align-items-center"
                      dir="ltr"
                    >
                      <label
                        className="form-check-label me-3"
                        htmlFor="customSwitch5"
                      >
                        Show Google Ads Overview
                      </label>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                          },
                        }}
                      >
                        <Switch
                          checked={isGoogleAdsChecked}
                          onChange={handleGoogleAdsToggle}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </ConfigProvider>
                    </div>

                    {/* Show Instagram Overview */}
                    <div
                      className="form-check mb-3 d-flex justify-content-between align-items-center"
                      dir="ltr"
                    >
                      <label
                        className="form-check-label me-3"
                        htmlFor="customSwitch6"
                      >
                        Show Instagram Overview
                      </label>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                          },
                        }}
                      >
                        <Switch
                          checked={instagrampagechecked}
                          onChange={handleinstagrampageToggle}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </ConfigProvider>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
            <ProfileMenu />
            <LanguageDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, layoutMode } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, layoutMode };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changelayoutMode,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
