import React, { useState, useEffect } from 'react';
import { checkSales } from '../../../queries/Aramex/Queries';
import { deleteIntegrationAramexRequest, integrationAramexRequest } from '../../../store/aramexState/action'
import { ApolloClient, InMemoryCache, HttpLink  } from '@apollo/client';

import urls from "../../../routes/apiUrls";
import { Card, CardBody, Col, Container, Row, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import amrex from '../Aramex/aramex.png';
import { deleteAlert } from '../../Alerts/NotLinkedAccountAlert';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from 'react-select'






const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.Aramex,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});



const AramexCard = (props) => {
 
  const dispatch = useDispatch();

  const { data, checkIntegrationdata } = useSelector((state) => state.aramex);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [AccountPin, setAccountPin] = useState("");
  const [AccountEntity, setAccountEntity] = useState("");
  const [AccountCountryCode, setAccountCountryCode] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [deleted, setDeleted] = useState(false);

  const accountCountryCodeOptions = [
    { value: 'TN', label: 'TN' },
    { value: 'MAR', label: 'MAR' },
    { value: 'DZ', label: 'DZ' },
    { value: 'EG', label: 'EG' },
    { value: 'SA', label: 'SA' },
    { value: 'KW', label: 'KW' },
    { value: 'BH', label: 'BH' },
    { value: 'QA', label: 'QA' },
    { value: 'OM', label: 'OM' },
    { value: 'AE', label: 'AE' },
    { value: 'YE', label: 'YE' },
    { value: 'JO', label: 'JO' },
    { value: 'LB', label: 'LB' },
    { value: 'SY', label: 'SY' },
    { value: 'IQ', label: 'IQ' },
    { value: 'PS', label: 'PS' },
    { value: 'IL', label: 'IL' },
    { value: 'IR', label: 'IR' },
    { value: 'TR', label: 'TR' },
    { value: 'CY', label: 'CY' },
    { value: 'GR', label: 'GR' },
    { value: 'IT', label: 'IT' },
    { value: 'FR', label: 'FR' },
    { value: 'ES', label: 'ES' },
    { value: 'PT', label: 'PT' },
    { value: 'DE', label: 'DE' },
    { value: 'NL', label: 'NL' },
    { value: 'BE', label: 'BE' },
    { value: 'LU', label: 'LU' },
    { value: 'CH', label: 'CH' },
    { value: 'AT', label: 'AT' },
    { value: 'GB', label: 'GB' },
    { value: 'IE', label: 'IE' },
    { value: 'IS', label: 'IS' },
    { value: 'SE', label: 'SE' },
    { value: 'NO', label: 'NO' },
    { value: 'FI', label: 'FI' },
    { value: 'EE', label: 'EE' },
    { value: 'LV', label: 'LV' },
    { value: 'LT', label: 'LT' },
    { value: 'PL', label: 'PL' },
    { value: 'CZ', label: 'CZ' },
  ]

  //not chaning this  useffect to redux cause i already have checksales  from page  dashboard
  useEffect(() => {
    let isSubscribed = true
    ApploCLI.query({
      query: checkSales,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: "all",
      fetchPolicy: "network-only"
    }).then((data) => {
      if (isSubscribed && data.data.get != null) {
        if (!data.data.get.isDeleted && data.data.get.active) {
          setInfo(data.data.get.active);
          console.log("data.data.get", data.data.get)
        } else {
          setInfo(data.data.get.isDeleted);
        }
        setDeleted(true);
        setUserName(data.data.get.UserName)
        setAccountCountryCode(data.data.get.AccountCountryCode)
        setAccountEntity(data.data.get.AccountEntity)
        setAccountNumber(data.data.get.AccountNumber)
        setAccountPin(data.data.get.AccountPin)
        setPassword(data.data.get.Password)

      }
    }).catch(err => console.log("Aramex  checkSales", err));
    return () => (isSubscribed = false)
  }, [])
  function handleSelectGroup(accountCountryCodeOptions) {
    setAccountCountryCode(accountCountryCodeOptions.value)
  }
  const onSubmitForm = async (e) => {
  }
  let integration = () => {
    if (UserName === "") {
      setError("UserName  is required!")
      return;
    }
    setError("");
    console.log("Consumer Key is", UserName)
    dispatch(integrationAramexRequest(UserName, Password, AccountNumber, AccountPin, AccountEntity, AccountCountryCode))
  }

  const deleteConnection = () => {
    deleteAlert('Aramex').then(function (e) {
      if (e.isConfirmed) {
        dispatch(deleteIntegrationAramexRequest())
      }
    })
  }

  return (
    <div >
                <div style={{marginTop:'15px'}}>
                <Container fluid>
          <Row className='gy-1 pt-75'>
            <Col>
              <Card className="border text-secondary">
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <div className='text-left d-flex align-items-center'>
                      <img className="" src={amrex} style={{height:'26px',width:'159px'}}/>
                      
                    </div>
                  </div>
                  <div className="modal-header">
                            <h5 className="" id="myModalLabel" style={{fontSize:'17px', marginTop:'20px', fontWeight:'400'}}>
                              {props.t("Aramex Credentials")}
                            </h5>
                          </div>
                          <div >
  

  <Label htmlFor="validationTooltip01" style={{ fontSize: '14px', fontWeight: '700', marginTop: '20px' }}>{props.t("Username")}</Label>
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <Col xs={12}>
      <Input
        type="text"
        className="form-control-username-tour"
        id="validationTooltip01"
        placeholder={props.t("Username")}
        onChange={e => setUserName(e.target.value)}
        value={UserName}
        style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '400' }}
      />
    </Col>
   
  </div>

  <Col xs={12} className="pwd-tour" style={{ marginBottom: '10px' }}>
    <Label htmlFor="validationTooltip01" style={{ fontSize: '14px', fontWeight: '700' }}>{props.t("Password")}</Label>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Input
        type="password"
        className="form-control-pwd"
        id="validationTooltip01"
        placeholder={props.t("Password")}
        onChange={e => setPassword(e.target.value)}
        value={Password}
        style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '400' }}
      />
   
    </div>
  </Col>

  <Row>
    <Col md={6} xs={12} className="Anumber-tour" style={{ marginBottom: '10px' }}>
      <Label htmlFor="validationTooltip01" style={{ fontSize: '14px', fontWeight: '700' }}>{props.t("Account Numbers")}</Label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          className="form-control-Anumber"
          id="validationTooltip01"
          placeholder={props.t("Account Number")}
          onChange={e => setAccountNumber(e.target.value)}
          value={AccountNumber}
          style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '400' }}
        />
      
      </div>
    </Col>

    <Col md={6} xs={12} className="acpin-tour" style={{ marginBottom: '10px' }}>
      <Label htmlFor="validationTooltip01" style={{ fontSize: '14px', fontWeight: '700' }}>{props.t("Account Pin")}</Label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          className="form-control-acpin"
          id="validationTooltip01"
          placeholder={props.t("Account Pin")}
          onChange={e => setAccountPin(e.target.value)}
          value={AccountPin}
          style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '400' }}
        />
       
      </div>
    </Col>

    <Col md={6} xs={12} className="AEnti-tour" style={{ marginBottom: '10px' }}>
      <Label htmlFor="validationTooltip01" style={{ fontSize: '14px', fontWeight: '700' }}>{props.t("Account Entity")}</Label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          className="form-control-AEnti"
          id="validationTooltip01"
          placeholder={props.t("Account Entity")}
          onChange={e => setAccountEntity(e.target.value)}
          value={AccountEntity}
          style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '400' }}
        />
      </div>
    </Col>

    <Col md={6} xs={12} className="countryC-tour" style={{ marginBottom: '10px' }}>
      <Label htmlFor="validationTooltip01" style={{ fontSize: '14px', fontWeight: '700' }}>{props.t("Account Country Code")}</Label>
      <Select
        id='account-country-code'
        isClearable={false}
        className='form-control-countryC'
        classNamePrefix='select'
        options={accountCountryCodeOptions}
        onChange={handleSelectGroup}
        defaultValue={props.t("Account Country Code")}
        style={{ backgroundColor: '#F4F2FF', fontSize: '16px', fontWeight: '400' }}
      />
    </Col>
  </Row>

 
</div>

                        <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => { integration() }}
                              style={{
                                width: '100%',
                                backgroundColor: '#0080F9',
                                borderRadius: '6px',
                                fontSize: '17px',
                                fontWeight: '500',
                                border: 'none',
                                marginTop: '20px',
                                color: '#FFF'
                              }}

                            >
                              {props.t("Connect")}
                            </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  )
}
AramexCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(AramexCard));
//export default AramexCard;
