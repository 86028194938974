import React, { useEffect, useState } from 'react';
import { getGoogleAnalyticsAccountsRequest } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import ActiveAccountBar from './ActiveAccountBar';

const GAActiveAccountBar = () => {
  const { accounts } = useSelector((state) => state.googleAnalytics);
  const dispatch = useDispatch();
  const [accountName, setAccountName] = useState(null);

  useEffect(() => {
    dispatch(getGoogleAnalyticsAccountsRequest());
  }, []);

  useEffect(() => {
    if (accounts) {
      const activeAccount = accounts.find((account) => account.active);
      if (activeAccount) {
        setAccountName(activeAccount.name || null);
      }
    }
  }, [accounts]);
  return (
    accountName && (
      <ActiveAccountBar accountName={accountName} switchTabNumber={4} />
    )
  );
};

export default GAActiveAccountBar;
