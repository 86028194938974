import overviewDashboard from './Overview Dashboard.svg';
import reports from './Reports.svg';
import prestaDashboard from './presta.svg';
import shopify from './Shopify Dashboard.svg';
import woo from './Woo Commerce Dashboard.svg';
import googleads from './Google Ads Dashboard.svg';
import googleanal from './Google Analytics Dashboard.svg';
import metaAnalytics from './Meta Analytics.svg';
import socialMedia from './Social Media Analytics.svg';
import klaviyo from './Klaviyo dashboard.svg';
import aramex from './Aramex Dashboard.svg';
import emailBuilder from './Email Builder.svg';
import integration from './Integrations.svg';
import advisor from './Advisor.svg';
import settings from './Settings.svg';
export const SideBarIconsMap = {
  'Overview': overviewDashboard,
  'Reports': reports,
  'PrestaShop': prestaDashboard,
  'Shopify Dashboard': shopify,
  'WooCommerce': woo,
  'Google Ads': googleads,
  'Google Analytics': googleanal,
  'Meta Ads': metaAnalytics,
  'Social Media': socialMedia,
  'Klaviyo': klaviyo,
  'Aramex': aramex,
  'Email Builder': emailBuilder,
  'Integrations': integration,
  'Advisor': advisor,
  'Settings': settings,
};
