import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

const InstagramTable = ({
  columns,
  data,
  setSortedInfo,
  sortedInfo,
  overview = true,
  pageSize = 5,
  filter = true,
  loading,
}) => {
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const [statusFilter, setStatusFilter] = useState({
    IMAGE: false,
    VIDEO: false,
    CAROUSEL_ALBUM: false,
  });

  const fixedColumns = columns.filter((column) => column.fixed);
  const nonFixedColumns = columns.filter((column) => !column.fixed);

  // Apply filters to non-fixed columns
  let filteredNonFixedColumns = nonFixedColumns.filter((column) =>
    selectedColumns.includes(column.key)
  );
  if (statusFilter.CAROUSEL_ALBUM || statusFilter.IMAGE || statusFilter.VIDEO) {
    filteredNonFixedColumns = filteredNonFixedColumns.filter((column) =>
      Object.keys(statusFilter).some(
        (status) => statusFilter[status] && column.category.includes(status)
      )
    );
  }
  console.log(columns[columns.length - 1]);
  // Combine filtered non-fixed columns with fixed columns
  const filteredColumns = [...fixedColumns, ...filteredNonFixedColumns, columns[columns.length - 1]];
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearAll = () => {
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
    clearFilters();
  };

  const setimpressionsSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };
  const handleSelectAll = () => {
    if (selectedColumns.length === columns.length) {
      // Deselect all
      setSelectedColumns([]);
    } else {
      // Select all
      setSelectedColumns(columns.map((column) => column.key));
    }
  };
  const isAllSelected = filteredColumns.length === columns.length;
  const menu = (
    <Menu>
      <Menu.Item key="select-all" onClick={handleSelectAll}>
        <Checkbox checked={isAllSelected} style={{ marginRight: '8px' }} />
        <span style={{ color: 'black' }}>All</span>
      </Menu.Item>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  // new filter

  const clearFilters = () => {
    setFilteredData(data);
    setStatusFilter({ IMAGE: false, VIDEO: false, CAROUSEL_ALBUM: false });
  };
  // new filter
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // Update the status filter
    const updatedFilter = {
      ...statusFilter,
      [name]: checked,
    };
    setStatusFilter(updatedFilter);

    // Extract selected statuses
    const selectedStatuses = Object.keys(updatedFilter).filter(
      (key) => updatedFilter[key]
    );

    // Filter the data based on selected statuses
    const filteredData = data.filter((item) =>
      selectedStatuses.length > 0
        ? selectedStatuses.includes(item.media_type)
        : true
    );
    setFilteredData(filteredData);
  };

  const Toolbar = ({ marginBottom = '16px' }) => (
    <Space
      style={{
        marginBottom,
      }}
    >
      <Dropdown overlay={menu} trigger={['click']}>
        <Button className="custom-button filter-button">
          {i18next.t('Select Metrics')} <DownOutlined />
        </Button>
      </Dropdown>
      {/* Add any additional buttons or controls specific to Instagram here */}
      <Button
        className="custom-button filter-button"
        onClick={setimpressionsSort}
      >
        {i18next.t('Sort Impressions')}
      </Button>
      {/* <Button onClick={clearFilters}>Clear filters</Button> */}
      <Button className="custom-button filter-button" onClick={clearAll}>
        {i18next.t('Clear filters and sorters')}
      </Button>
    </Space>
  );

  const menufilter = (
    <Menu>
      <Menu.Item key="IMAGE">
        <Checkbox
          name="IMAGE"
          checked={statusFilter.IMAGE}
          onChange={handleCheckboxChange}
        >
          IMAGE
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="VIDEO">
        <Checkbox
          name="VIDEO"
          checked={statusFilter.VIDEO}
          onChange={handleCheckboxChange}
        >
          VIDEO
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="CAROUSEL_ALBUM">
        <Checkbox
          name="CAROUSEL_ALBUM"
          checked={statusFilter.CAROUSEL_ALBUM}
          onChange={handleCheckboxChange}
        >
          CAROUSEL ALBUM
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {filter && <Toolbar />}
      <div className="table-border">
        <div className="d-flex align-items-center" style={{ padding: '20px ' }}>
          <Dropdown overlay={menufilter} trigger={['click']}>
            {filter ? (
              <Button
                className="custom-button"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '22px',
                  backgroundColor: 'inherit',
                  color: '#25213B',
                  fontFamily: 'DM Sans,sans-serif',
                  fontweight: 'bold',
                  borderColor: '#D9D5EC',
                }}
              >
                <FeatherIcon
                  icon="filter"
                  size="26"
                  style={{ marginRight: '8px' }}
                  fill="#8B83BA"
                  color="#8B83BA"
                />
                {i18next.t('Filter')}
              </Button>
            ) : (
              <Toolbar marginBottom="0px" />
            )}
          </Dropdown>

          {overview && (
            <Link
              to="/InstagramDashboard"
              className="btn btn-primary btn-sm viewMetrics-button"
            >
              {i18next.t('View full metrics')}
            </Link>
          )}
        </div>
        <Table
          // style={{ border: "1px solid #e8e8e8" }}
          loading={loading}
          columns={filteredColumns}
          dataSource={filteredData}
          onChange={handleChange}
          pagination={{
            ...paginationOptions,
            onChange: handleChange,
            showSizeChanger: true, // Enable the page size selector
            style: {
              backgroundColor: '#F4F2FF',
              padding: '10px 15px',
              marginTop: '2px',
              borderRadius: '6px',
              fontsize: '14px',
              fontweight: '700',
              color: '#6E6893',
            },
          }}
        />
      </div>
    </div>
  );
};

export default InstagramTable;
