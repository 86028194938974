export const GET_FACEBOOKPAGE_DATA_PER_DATE = 'GET_FACEBOOKPAGE_DATA_PER_DATE';
export const GET_FACEBOOKPAGE_DATA_PER_DATE_SUCCESS =
  'GET_FACEBOOKPAGE_DATA_PER_DATE_SUCCESS';
export const GET_FACEBOOKPAGE_DATA_PER_DATE_FAILURE =
  'GET_FACEBOOKPAGE_DATA_PER_DATE_FAILURE';

export const GET_FACEBOOKPAGE_POST_DATA = 'GET_FACEBOOKPAGE_POST_DATA';
export const GET_FACEBOOKPAGE_POST_DATA_SUCCESS =
  'GET_FACEBOOKPAGE_POST_DATA_SUCCESS';
export const GET_FACEBOOKPAGE_POST_DATA_FAILURE =
  'GET_FACEBOOKPAGE_POST_DATA_FAILURE';

export const getFacebookPageDataPerDate = (startDate, endDate) => ({
  type: GET_FACEBOOKPAGE_DATA_PER_DATE,
  payload: { startDate, endDate },
});

export const getCompareFacebookPageDataPerDate = (
  startDate,
  endDate,
  compareStartDate,
  compareEndDate
) => ({
  type: GET_FACEBOOKPAGE_DATA_PER_DATE,
  payload: { startDate, endDate, compareStartDate, compareEndDate },
});

export const getFacebookPageDataPerDateSuccess = (facebookPageData) => ({
  type: GET_FACEBOOKPAGE_DATA_PER_DATE_SUCCESS,
  payload: { facebookPageData },
});
export const getFacebookPageDataPerDateFailure = (error) => ({
  type: GET_FACEBOOKPAGE_DATA_PER_DATE_FAILURE,
  payload: { error },
});

export const getFacebookPagePostData = () => ({
  type: GET_FACEBOOKPAGE_POST_DATA,
  payload: {},
});
export const getFacebookPagePostDataSuccess = (facebookPagePostData) => ({
  type: GET_FACEBOOKPAGE_POST_DATA_SUCCESS,
  payload: { facebookPagePostData },
});
export const getFacebookPagePostDataFailure = (error) => ({
  type: GET_FACEBOOKPAGE_POST_DATA_FAILURE,
  payload: { error },
});
