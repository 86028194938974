import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Add translation import
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
import GoogleAdsTable from '../GoogleDashboard/GoogleAdsTable';

const FacebookSocialMediaIcon = `${process.env.PUBLIC_URL}/images/integrations_images/fb1.svg`;

const FbSocialMediaPostsTable = ({ dataSource }) => {
  const { t } = useTranslation(); // Add translation hook
  const [filteredData, setFilteredData] = useState(dataSource);
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: t('Full Picture'),
      dataIndex: 'full_picture',
      key: 'full_picture',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a
              href={record.permalink_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('There is No Image')}</span>
            </a>
          );
        } else {
          return (
            <a
              href={record.permalink_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={text}
                alt={record.full_picture}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      },
    },
    {
      title: t('Post Clicks'),
      dataIndex: 'post_clicks',
      key: 'post_clicks',
      align: 'center',
      sorter: (a, b) => a.post_clicks - b.post_clicks,
      sortOrder: sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: t('Post Likes'),
      dataIndex: 'post_likes',
      key: 'post_likes',
      align: 'center',
      sorter: (a, b) => a.post_likes - b.post_likes,
      sortOrder: sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: t('Post Total Reactions'),
      dataIndex: 'postReactionsTotal',
      key: 'postReactionsTotal',
      align: 'center',
      sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
      sortOrder: sortedInfo.columnKey === 'postReactionsTotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: t('Post Impressions'),
      dataIndex: 'post_impressions',
      key: 'post_impressions',
      align: 'center',
      sorter: (a, b) => a.post_impressions - b.post_impressions,
      sortOrder: sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
   
    {
      title: t('Impressions Paid'),
      dataIndex: 'post_impressions_paid',
      key: 'post_impressions_paid',
      align: 'center',
      sorter: (a, b) => a.post_impressions_paid - b.post_impressions_paid,
      sortOrder: sortedInfo.columnKey === 'post_impressions_paid' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
   
    {
      title: t('Shares'),
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: t('Created At'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => {
        const formattedDate = text ? new Date(text).toLocaleDateString() : '';
        return <strong>{formattedDate}</strong>;
      },
    },
  ];

  return (
    <GoogleAdsTable
      columns={columns}
      setFilteredData={setFilteredData}
      filteredData={filteredData}
      data={dataSource}
      sortedInfo={sortedInfo}
      setSortedInfo={setSortedInfo}
      mainSorterKey='post_likes'
    />
  );
};

export default FbSocialMediaPostsTable;