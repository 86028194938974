import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'; // Add this import
import { getAdsRequest } from '../../store/googleAds/actions';
import GoogleAdsTable from './GoogleAdsTable';
import { logoMap } from '../../assets/integrations_images/logoMap';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { formatDate2 } from '../GoogleAnalytics/common/HelperFunctions/utils';

const AdsTables = () => {
  const { t } = useTranslation(); // Add translation hook
  const dispatch = useDispatch();

  const { ads, isLoading } = useSelector((state) => state.GoogleAds);

  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    dispatch(getAdsRequest());
  }, []);

  const columns = [
    {
      title: t('Ad ID'),
      dataIndex: 'ad_id',
      key: 'ad_id',
      sorter: (a, b) => a.ad_id - b.ad_id,
      sortOrder: sortedInfo.columnKey === 'ad_id' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Ad Group Name'),
      dataIndex: 'ad_group_name',
      align: 'center',
      key: 'ad_group_name',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Campaign Name'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: t('Clicks'),
      dataIndex: 'clicks',
      align: 'center',
      key: 'clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.clicks}</strong>
      ),
    },
    {
      title: t('Conversions'),
      dataIndex: 'conversions',
      key: 'conversions',
      align: 'center',
      sorter: (a, b) => a.conversions - b.conversions,
      sortOrder:
        sortedInfo.columnKey === 'conversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.conversions.toFixed(2)}
        </strong>
      ),
    },
    {
      title: t('Cost'),
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      sorter: (a, b) => a.cost - b.cost,
      sortOrder: sortedInfo.columnKey === 'cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.cost.toFixed(2)}</strong>
      ),
    },
    {
      title: t('Interactions'),
      dataIndex: 'interactions',
      key: 'interactions',
      align: 'center',
      sorter: (a, b) => a.interactions - b.interactions,
      sortOrder:
        sortedInfo.columnKey === 'interactions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.interactions}</strong>
      ),
    },
    {
      title: t('CONVERSIONS VALUE'),
      dataIndex: 'conversions_value',
      key: 'conversions_value',
      align: 'center',
      sorter: (a, b) => a['conversions_value'] - b['conversions_value'],
      sortOrder:
        sortedInfo.columnKey === 'conversions_value' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record['conversions_value'].toFixed(2)}
        </strong>
      ),
    },
    {
      title: t('CTR'),
      dataIndex: 'ctr',
      key: 'ctr',
      align: 'center',
      sorter: (a, b) => a['ctr'] - b['ctr'],
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{record.ctr.toFixed(2)}</strong>
      ),
    },
    {
      title: t('Created At'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sorter: (a, b) => a['date'] - b['date'],
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">{formatDate2(+record.date)}</strong>
      ),
    },
  ];

  const filters = [
    {
      type: 'adGroup',
      value: 'ad_group_name',
      placeholder: t('Ad Group Name'),
    },
    {
      type: 'campaign',
      value: 'campaign_name',
      placeholder: t('Campaign Name'),
    },
  ];

  return (
    <div className="page-content">
      <Breadcrumb image={logoMap['Google Ads']} title={t('Ads Table')} />
      <GoogleAdsTable
        columns={columns}
        data={ads}
        sortedInfo={sortedInfo}
        setSortedInfo={setSortedInfo}
        isLoading={isLoading}
        filters={filters}
        mainSorterKey="conversions"
      />
    </div>
  );
};

export default AdsTables;
