import React, { useState } from 'react';
import { Table, Switch, ConfigProvider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const SettingsTable = () => {
    const [isGeneralActive, setIsGeneralActive] = useState(true);
    const [isStoreActive, setIsStoreActive] = useState(false);
    const [isFacebookActive, setIsFacebookActive] = useState(false);
    const [isFacebookPageActive, setIsFacebookPageActive] = useState(false);
    const [isGoogleAdsActive, setIsGoogleAdsActive] = useState(false);
    const [isInstagramActive, setIsInstagramActive] = useState(false);

    const data = [
        {
            key: '1',
            setting: 'Show General Overview',
            toggle: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                        },
                    }}
                >
                    <Switch
                        checked={isGeneralActive}
                        onChange={() => setIsGeneralActive(!isGeneralActive)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </ConfigProvider>
            ),
        },
        {
            key: '2',
            setting: 'Show Store Overview',
            toggle: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                        },
                    }}
                >
                    <Switch
                        checked={isStoreActive}
                        onChange={() => setIsStoreActive(!isStoreActive)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </ConfigProvider>
            ),
        },
        {
            key: '3',
            setting: 'Show Facebook Overview',
            toggle: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                        },
                    }}
                >
                    <Switch
                        checked={isFacebookActive}
                        onChange={() => setIsFacebookActive(!isFacebookActive)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </ConfigProvider>
            ),
        },
        {
            key: '4',
            setting: 'Show Facebook Page Overview',
            toggle: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                        },
                    }}
                >
                    <Switch
                        checked={isFacebookPageActive}
                        onChange={() => setIsFacebookPageActive(!isFacebookPageActive)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </ConfigProvider>
            ),
        },
        {
            key: '5',
            setting: 'Show Google Ads Overview',
            toggle: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                        },
                    }}
                >
                    <Switch
                        checked={isGoogleAdsActive}
                        onChange={() => setIsGoogleAdsActive(!isGoogleAdsActive)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </ConfigProvider>
            ),
        },
        {
            key: '6',
            setting: 'Show Instagram Overview',
            toggle: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#5CCF5C',
                            colorTextQuaternary: '#6E6893',
                            opacityLoading: 1,
                        },
                    }}
                >
                    <Switch
                        checked={isInstagramActive}
                        onChange={() => setIsInstagramActive(!isInstagramActive)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </ConfigProvider>
            ),
        },
    ];

    const columns = [
        {
            title: '',
            dataIndex: 'setting',
            key: 'setting',
            render: (text) => <span style={{ fontSize: '16px', fontWeight: '500' }}>{text}</span>, // Add font size and weight here
        },
        {
            title: '',
            dataIndex: 'toggle',
            key: 'toggle',
            align: 'center',
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={data}
            rowKey="key"
            pagination={false}
            showHeader={false} // This hides the column headers
            onRow={(record, index) => {
                return {
                    style: {
                        backgroundColor: index % 2 === 0 ? 'white' : '#F4F2FF',
                    },
                };
            }}
            style={{
                width: '425px',
                borderRadius: '3px', // Adding border-radius here
                overflow: 'hidden', // Ensures the border-radius is applied to the entire table
                border: '1px solid #d9d9d9', // Adds a border to maintain a clean look
            }}
        />
    );
};

export default SettingsTable;
