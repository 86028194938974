

import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { getStrategicCDataRequest } from '../../../store/reports/strategic/action';
import { formatDate } from '../../GoogleAnalytics/common/HelperFunctions/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Space,
} from "antd";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import OperationsChart from '../ReportsChart'; 
import NumberFormat from 'react-number-format';
import secureSection from "../../../MangeSecurity/MemberSecurity";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CostsIcon from "../../../assets/images/CostsIcon.svg";
import ExportIcon from "../../../assets/images/ExportIcon.svg";
import PropTypes from 'prop-types';
import StrategicTable from '../ReportsTable'; 

function Strategic(props) {
  const dispatch = useDispatch();
  const { strat, avg } = useSelector((state) => state.strategic);

  secureSection(); 

  const [loadingState, setLoadingState] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const currency = window.localStorage.getItem("currency");

  const headers = [
    { label: "Date", key: "created" },
    { label: "Sales", key: "sales" },
    { label: "Net Profit", key: "netProfit" },
    { label: "AOV", key: "aov" },
    { label: "CPS", key: "cps" },
    { label: "ROA", key: "roa" },
    { label: "Magic Metrics", key: "magicMetrics" },
    { label: "Profit Margin", key: "profitMargin" },
    { label: "Break-even ROAS", key: "bRoas" },
  ];

  const onExport = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; 
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("strategic_report.pdf");
    });
  };

  const [channel, setChannel] = useState("all");
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const newStartDate = formatDate(dateRange.selection1.startDate);
  const newEndDate = formatDate(dateRange.selection1.endDate);

  const getData = () => {
    setLoadingState(true);
    dispatch(getStrategicCDataRequest(newStartDate, newEndDate, channel));
  };

  useEffect(() => {
    getData(channel);
  }, [dateRange, channel]);

  // **1. Chart Metric**: Using AOV (Average Order Value)
  const metricData = {
    name: "AOV",
    data: strat ? strat.map(item => item.aov) : [],
  };

  const colors = {
    lineColor: "#556ee6",
    fillColor: [
      { offset: 0, color: 'rgba(85, 110, 230, 0.2)' },
      { offset: 1, color: 'rgba(85, 110, 230, 0)' }
    ],
  };

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'created',
      key: 'created',
      render: (text) => {
        const date = new Date(text);
        const options = { month: 'short', day: '2-digit' };
        return (
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            {date.toLocaleDateString('en-US', options).replace(',', '')}
          </span>
        );
      },
    },
    {
      title: props.t('TOTAL SALES'),
      dataIndex: 'sales',
      key: 'sales',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('GROSS PROFIT'),
      dataIndex: 'netProfit',
      key: 'netProfit',
      render: (text) => (
        console.log(text),
        <span style={{
          display: 'inline-block',
          height: '25px',
          width: '80%',
          fontSize: '16px',
          fontWeight: '500',
          color: text < 0 ? '#D30000' : '#007F00',
          backgroundColor: text < 0 ? '#FFE0E0' : '#CDFFCD',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
          <NumberFormat 
            value={text} 
            displayType={'text'} 
            thousandSeparator={true} 
            prefix={"TND"} 
            decimalScale={2} 
          />
        </span>
      ),
    },
    {
      title: props.t('AD COST'),
      key: 'adCost',  
      render: (record) => { 
        const adCost = parseFloat(record.facebook) + parseFloat(record.google);
        return (
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            <NumberFormat 
              value={adCost} 
              displayType={'text'} 
              thousandSeparator={true} 
              prefix={"TND"} 
              decimalScale={2} 
            />
          </span>
        );
      },
    },    
    {
      title: 'AOV',
      dataIndex: 'aov',
      key: 'aov',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('BLENDED CPP'),
      dataIndex: 'cps',
      key: 'cps',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: 'ROAs',
      dataIndex: 'roa',
      key: 'roa',
      width: '8%',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"%"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('GROSS PROFIT MARGIN'),
      dataIndex: 'profitMargin',
      key: 'profitMargin',
      width: '13%',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text * 100} displayType={'text'} thousandSeparator={true} prefix={"%"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: 'BEROAs',
      dataIndex: 'bRoas',
      key: 'bRoas',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
  ];

  return (
    <div className="page-content">
        <title>{props.t("Reports strategic")}</title>
      <Breadcrumbs
        title={'Costs'}
        breadcrumbItem={'Strategic Costs'}
        pageHeading={'Reports'}
        image={CostsIcon}
      />
      <Row>
        <Row style={{ marginTop: '15px' }}>
          <Col xs={8}>
            <span style={{ fontWeight: "300", fontSize: '15px', color: '#9291A5' }}>{props.t("CurrentAOV")}</span>
            {loadingState ? (
              <div>
                <NumberFormat value={avg} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={3} style={{ fontWeight: "bold", fontSize: '36px', color: 'black' }} />
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </Col>

          <Col xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Space>
              <Button type="primary" onClick={() => setModalVisible(true)} style={{ width: '212px', height: '53px', fontSize: '15px' }}>
                <img src={ExportIcon} alt="Export" style={{ marginRight: 10 }} />
                Export PDF
              </Button>
              <Modal
                title="Export Options"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
              >
                <Button onClick={onExport} style={{ marginRight: 10 }}>
                  Export PDF
                </Button>
                <CSVLink
                  filename={`${new Date().toLocaleDateString()}_Strategic.csv`}
                  data={strat || []}
                  headers={headers}
                >
                  <Button>Export CSV</Button>
                </CSVLink>
              </Modal>
            </Space>
          </Col>
        </Row>

        <OperationsChart metricData={metricData} colors={colors} />

      </Row>

      <Row style={{ marginTop: '53px' }}>
        <Breadcrumbs
          title={'Strategic Table Data'}
          image={CostsIcon}
        />
        <div style={{ height: '500px', marginBottom: '130px' }}>
          <StrategicTable columns={columns} data={strat} /> 
        </div>
      </Row>
    </div>
  );
}

Strategic.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Strategic);
