import React, { useEffect, useState } from 'react';


import { Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatNumberWithSpaces,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import { format } from 'date-fns';

import i18next from 'i18next';
import { getMetricsByCampaignsPerDate } from '../../store/googleAds/actions';
import AdTableSingleSearch from './AdTableSingleSearch';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { logoMap } from '../../assets/integrations_images/logoMap';


const GoogleAdsCompaignTable = ({ title = 'Campaigns Data' }) => {
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: dateRange.selection1.key,
    },
  };

  useEffect(() => {
    dispatch(
      getMetricsByCampaignsPerDate(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [dateRange]);

  const [sortedInfo, setSortedInfo] = useState({});

  const { metrcisbyCompaign, isLoading } = useSelector(
    (state) => state.GoogleAds
  );
  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (status) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: status == '2' ? '#007F00' : 'grey',
            backgroundColor: status == '2' ? '#CDFFCD' : '#fff0f6',
            fontWeight: status == '2' ? 'bold' : 'normal',
            opacity: status == '2' ? '1' : 0.6,
            borderRadius: '12.67px',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: status == '2' ? 'green' : 'grey',
              marginRight: '8px',
            }}
          ></span>
          {status == '2' ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      title: i18next.t('NAME'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 200,
      render: (text) => <span className="table-cell-text">{text}</span>,
    },
    {
      title: i18next.t('SPEND'),
      dataIndex: 'metrics.totalCost',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.metrics.totalCost - b.metrics.totalCost,
      sortOrder:
        sortedInfo && sortedInfo.columnKey === 'spend'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.metrics.totalCost.toFixed(2)}$
        </strong>
      ),
    },
    {
      title: i18next.t('REVENUE'),
      dataIndex: 'totalConversionsValue',
      key: 'totalConversionsValue',
      sorter: (a, b) =>
        a.metrics.totalConversionsValue - b.metrics.totalConversionsValue,
      sortOrder:
        sortedInfo.columnKey === 'totalConversionsValue'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      align: 'center',
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.metrics.totalConversionsValue.toFixed(2)}$
        </strong>
      ),
    },
    {
      title: i18next.t('CONVERSIONS'),
      dataIndex: 'totalConversions',
      key: 'totalConversions',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.metrics.totalConversions - b.metrics.totalConversions,
      sortOrder:
        sortedInfo.columnKey === 'totalConversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.metrics.totalConversions.toFixed(2)}
        </strong>
      ),
    },
    {
      title: i18next.t('IMPRESSIONS'),
      dataIndex: 'metrics.totalImpressions',
      key: 'impressions',
      sorter: (a, b) => a.metrics.totalImpressions - b.metrics.totalImpressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      width: 150,
      align: 'center',
      render: (text, record) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(record.metrics.totalImpressions)}
        </strong>
      ),
    },
    {
      title: i18next.t('INTERACTIONS'),
      dataIndex: 'metrics.totalInteractions',
      key: 'totalInteractions',
      align: 'center',
      width: 150,
      sorter: (a, b) =>
        a.metrics.totalInteractions - b.metrics.totalInteractions,
      sortOrder:
        sortedInfo.columnKey === 'totalInteractions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(record.metrics.totalInteractions)}
        </strong>
      ),
    },
    {
      title: i18next.t('CLICKS'),
      dataIndex: 'metrics.totalClicks',
      align: 'center',
      key: 'totalClicks',
      sorter: (a, b) => a.metrics.totalClicks - b.metrics.totalClicks,
      sortOrder:
        sortedInfo.columnKey === 'totalClicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(record.metrics.totalClicks)}
        </strong>
      ),
    },
    {
      title: 'CTR',
      dataIndex: 'metrics.ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.metrics.ctr - b.metrics.ctr,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.metrics?.ctr?.toFixed(2)}%
        </strong>
      ),
    },
    {
      title: 'CPC',
      dataIndex: 'metrics.cpc',
      align: 'center',
      key: 'cpc',
      sorter: (a, b) => a.metrics.cpc - b.metrics.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.metrics?.cpc?.toFixed(2)}$
        </strong>
      ),
    },
    {
      title: 'CPA',
      dataIndex: 'metrics.cpa',
      align: 'center',
      key: 'CPA',
      sorter: (a, b) => a.metrics.cpa - b.metrics.cpa,
      sortOrder: sortedInfo.columnKey === 'CPA' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong className="table-cell-text">
          {record.metrics?.cpa?.toFixed(2)}$
        </strong>
      ),
    },
  ];

  return (
    <div className="page-content">
      <Card style={{ border: 'none' }}>
        <CardBody>
          
          <Breadcrumb title={"Campaigns Data"} pageHeading={"Google Ads"} breadcrumbItem={"Campaigns"} image={logoMap["Google Ads"]} />
          <AdTableSingleSearch
            data={metrcisbyCompaign}
            columns={columns}
            sortedInfo={sortedInfo}
            setSortedInfo={setSortedInfo}
            loading={isLoading}
            statusMapping={{
              ENABLED: 2,
              PAUSED: 3,
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default GoogleAdsCompaignTable;
