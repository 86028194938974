import gql from 'graphql-tag';

const getWooTopSellers = gql`query($startDate: String!,$endDate: String!){
    all(startDate: $startDate,endDate: $endDate){
       name,
       quantity,
       product_id,
         image,
}}
`


export { getWooTopSellers};