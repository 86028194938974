import { emailbuilder } from "../../../queries/EmailBuilder/queries";
import urls from "../../../routes/apiUrls"
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import { Axios } from "../config";



const ApolooCLIEmailBuilder = new ApolloClient({
  link: new HttpLink({
    uri : urls.EmailBuilder,
       headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

export const uploadPhotoFiles = async (body) => {
  // try {
  //   const {
  //     data: { message, error, data },
  //   } = await Axios.post("uploadFiles", body);
  //   return { message, error, data };
  // } catch (error) {
  //   return { error: true, message: error.message, data: [] };
  // }
  console.log("uploadPhotoFiles", body)
};
export const getPhotoFiles = async () => {
  try {
    const {
      data: { message, error, data },
    } = await Axios.get("getFiles");
    return { message, error, data };
  } catch (error) {
    return { error: true, message: error.message, data: [] };
  }
};

 export const sendNewsletter = async (payload) => {
  console.log("sendNewsletter", payload.subject)
  ApolooCLIEmailBuilder.mutate({
    mutation : emailbuilder,
    variables : {
      subject: payload.subject,
      emails: payload.emails,
      mailContent: payload.mailContent,
    },
 
    errorPolicy: 'all',
  }).then((data)=>{
    if (data && data.length > 0 )
    console.log("success");
})

  }
export const getImageDimensions = (file) => {
  const url = window.URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      //URL.revokeObjectURL(img.src)
      resolve({
        width: img.width,
        height: img.height,
        url,
        file,
      });
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
  console.log("getImageDimensions", file)
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
