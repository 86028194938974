import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../common/HelperFunctions/utils';
import {
  getGAAffiliationsCompraisonRequest,
  getGAAffiliationsRequest,
} from '../../../store/actions';
import { Row } from 'reactstrap';

import GACardnew from '../common/GACardnew';

const GAAffiliationOverview = () => {
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const { startDate, endDate } = dateRange.selection1;
  const dispatch = useDispatch();
  const [EcommerceAffiliationDashboard, setEcommerceAffiliationDashboard] =
    useState([]);
  const [
    compareEcommerceAffiliationDashboard,
    setCompareEcommerceAffiliationDashboard,
  ] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [ecommercePurchases, setEcommercePurchases] = useState([]);
  const [compareEcommercePurchases, setCompareEcommercePurchases] = useState(
    []
  );
  const [purchaserRate, setPurchaserRate] = useState([]);
  const [comparePurchaserRate, setComparePurchaserRate] = useState([]);
  const [purchaseRevenue, setPurchaseRevenue] = useState([]);
  const [comparePurchaseRevenue, setComparePurchaseRevenue] = useState([]);
  const [averagePurchaseRevenue, setAveragePurchaseRevenue] = useState([]);
  const [compareAveragePurchaseRevenue, setCompareAveragePurchaseRevenue] =
    useState([]);
  const { gaAffiliations } = useSelector((state) => state.googleAnalytics);

  useEffect(() => {
    if (dateRange.selection1.startDate && dateRange.selection1.endDate) {
      const newStartDate = formatDate(dateRange.selection1?.startDate);
      const newEndDate = formatDate(dateRange.selection1?.endDate);
      if (dateRange.selection2?.startDate && dateRange.selection2?.endDate) {
        const newStartDate2 = formatDate(dateRange.selection2?.startDate);
        const newEndDate2 = formatDate(dateRange.selection2?.endDate);
        dispatch(
          getGAAffiliationsCompraisonRequest(
            newStartDate,
            newEndDate,
            newStartDate2,
            newEndDate2
          )
        );
      }
      dispatch(getGAAffiliationsRequest(newStartDate, newEndDate));
    }
  }, [dateRange]);

  //allow me to refecth data
  useEffect(() => {
    if (gaAffiliations && gaAffiliations.length > 0) {
      setEcommerceAffiliationDashboard(gaAffiliations[0]);
      setCompareEcommerceAffiliationDashboard([]);
      if (gaAffiliations.length > 1) {
        setCompareEcommerceAffiliationDashboard(gaAffiliations[1]);
      }
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }
  }, [gaAffiliations]);

  useEffect(() => {
    let isSubscribed = true;
    var TecommercePurchases = [];
    var TpurchaserRate = [];
    var TpurchaseRevenue = [];
    var TaveragePurchaseRevenue = [];
    if (EcommerceAffiliationDashboard.length !== 0) {
      EcommerceAffiliationDashboard.map((elem) => {
        TecommercePurchases.push(
          elem.ecommercePurchases ? elem.ecommercePurchases : 0
        );
        TpurchaserRate.push(elem.purchaserRate ? elem.purchaserRate : 0);
        TpurchaseRevenue.push(elem.purchaseRevenue ? elem.purchaseRevenue : 0);
        TaveragePurchaseRevenue.push(
          elem.averagePurchaseRevenue ? elem.averagePurchaseRevenue : 0
        );
      });
    }
    setEcommercePurchases(TecommercePurchases);
    setPurchaserRate(TpurchaserRate);
    setPurchaseRevenue(TpurchaseRevenue);
    setAveragePurchaseRevenue(TaveragePurchaseRevenue);

    return () => (isSubscribed = false);
  }, [EcommerceAffiliationDashboard]);
  useEffect(() => {
    var TecommercePurchases = [];
    var TpurchaserRate = [];
    var TpurchaseRevenue = [];
    var TaveragePurchaseRevenue = [];
    if (compareEcommerceAffiliationDashboard.length !== 0) {
      compareEcommerceAffiliationDashboard.map((elem) => {
        TecommercePurchases.push(
          elem.ecommercePurchases ? elem.ecommercePurchases : 0
        );
        TpurchaserRate.push(elem.purchaserRate ? elem.purchaserRate : 0);
        TpurchaseRevenue.push(elem.purchaseRevenue ? elem.purchaseRevenue : 0);
        TaveragePurchaseRevenue.push(
          elem.averagePurchaseRevenue ? elem.averagePurchaseRevenue : 0
        );
      });
    }
    setCompareEcommercePurchases(TecommercePurchases);
    setComparePurchaserRate(TpurchaserRate);
    setComparePurchaseRevenue(TpurchaseRevenue);
    setCompareAveragePurchaseRevenue(TaveragePurchaseRevenue);
  }, [compareEcommerceAffiliationDashboard]);

  const [myCards, setMyCards] = useState([
    {
      id: 1,
      name: 'ecommerce Purchases',
      data: ecommercePurchases,
      compareData: compareEcommercePurchases,
    },
    {
      id: 2,
      name: 'purchaser Rate',
      data: purchaserRate,
      compareData: comparePurchaserRate,
    },
    {
      id: 3,
      name: 'purchase Revenue',
      data: purchaseRevenue,
      compareData: comparePurchaseRevenue,
    },
    {
      id: 4,
      name: 'average Purchase Revenue',
      data: averagePurchaseRevenue,
      compareData: compareAveragePurchaseRevenue,
    },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setMyCards([
      {
        id: 1,
        name: 'Ecommerce Purchases',
        data: ecommercePurchases,
        compareData: compareEcommercePurchases,
      },
      {
        id: 2,
        name: 'Purchaser Rate',
        data: purchaserRate,
        compareData: comparePurchaserRate,
      },
      {
        id: 3,
        name: 'Purchase Revenue',
        data: purchaseRevenue,
        compareData: comparePurchaseRevenue,
      },
      {
        id: 4,
        name: 'Average Purchase Revenue',
        data: averagePurchaseRevenue,
        compareData: compareAveragePurchaseRevenue,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    ecommercePurchases,
    purchaserRate,
    purchaseRevenue,
    averagePurchaseRevenue,
    compareAveragePurchaseRevenue,
    compareEcommercePurchases,
    comparePurchaseRevenue,
    comparePurchaserRate,
  ]);

  return (
    <Row className=" justify-content-center my-5">
      <GACardnew data={myCards} />
    </Row>
  );
};

export default GAAffiliationOverview;
