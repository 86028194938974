import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getInstaPageData,
  getInstaPostData,
  getCompareInstaPageData,
} from '../../store/instagram/actions';
import { useDispatch, useSelector } from 'react-redux';

import instagramIcon from '../../assets/integrations_images/insta.svg';
import InstagramTable from '../InstagramDashboard/InstagramTable';
import InstagramSocialMediaCards from '../InstagramDashboard/InstagramSocialMediaCards';

const InstagramOverview = (props) => {
  const dispatch = useDispatch();
  const { instaPageData, instaPostData } = useSelector(
    (state) => state.Instagram
  );

  const [sortedInfo, setSortedInfo] = useState({});
  const [reach, setreach] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [impressions, setimpressions] = useState([]);
  const [compareImpressions, setCompareImpressions] = useState([]);
  const [follower_count, setfollower_count] = useState([]);
  const [compareFollower_count, setCompareFollower_count] = useState([]);
  const [profile_views, setprofile_views] = useState([]);
  const [compareProfile_views, setCompareProfile_views] = useState([]);
  const [website_clicks, setwebsite_clicks] = useState([]);
  const [compareWebsite_clicks, setCompareWebsite_clicks] = useState([]);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (
      props.dateRange.selection1.startDate &&
      props.dateRange.selection1.endDate
    ) {
      if (
        props.dateRange.selection2.startDate &&
        props.dateRange.selection2.endDate
      ) {
        dispatch(
          getCompareInstaPageData(
            formatDate(props.dateRange.selection1.startDate),
            formatDate(props.dateRange.selection1.endDate),
            formatDate(props.dateRange.selection2.startDate),
            formatDate(props.dateRange.selection2.endDate)
          )
        );
      } else {
        dispatch(
          getInstaPageData(
            formatDate(props.dateRange.selection1.startDate),
            formatDate(props.dateRange.selection1.endDate)
          )
        );
      }
    }
  }, [props.dateRange]);

  useEffect(() => {
    if (instaPageData && instaPageData.length > 0) {
      let total_reach = [];
      let total_impressions = [];
      let total_follower_count = [];
      let total_profile_views = [];
      let total_website_clicks = [];

      instaPageData[0].forEach((item) => {
        total_reach.push(item.reach);
        total_impressions.push(item.impressions);
        total_follower_count.push(item.follower_count);
        total_profile_views.push(item.profile_views);
        total_website_clicks.push(item.website_clicks);
      });
      setreach(total_reach);
      setimpressions(total_impressions);
      setfollower_count(total_follower_count);
      setprofile_views(total_profile_views);
      setwebsite_clicks(total_website_clicks);

      let compare_total_reach = [];
      let compare_total_impressions = [];
      let compare_total_follower_count = [];
      let compare_total_profile_views = [];
      let compare_total_website_clicks = [];

      if (instaPageData[1] && instaPageData[1].length > 0) {
        instaPageData[1].forEach((item) => {
          compare_total_reach.push(item.reach);
          compare_total_impressions.push(item.impressions);
          compare_total_follower_count.push(item.follower_count);
          compare_total_profile_views.push(item.profile_views);
          compare_total_website_clicks.push(item.website_clicks);
        });
      }
      setCompareData(compare_total_reach);
      setCompareImpressions(compare_total_impressions);
      setCompareFollower_count(compare_total_follower_count);
      setCompareProfile_views(compare_total_profile_views);
      setCompareWebsite_clicks(compare_total_website_clicks);
    }
  }, [instaPageData]);

  useEffect(() => {
    dispatch(getInstaPostData());
  }, []);
  const metrics = [
    {
      name: props.t('Impressions'),
      data: impressions,
      compareData: compareImpressions,
    },
    {
      name: props.t('New Followers'),
      data: follower_count,
      compareData: compareFollower_count,
    },
    {
      name: props.t('Reach'),
      data: reach,
      compareData: compareData,
    },
    {
      name: props.t('Profile views'),
      data: profile_views,
      compareData: compareProfile_views,
    },
  ];
  const columns = [
    {
      title: props.t('POST IMAGE'),
      dataIndex: 'media_url',
      key: 'media_url',
      align: 'center',
      render: (text, record) => {
        if (!text) {
          return (
            <a
              href={record.permalink}
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          );
        } else {
          return (
            <a
              href={record.permalink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={text}
                alt={record.full_picture}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      },
    },
    {
      title: props.t('INTERACTIONS'),
      dataIndex: 'total_interactions',
      key: 'total_interactions',
      sorter: (a, b) => a.total_interactions - b.total_interactions,
      sortOrder:
        sortedInfo.columnKey === 'total_interactions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: props.t('SAVES'),
      dataIndex: 'saved',
      align: 'center',
      key: 'saved',
      sorter: (a, b) => a.saved - b.saved,
      sortOrder: sortedInfo.columnKey === 'saved' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },
    {
      title: props.t('IMPRESSIONS'),
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'center',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">{text || '-'}</strong>
      ), // Render '-' if text is falsy
    },
    {
      title: props.t('REACH'),
      dataIndex: 'reach',
      align: 'center',
      key: 'reach',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className="table-cell-text">{text}</strong>,
    },

    {
      title: props.t('CREATED AT'),
      dataIndex: 'timestamp',
      align: 'center',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      ellipsis: true,
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toISOString().split('T')[0]; // Extracts date portion
        return <strong className="table-cell-text">{formattedDate}</strong>;
      },
    },
  ];

  const colors = {
    lineColor: '#FF5B41',
    offsetZERO: '#FF6241',
    offsetONE: '#FFD354',
    offsetTWO: '#ffffff',
  };

  return (
    <div className="mt-5">
      <Col xl={12}>
        <Breadcrumbs image={instagramIcon} title={props.t("Instagram Social Page")} />

        <Row className="justify-content-center ">
          <InstagramSocialMediaCards secCards={metrics} />
        </Row>
        <Breadcrumbs image={instagramIcon} title="Instagram Posts" />

        <Row>
          <InstagramTable
            columns={columns}
            data={instaPostData}
            sortedInfo={sortedInfo}
            setSortedInfo={setSortedInfo}
          />
        </Row>
      </Col>
    </div>
  );
};

InstagramOverview.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InstagramOverview));
