import Breadcrumbs from '../../../components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import GAUsersOverview from './GAUsersOverview';
import GAPurchaseJourney from './GAPurchaseJourney';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';

import html2canvas from 'html2canvas';
import GASoldProductsOverview from '../GAsoldProducts/GASoldProductsOverview';
const compareStartDateString = new Date(
  'Wed Jun 12 2024 00:00:00 GMT+0100 (heure normale d’Afrique de l’Ouest)'
);
const compareEndDateString = new Date(
  'Wed Jun 14 2024 00:00:00 GMT+0100 (heure normale d’Afrique de l’Ouest)'
);
const GoogleAnalyticsReportDetail = () => {
  //upload brand image
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    const storedImage = localStorage.getItem('selectedImage');
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataURL = event.target.result;

        // Save the selected image URL to localStorage
        localStorage.setItem('selectedImage', imageDataURL);

        setSelectedImage(imageDataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const exportToPDF = async () => {
    const capture = document.querySelector('.print');

    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();

      const padding = 10; // Adjust this value to set the padding in millimeters

      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        210 - 2 * padding,
        componentHeight - 2 * padding
      );

      doc.save('Rapport-Googl-Ads.pdf');
    });
  };

  const printInvoice = () => {
    window.print();
  };
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: new Date(window.localStorage.getItem('start')),
      //    ? new Date(window.localStorage.getItem('start'))
      //    : new Date(
      //        new Date().toLocaleDateString('en-US', {
      //          timeZone: props.timeZone ? props.timeZone : 'UTC',
      //        })
      //      )
      endDate: new Date(window.localStorage.getItem('end')),
      //    ? new Date(window.localStorage.getItem('end'))
      //    : new Date(
      //        new Date().toLocaleDateString('en-US', {
      //          timeZone: props.timeZone ? props.timeZone : 'UTC',
      //        })
      //      ),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Google Ads Report" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="print">
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="">
                            <div
                              style={{ marginRight: '-10px' }}
                              className="flex-grow-1"
                            >
                              {/* Input element for uploading an image */}
                              <input
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                              />
                              {selectedImage ? (
                                <img
                                  src={selectedImage}
                                  alt="Selected Image"
                                  style={{
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    document
                                      .getElementById('imageUpload')
                                      .click()
                                  }
                                />
                              ) : (
                                <label
                                  htmlFor="imageUpload"
                                  className="btn btn-primary waves-effect waves-light w-sm"
                                >
                                  <i className="mdi mdi-upload d-block font-size-16 text-white"></i>
                                  Upload Brand Image
                                </label>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex-shrink-0">
                          <div className="">
                            <h4 className="float-end font-size-16">
                              'Google ADS Performence Report'
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="flex-grow-1">
                          <div className=""></div>
                        </div>

                        <div className="flex">
                          <div>
                            <FeatherIcon icon="calendar" />
                            Start Date
                            <input
                              name="date"
                              type="date"
                              autoComplete="off"
                              value={`${chosedDate[0].startDate.getFullYear()}-${String(
                                chosedDate[0].startDate.getMonth() + 1
                              ).padStart(2, '0')}-${String(
                                chosedDate[0].startDate.getDate()
                              ).padStart(2, '0')}`}
                              id="date"
                              disabled
                              className="form-control"
                              placeholder="date"
                            />
                          </div>
                          <div>
                            <FeatherIcon icon="calendar" />
                            End Date
                            <input
                              name="date"
                              type="date"
                              autoComplete="off"
                              value={`${chosedDate[0].endDate.getFullYear()}-${String(
                                chosedDate[0].endDate.getMonth() + 1
                              ).padStart(2, '0')}-${String(
                                chosedDate[0].endDate.getDate()
                              ).padStart(2, '0')}`}
                              disabled
                              className="form-control"
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col md={12}>
                        <GAUsersOverview
                          chosedDate={chosedDate}
                        ></GAUsersOverview>
                        <GAPurchaseJourney
                          startDate={chosedDate[0].startDate}
                          endDate={chosedDate[0].endDate}
                          comparedStartDate={compareStartDateString}
                          comparedEndDate={compareEndDateString}
                        />
                        <hr></hr>
                        <GASoldProductsOverview
                          chosedDate={chosedDate}
                          showPagination={false}
                        />
                      </Col>
                    </Row>
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <button
                          onClick={exportToPDF}
                          className="btn btn-primary w-md "
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GoogleAnalyticsReportDetail;
