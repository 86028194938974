import React from 'react';
import { Link } from 'react-router-dom';
import { SideBarIconsMap } from '../../assets/sidebar_icons/sideBarIconsMap';

const SideBarContentElement = ({ item }) => {
  // the item will be as follows:
  //{route: "/overview", name: "Overview"}
  // or if it has a submenu like this
  // { name: "Overview", children: [ { route: "/overview", name: "Overview" } ] }
  return !item.children ? (
    <li>
      <Link to={item.route}>
        <div className="data-container">
          <div className="img-container">
            <img src={SideBarIconsMap[item.name]} alt="icon" />
          </div>
          <span>{item.name}</span>
        </div>
      </Link>
    </li>
  ) : (
    <li>
      <Link to="/#" className="has-arrow">
        <div className="data-container">
          <div className="img-container">
            <img src={SideBarIconsMap[item.name]} alt="icon" />
          </div>
        </div>
        <span>{item.name}</span>
      </Link>
      <ul className="sub-menu">
        {item.children.map((child) => (
          <li key={child.route}>
            <Link to={child.route}>{child.name}</Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default SideBarContentElement;
