import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchTopSellersFailure,
  fetchTopSellersSuccess,
  fetchCouponsCodesFailure,
  fetchCouponsCodesSuccess,
  fetchOrderStatusSuccess,
  fetchOrderStatusFailure,
  fetchProductCategorySuccess,
  fetchProductCategoryFailure,
  integrationswoSuccess,
  integrationswoFailure,
  deleteWoocommerceSuccess,
  deleteWoocommerceFailure,
  getSalesPlateformeSuccess,
  getSalesPlateformeFailure,
  checkThirdStepWoocommerceSuccess,
  checkThirdStepWoocommerceFailure,
} from './actions';
import {
  FETCH_TOP_SELLERS_REQUEST,
  FETCH_COUPONS_CODES_REQUEST,
  FETCH_ORDER_STATUS_REQUEST,
  FETCH_PRODUCT_CATEGORY_REQUEST,
  INTEGRATIONS_WO_REQUEST,
  DELETE_WOOCOMMERCE_REQUEST,
  GET_SALES_PLATEFORME_REQUEST,
  CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST,
} from './actionsTypes';
import { getWooTopSellers } from '../../queries/woo/wooTopSellersQueries';
import { getCoupons } from '../../queries/woo/wooCouponsQueries';
import { getOrdersStatus } from '../../queries/woo/wooOrdersStatusQueries';
import { getProductCategory } from '../../queries/woo/wooProductCategoryQueries';
import {
  getWooStore,
  integrateWoo,
  deleteIntegration,
} from '../../queries/woo/queries';
import { checkSales } from '../../queries/Shopify/queries';
import { successAlert } from '../../pages/Alerts/NotLinkedAccountAlert';
import urls from '../../routes/apiUrls';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const AppoloCliWoocommerce = new ApolloClient({
  link: new HttpLink({
    uri: urls.shopify,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.woo,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const wooTopSellers = new ApolloClient({
  link: new HttpLink({
    uri: urls.wootopsellers,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const wooCouponsCodes = new ApolloClient({
  link: new HttpLink({
    uri: urls.woocoupons,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const wooOrderStatus = new ApolloClient({
  link: new HttpLink({
    uri: urls.wooordersstatus,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

const wooProductCategory = new ApolloClient({
  link: new HttpLink({
    uri: urls.wooproductcategory,

    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});
function* fetchWooTopSellers(action) {
  try {
    const { data } = yield call(wooTopSellers.query, {
      query: getWooTopSellers,
      variables: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },

      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    yield put(fetchTopSellersSuccess(data.all));
  } catch (error) {
    yield put(fetchTopSellersFailure(error.message));
  }
}
function* fetchWooCouponsCodes(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooCouponsCodes.query, {
      query: getCoupons,
      variables: { limit, page },

      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    localStorage.setItem('woocoupons', JSON.stringify(data.all));
    yield put(fetchCouponsCodesSuccess(data.all));
  } catch (error) {
    yield put(fetchCouponsCodesFailure(error.message));
  }
}
function* fetchWooOrderStatus(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooOrderStatus.query, {
      query: getOrdersStatus,
      variables: { limit, page },

      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    localStorage.setItem('wooorderstatus', JSON.stringify(data.all));
    yield put(fetchOrderStatusSuccess(data.all));
  } catch (error) {
    yield put(fetchOrderStatusFailure(error.message));
  }
}
function* fetchWooProductCategory(action) {
  try {
    const { limit, page } = action.payload;
    const { data } = yield call(wooProductCategory.query, {
      query: getProductCategory,
      variables: { limit, page },

      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    localStorage.setItem('wooProductCategory', JSON.stringify(data.all));
    yield put(fetchProductCategorySuccess(data.all));
  } catch (error) {
    yield put(fetchProductCategoryFailure(error.message));
  }
}
function* integratewo(action) {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: integrateWoo,
      variables: {
        key: action.payload.key,
        secret: action.payload.secret,
        url: action.payload.url,
      },

      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    console.log(data);
    if (data.integration) {
      yield put(integrationswoSuccess(data.integration));
      window.location.replace('/integrations');
    }
  } catch (error) {
    yield put(integrationswoFailure(error.message));
  }
}
function* deleteWoocommerce(action) {
  try {
    const { data } = yield call(ApploCLI.mutate, {
      mutation: deleteIntegration,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });

    yield put(deleteWoocommerceSuccess());
    successAlert().then(() => {
      window.location.reload();
    });
  } catch (error) {
    yield put(deleteWoocommerceFailure(error.message));
  }
}

function* fetchWooStore() {
  try {
    const { data } = yield call(ApploCLI.query, {
      query: getWooStore,

      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    });
    if (data.get) {
      console.log('*************eaezaeaz**************', data.get);
      var websiteName = JSON.stringify(data.get.url);
      websiteName = websiteName.replace('/', '');
      localStorage.setItem('websiteName', websiteName);
      var info = data.get;
      yield put(getSalesPlateformeSuccess(info, websiteName));
    } else {
      yield put(getSalesPlateformeFailure('data null'));
    }
  } catch (error) {
    yield put(getSalesPlateformeFailure(error.message));
  }
}

function* checkThirdStepWoocommerce() {
  try {
    const { data } = yield call(AppoloCliWoocommerce.query, {
      query: checkSales,

      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });
    if (data.getIntegration) {
      if (data.getIntegration.type !== 7) {
        yield put(checkThirdStepWoocommerceSuccess());
      }
    }
  } catch (error) {
    yield put(checkThirdStepWoocommerceFailure(error.message));
  }
}

export default function* rootSaga() {
  yield takeEvery(FETCH_TOP_SELLERS_REQUEST, fetchWooTopSellers);
  yield takeEvery(FETCH_COUPONS_CODES_REQUEST, fetchWooCouponsCodes);
  yield takeEvery(FETCH_ORDER_STATUS_REQUEST, fetchWooOrderStatus);
  yield takeEvery(FETCH_PRODUCT_CATEGORY_REQUEST, fetchWooProductCategory);
  yield takeEvery(INTEGRATIONS_WO_REQUEST, integratewo);
  yield takeEvery(DELETE_WOOCOMMERCE_REQUEST, deleteWoocommerce);
  yield takeEvery(GET_SALES_PLATEFORME_REQUEST, fetchWooStore);
  yield takeEvery(
    CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST,
    checkThirdStepWoocommerce
  );
}
