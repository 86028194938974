import {
  FETCH_TOP_SELLERS_FAILURE,
  FETCH_TOP_SELLERS_SUCCESS,
  FETCH_TOP_SELLERS_REQUEST,
  FETCH_COUPONS_CODES_FAILURE,
  FETCH_COUPONS_CODES_REQUEST,
  FETCH_COUPONS_CODES_SUCCESS,
  FETCH_ORDER_STATUS_REQUEST,
  FETCH_ORDER_STATUS_SUCCESS,
  FETCH_ORDER_STATUS_FAILURE,
  FETCH_PRODUCT_CATEGORY_REQUEST,
  FETCH_PRODUCT_CATEGORY_SUCCESS,
  FETCH_PRODUCT_CATEGORY_FAILURE,
  INTEGRATIONS_WO_REQUEST,
  INTEGRATIONS_WO_SUCCESS,
  INTEGRATIONS_WO_FAILURE,
  DELETE_WOOCOMMERCE_REQUEST,
  DELETE_WOOCOMMERCE_SUCCESS,
  DELETE_WOOCOMMERCE_FAILURE,
  GET_SALES_PLATEFORME_REQUEST,
  GET_SALES_PLATEFORME_SUCCESS,
  GET_SALES_PLATEFORME_FAILURE,
  CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST,
  CHECK_THIRD_STEP_WOOCOMMERCE_SUCCESS,
  CHECK_THIRD_STEP_WOOCOMMERCE_FAILURE,

} from './actionsTypes';

const initialState = {
  loading: false,
  topSellers: [],
  coupons: [],
  orderStatus: [],
  ProductCategory: [],
  error: null,
  reachedFinalstep: false,
  step: null,
  data:[],
  info:[],
  websiteName:'',
};

const woocommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOP_SELLERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TOP_SELLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        topSellers: action.payload,
      };
    case FETCH_TOP_SELLERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_COUPONS_CODES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_COUPONS_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: action.payload,
      };
    case FETCH_COUPONS_CODES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ORDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderStatus: action.payload,
      };
    case FETCH_ORDER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        ProductCategory: action.payload,
      };
    case FETCH_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case INTEGRATIONS_WO_REQUEST:
      return {
        ...state,
        error: null,
      };
      case INTEGRATIONS_WO_SUCCESS:
      return {
        ...state,
        data :action.payload,
        step:2,
        reachedFinalstep: true,

      };
    case INTEGRATIONS_WO_FAILURE:
      return {
        ...state,
        error: action.payload,
        
      };
      case DELETE_WOOCOMMERCE_REQUEST:
      return {
        ...state,

      };
      case DELETE_WOOCOMMERCE_SUCCESS:
      return {
        ...state,
        step:1,
      }
    case DELETE_WOOCOMMERCE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case GET_SALES_PLATEFORME_REQUEST:
      return {
        ...state,
        error: null,
      };
      case GET_SALES_PLATEFORME_SUCCESS:
      return {
        ...state,
        info :action.payload.info,
        websiteName: action.payload.websiteName,
        step:2,
        reachedFinalstep: true,

      };
    case GET_SALES_PLATEFORME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST:
      return {
        ...state,
        error: null,
      };
      case CHECK_THIRD_STEP_WOOCOMMERCE_SUCCESS:
      return {
        ...state,
        step:3,
        reachedFinalstep: true,
      };
    case CHECK_THIRD_STEP_WOOCOMMERCE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default woocommerceReducer;