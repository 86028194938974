import React from 'react';
import ReactApexChart from 'react-apexcharts';
const StackedLineChart = ({ dataSeries, chosedDate }) => {
  const { startDate, endDate } = chosedDate;
  console.log('Data Series:', dataSeries);
  const generateDateRange = (start, end) => {
    const startDt = new Date(start);
    const endDt = new Date(end);
    const dateArray = [];

    let currentDate = startDt;
    while (currentDate <= endDt) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  };

  const dateArray = generateDateRange(startDate, endDate).map((day) =>
    day.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'long',
    })
  );

  // Calculate the min and max values for the y-axis
  const allValues = dataSeries.reduce(
    (acc, series) => acc.concat(series.data),
    []
  );
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);


  const colors = ['#FF925B', '#EC1A74', '#F6B43B', '#0064E0', '#007F00'];
  
  const options = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      animations: { enabled: true },
    },
    colors: colors,
    dataLabels: { enabled: false }, // disable data labels
    stroke: {
      width: 2,
      curve: 'straight',
    },
    grid: {
      row: { colors: ['transparent', 'transparent'], opacity: 0.2 },
      borderColor: '#E7E8F2',
      padding: {
        top: 40,
        left: 20,
      },
    },
    markers: {
      style: 'inverted',
      strokeWidth: 3,
      size: 7,
      hover: { size: 5, strokeWidth: 2 },
    },
    xaxis: {
      categories: dateArray,
      labels: {
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
      },
      
    },
    yaxis: {
      min: Math.floor(minValue),
      max: Math.ceil(maxValue),
      labels: {
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: 30,
      offsetX: -5,
      markers: {
        size: 10,
        shape: 'circle',
      },
      fontFamily: 'DM Sans ',
      fontSize: '14px',
      itemMargin: { horizontal: 15 },
    },

    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
  };

  return (
    <div style={{ padding: '20px' }}>
      <ReactApexChart
        options={options}
        series={dataSeries}
        type="line"
        height="420"
      />
    </div>
  );
};

export default StackedLineChart;
