export const tooltipMap={
  "Ad Spend": {
    "tooltip": "Total ad spending.",
    "formula": "Sum of all ad costs."
  },
  "Revenue": {
    "tooltip": "Total sales revenue.",
    "formula": "Sum of all sales revenue."
  },
  "Conversions": {
    "tooltip": "Number of completed actions.",
    "formula": "Total successful actions."
  },
  "ROAS": {
    "tooltip": "Return on ad spend.",
    "formula": "Revenue * Exchange Rate / Ad Spend."
  },
  "CPA": {
    "tooltip": "Cost per acquisition.",
    "formula": "Ad Spend / Conversions."
  },
  "CPC": {
    "tooltip": "Cost per click.",
    "formula": "Ad Spend / Clicks."
  },
  "CTR": {
    "tooltip": "Click-through rate.",
    "formula": "(Clicks / Impressions) * 100."
  },
  "Conversion Rate": {
    "tooltip": "Rate of actions per click.",
    "formula": "(Conversions / Clicks) * 100."
  },
  "Impressions": {
    "tooltip": "Total ad views.",
    "formula": "Count of ad displays."
  },
  "Clicks": {
    "tooltip": "Total ad clicks.",
    "formula": "Count of ad clicks."
  },
  "AOV": {
    "tooltip": "Average order value.",
    "formula": "Revenue / Conversions."
  },
  "Total Landing Page View": {
    "tooltip": "Total landing page views.",
    "formula": "Count of landing page views."
  },
  "Total Reach": {
    "tooltip": "Unique viewers.",
    "formula": "Count of unique viewers."
  },
  "Ecommerce Purchases": {
    "tooltip": "Total eCommerce purchases.",
    "formula": "Count of all purchases."
  },
  "Purchaser Rate": {
    "tooltip": "Percentage of buyers.",
    "formula": "(Ecommerce Purchases / Total Visitors) * 100."
  },
  "Purchase Revenue": {
    "tooltip": "Revenue from purchases.",
    "formula": "Sum of all purchase revenues."
  },
  "Average Purchase Revenue": {
    "tooltip": "Average revenue per purchase.",
    "formula": "Purchase Revenue / Ecommerce Purchases."
  },
  "Average Purchase Revenue Per Paying User": {
    "tooltip": "Revenue per paying user.",
    "formula": "Purchase Revenue / Paying Users."
  },
  "Total Sales": {
    "tooltip": "Total store sales.",
    "formula": "Sum of all sales."
  },
  "Average Order Value": {
    "tooltip": "Average value per order.",
    "formula": "Total Sales / Total Orders."
  },
  "Total Orders": {
    "tooltip": "Total number of orders.",
    "formula": "Count of all orders."
  },
  "MER": {
    "tooltip": "Marketing efficiency ratio.",
    "formula": "Total Sales / Total Ads Spend."
  },
  "Total Ads Spend": {
    "tooltip": "The combined amount spent on Google Ads and Facebook Ads, converted to the store's currency.",
    "formula": "Google Ads Spend * Exchange Rate + Facebook Ads Spend * Exchange Rate.) "
  },
  "Total Ads Revenue": {
    "tooltip": "The combined revenue generated from Google Ads and Facebook Ads, converted to the store's currency.",
    "formula": "Google Ads Revenue * Exchange Rate + Facebook Ads Revenue* Exchange Rate.) "
  }
}
