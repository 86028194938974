import { Carousel } from 'antd';
import React from 'react';

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const items = [
  {
    src: 'banner-image1.png', // Replace with your image path
    altText: 'Slide 1',
  },
  {
    src: 'banner-image2.png', // Replace with your image path
    altText: 'Slide 2',
  },
  {
    src: 'banner-image3.png', // Replace with your image path
    altText: 'Slide 3',
  },
];

const BannerCarousel = () => {
  return (
    <Carousel autoplay dots={false} draggable={true} arrows={true}>
      {items.map((item, index) => (
        <div key={index}>
          <img
            src={item.src}
            alt={item.altText}
            style={{ width: '100%', height: '160px', objectFit: 'cover' }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;
