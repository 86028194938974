import React from 'react';
import OverallCard from './overallCardPieChart';
import { Card, CardBody, Row } from 'reactstrap';
import i18next from 'i18next';
const GeneralOverviewCard = ({ data, title }) => {
  return (
    <Card className="card-h-100">
      <CardBody>
        <div className="d-flex flex-wrap align-items-center mb-4">
          <h4 className="" style={{ color: '#0044BD' }}>
            {i18next.t(title)}
          </h4>
        </div>
        <Row className="align-items-center">
          <div className="col-sm">
            <div id="wallet-balance" className="apex-charts">
              <OverallCard
                data={data}
                style={{ width: '100%', height: '400px' }}
                type="data"
              />
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default GeneralOverviewCard;
