import { takeLatest, put, call} from 'redux-saga/effects';
import { getInformations } from "../../../queries/Reports/OperationsQuery";
import urls from '../../../routes/apiUrls';
import {
    GET_OPERATION_REPORTS_REQUEST,
    getOperationDataSuccess,
    getOperationDataFailure,


} from './action';
import {  ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';


  const ApploCLI = new ApolloClient({
    link: new HttpLink({
        uri: urls.report,
         headers: {
        Authorization: window.localStorage.getItem('token')
          ? `Bearer ${window.localStorage.getItem('token')}`
          : '',
      },
      credentials: 'include', // Include cookies for cross-origin requests
    }),
    cache: new InMemoryCache(),
  });


function* getOperationData(action) {

    try{
        const {data}= yield call(ApploCLI.query, {
            query: getInformations,
            variables: {
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                channel: action.payload.channel,
            },
            context: {
                headers: {
                    Authorization: window.localStorage.getItem('token')
                        ? `Bearer ${window.localStorage.getItem('token')}`
                        : '',
                }
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache",
        })
        if(data.ops){
            console.log('data.ops',data.ops)
           var ops= data.ops;
           let series = [];
              let sales = [];
           var Tsales = [];
        var TempSeries = [];
        const time = data.ops.map((elem, idx) => {
            const created = new Date(elem.created);
                 const formattedDate = created.getDate() + " " + created.toLocaleString('default', { month: 'short' });
                 Tsales.push(elem.sumTotal ? elem.sumTotal : 0)
        return formattedDate;
        
                });
                TempSeries.push(time)
                series=TempSeries
                sales=Tsales

                const avgTest = data.ops && data.ops.map((el) => el.sumTotal);  
                  var avg=data.ops &&
                  data.ops.length > 0 &&
                 data.ops.map((el) => el.sumTotal).reduce((a, b) => a + b)

                    var daily = data.ops && data.ops.length > 0 && data.ops.map((el) => el.sumTotal).reduce((a, b) => a + b) /avgTest.length;

 
                    var avgGR=data.ops && data.ops.length > 0 && data.ops.map((el) => el.grossRev).reduce((a, b) => a + b) 
                    var avgAdCost=data.ops && data.ops.length > 0 && data.ops.map((el) => el.adCost).reduce((a, b) => a + b)
                    var avgFacebook=data.ops && data.ops.length > 0 && data.ops.map((el) => el.facebook).reduce((a, b) => a + b)
                    var avgGoogle=data.ops && data.ops.length > 0 && data.ops.map((el) => el.google).reduce((a, b) => a + b)
                    var avgCOG = data.ops && data.ops.length > 0 && data.ops.map((el) => el.costGoods).reduce((a, b) => a + b)
                    var avgShip = data.ops && data.ops.length > 0 && data.ops.map((el) => el.shippingCost).reduce((a, b) => a + b)
                     var avgSnh= data.ops && data.ops.length > 0 && data.ops.map((el) => el.variantHandling).reduce((a, b) => a + b)
                     var avgMF= data.ops && data.ops.length > 0 && data.ops.map((el) => el.merchantFee).reduce((a, b) => a + b)
                     var avgRef = data.ops && data.ops.length > 0 && data.ops.map((el) => el.refund).reduce((a, b) => a + b)

                      var avgDis= data.ops && data.ops.length > 0 && data.ops.map((el) => el.discount).reduce((a, b) => a + b)
                      console.log('avgDis',avgDis)
                        var avgTax= data.ops && data.ops.length > 0 && data.ops.map((el) => el.tax).reduce((a, b) => a + b)
                       var avgNetIn= data.ops && data.ops.length > 0 && data.ops.map((el) => el.netProfit).reduce((a, b) => a + b) 

                       var avgOrd= data.ops && data.ops.length > 0 && data.ops.map((el) => el.numOrders).reduce((a, b) => a + b)
                       var avgUni= data.ops && data.ops.length > 0 && data.ops.map((el) => el.sumQte).reduce((a, b) => a + b)
                       var avgtotalreturn= data.ops && data.ops.length > 0 && data.ops.map((el) => (el.sumTotal- el.adCost- el.discount-el.variantHandling)).reduce((a, b) => a + b)

                      yield put(getOperationDataSuccess(ops,series, sales, avg, daily, avgGR,avgAdCost, avgFacebook, avgGoogle, avgCOG, avgSnh, avgRef,  avgDis, avgTax, avgOrd, avgUni, avgShip, avgMF,  avgNetIn, avgtotalreturn)); 
        }
        else{
            yield put(getOperationDataFailure('something went wrong in if condition'));
        }



    }catch(error){
        yield put(getOperationDataFailure('eazaa'));
    }

}


export default function* OPERATIONSaga() {
    yield takeLatest(GET_OPERATION_REPORTS_REQUEST, getOperationData);
}
