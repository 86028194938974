import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Table, Switch, message, ConfigProvider, Input } from "antd"; // Ant Design components
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {  ApolloClient, InMemoryCache, HttpLink  } from '@apollo/client';

import { getInstagramocialMediaAccounts, toggleInstaSocialMediaAccount, deletePlatformInstagramSocialMedia, getInstagramSocialMedia, getFromInstagramSocial } from '../../../queries/instagramSocialMedia/queries';
import urls from '../../../routes/apiUrls';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18next from 'i18next';
import {facebook} from '../../../config';
import PropTypes from "prop-types";
import InstagramLogo from '../../../assets/integrations_images/instagram-integration.svg';


const ApploCLI = new ApolloClient({
  link: new HttpLink({
    uri: urls.instagramSocialMedia,
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    },
    credentials: 'include', // Include cookies for cross-origin requests
  }),
  cache: new InMemoryCache(),
});

 
function InstagramSocialMediaCard({ t }) {
  const [instaData, setInstaData] = useState([]);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [inactiveAccounts, setInactiveAccounts] = useState([]);
  const [step, setStep] = useState(1);
  const [searchText, setSearchText] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getInstagramSocialMedia,
        context: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem("token") || ""}` }
        },
        errorPolicy: "all",
        fetchPolicy: "network-only"
      });

      if (data.one) {
        setInfo(data.one);
        setStep(2);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchInstagramAccounts = useCallback(async () => {
    try {
      const { data } = await ApploCLI.query({
        query: getInstagramocialMediaAccounts,
        context: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem("token") || ""}` }
        },
        errorPolicy: "all",
        fetchPolicy: "network-only"
      });

      if (data.instagramSocialMediaAccounts) {
        const active = data.instagramSocialMediaAccounts.filter(account => account.active);
        const inactive = data.instagramSocialMediaAccounts.filter(account => !account.active);
        setActiveAccounts(active);
        setInactiveAccounts(inactive);

        if (active.length) {
          setStep(3);
        } else {
          setStep(2);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [info]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (info) fetchInstagramAccounts();
  }, [info, fetchInstagramAccounts]);

  const integrateInstagramSocialMedia = async (instagram_code) => {
    try {
      const { data } = await ApploCLI.mutate({
        mutation: getFromInstagramSocial,
        context: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem("token") || ""}` }
        },
        variables: { code: instagram_code || "" },
        errorPolicy: "all",
      });
      if (data.integration) {
        window.location.replace('/integrations');
        setStep(2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addConnection = () => {
    const FACEBOOK_APP_ID = facebook.FACEBOOK_APP_ID;
    const FACEBOOK_APP_REDIRECT_STATIC = facebook.FACEBOOK_APP_REDIRECT_STATIC;
    const connectUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${FACEBOOK_APP_REDIRECT_STATIC}&scope=ads_read`;

    let windowObjectReference = window.open(connectUrl, "", "toolbar=no,menubar=no,width=700,height=700,top=100,left=100");
    setLoading(true);

    const interval = setInterval(() => {
      try {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(interval);
        } else if (windowObjectReference.location.hostname === "app.live-metrics.io") {
          clearInterval(interval);
          const code = new URL(windowObjectReference.location.href).searchParams.get("code");
          integrateInstagramSocialMedia(code);
          windowObjectReference.close();
          setStep(2);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  };

  const deleteConnection = () => {
    ApploCLI.mutate({
      mutation: deletePlatformInstagramSocialMedia,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: 'all',
    }).then((data) => {
      if (data.delete.isDeleted)
        window.location.replace('/integrations');
      setInfo(null);
    });
  };

  // Handle toggling of accounts between active and inactive
  const handleToggle = async (instaAccountId) => {
    setLoading(true);
    try {
      const { data } = await ApploCLI.mutate({
        mutation: toggleInstaSocialMediaAccount,
        variables: {
          platform_id: info.id,
          fb_page_id: instaAccountId,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
          }
        },
        errorPolicy: 'all',
      });

      if (data?.toggle) {
        const newState = data.toggle.active;

        // Display messages based on the new state
        if (newState) {
          message.success('Account deactivated successfully!');
        } else {
          message.success('Account activated successfully!');
        }

        // Update active and inactive accounts in state immediately
        if (newState) {
          setActiveAccounts([data.toggle]);
          setInactiveAccounts(prev => prev.filter(account => account.fb_page_id !== instaAccountId));
        } else {
          setInactiveAccounts(prev => [...prev, data.toggle]);
          setActiveAccounts([]);
        }

        await fetchInstagramAccounts();
      } else {
        message.error('Failed to toggle the account. Please try again.');
      }
    } catch (err) {
      setLoading(false);
      message.error('An error occurred while toggling the account.');
      console.error('Toggle Error:', err);
    } finally {
      setLoading(false);
    }
  };


  const [filteredData, setFilteredData] = useState(inactiveAccounts);

useEffect(() => {
  setFilteredData(inactiveAccounts);
}, [inactiveAccounts]);

const handleSearchInputChange = (e) => {
  const value = e.target.value.toLowerCase();
  setSearchText(value);

  const filtered = inactiveAccounts.filter((item) =>
    item.instagram_username.toLowerCase().includes(value)
  );

  setFilteredData(filtered);
};


  // Define table columns with toggle action in the "Action" column
  const columns = [
    {
      title: 'INSTAGRAM PAGE NAME',
      dataIndex: 'instagram_username',
      key: 'instagram_username',
      render: (text) => (
        <span className='table-cell-text'>{text}</span>
  
    ),
    },
    {
      title: 'ACTION',
      key: 'action',
      width: 140, // Set a fixed width for the ACTION column
      align: 'center', // Align the content of the ACTION column to the center
      render: (text, record) => (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#5CCF5C',
              colorTextQuaternary: '#6E6893',
              opacityLoading: 1,
            },
          }}
        >
          <Switch
            checked={record.active}
            disabled={!record.active && activeAccounts.length > 0}
            onChange={() => handleToggle(record.fb_page_id)}
            loading={loading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </ConfigProvider>
      ),
    },
  ];

  const renderSections = () => {
    return (
      <div className='mt-5'>
        <Row>
          <Col>
            <Card className="border text-secondary">
              <CardHeader style={{ borderBottom: "none" }}>
                <img src={InstagramLogo} alt="Instagram Social Media" />
              </CardHeader>
              <CardBody>
                {step === 1 && (
                  <>
                    <p style={{ fontSize: '16px', fontWeight: '400' }}>
                      {t("Connect your Instagram Social Media Store within 30 seconds")}
                    </p>
                    <button onClick={addConnection} className="btn btn-primary" style={{ width: '100%', backgroundColor: "#0080F9", borderRadius: '6px', fontSize: '17px', fontWeight: '500', border: 'none' }}>
                      {t("Connect")}
                    </button>
                  </>
                )}
                {step !== 1 && (
                  <>
                    <h5 className="" style={{ marginBottom: '20px', fontWeight: '600' }}>Choose Instagram Page</h5>
                    <div className="search-box" style={{ flex: 1, maxWidth: '523px', position: 'relative', backgroundColor: '#F4F2FF', borderRadius: '6px', padding: '10px 15px' , marginBottom:"10px"}}>
                      <Input
                        placeholder={i18next.t('Convergen - P')}
                        value={searchText}
                        className="custom-search-input"
                         onChange={handleSearchInputChange}
                      />
                      <i className="bx bx-search-alt search-icon" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#7a7a7a' }} />
                    </div>
                    <Spin spinning={loading}>
                    {activeAccounts.length > 0 && (
                      <Table
                        bordered
                        columns={columns}
                        dataSource={activeAccounts}
                        rowKey="fb_page_id"
                        pagination={false}
                        style={{ marginBottom: '20px', marginTop: '20px' }}
                      />
                    )}
                      <Table
                        bordered
                        columns={columns}
                        dataSource={filteredData}
                        rowKey="fb_page_id"
                        pagination={{
                          pageSize: 5,
                          showSizeChanger: false,
                          style: {
                            backgroundColor: '#F4F2FF',
                            padding: '10px 15px',
                            marginTop: '2px',
                            borderRadius: '6px',
                            fontsize: '14px',
                            fontweight: '700',
                            color: '#6E6893',
                          },
                        }}
                        headerCellStyle={{
                          backgroundColor: '#FF0000', // Change this to your desired color
                          color: '#333333', // Text color
                        }}
                        // onRow={(record, index) => {
                        //   return {
                        //     style: {
                        //       backgroundColor: index % 2 === 0 ? 'white' : '#F4F2FF',
                        //     },
                        //   };
                        // }}
                      
                      />
                    </Spin>
                    {activeAccounts.length === 0 ? (
                      <div className='text-center'>
                        <button type="button" 
                         className="btn btn-danger waves-effect waves-light"
                         style={{
                           width: '100%',
                           backgroundColor: "#F4F2FF",
                           borderRadius: '6px',
                           fontSize: '17px',
                           fontWeight: '500',
                           border: 'none',
                           marginTop: '10px',
                           color: "#6E6893"
                         }} onClick={deleteConnection}>
                          {t("Delete Connection")}
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  return renderSections();
}

InstagramSocialMediaCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(InstagramSocialMediaCard));
