import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Container, Row, Input } from "reactstrap";  // Import Input for the dropdown
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const Chat = (props) => {
  const [messageBox, setMessageBox] = useState(null);
  const [message, setMessage] = useState("");
  const [responses, setResponses] = useState([]);
  const [socket, setSocket] = useState(null);
  const [platform, setPlatform] = useState("google"); // State to hold selected platform

  const [Chat_Box_Username, setChat_Box_Username] = useState("ConverGinie");
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online");

  const [isTyping, setIsTyping] = useState(false);
  const [botMessage, setBotMessage] = useState("");
  const [typingCompleted, setTypingCompleted] = useState(false);

  const token = localStorage.getItem("token");

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    // Passing the token and platform as query parameters in the WebSocket URL
    const ws = new WebSocket(`ws://localhost:8000/ws/chat/user_123?token=${token}&platform=${platform}`);

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (event) => {
      setIsTyping(true);
      const fullMessage = event.data;
      setBotMessage("");
      setTypingCompleted(false);

      let index = 0;
      const typingInterval = setInterval(() => {
        if (index < fullMessage.length) {
          setBotMessage((prev) => prev + fullMessage[index]);
          index++;
        } else {
          clearInterval(typingInterval);
          setIsTyping(false);
          setTypingCompleted(true);
        }
      }, 0);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [platform]);  // Re-run this effect whenever platform changes

  useEffect(() => {
    if (typingCompleted && botMessage) {
      setResponses((prev) => [...prev, { type: "bot", text: botMessage }]);
      setBotMessage("");
      setTypingCompleted(false);
    }
  }, [typingCompleted, botMessage]);

  const sendMessage = () => {
    if (socket && message.trim()) {
      socket.send(message);
      setResponses((prev) => [...prev, { type: "user", text: message }]);
      setMessage("");
    }
  };

  return (
    <React.Fragment>
      <style>
        {`
          .chat-conversation ul li {
            margin-bottom: 10px;
          }
          .chat-conversation .user-message {
            background-color: #007bff;
            color: white;
            border-radius: 15px 15px 0 15px;
            padding: 10px;
            max-width: 75%;
            text-align: right;
          }
          .chat-conversation .bot-message {
            background-color: #f1f1f1;
            color: black;
            border-radius: 15px 15px 15px 0;
            padding: 10px;
            max-width: 75%;
          }
          .chat-day-title {
            text-align: center;
            margin-bottom: 15px;
            font-weight: bold;
            color: #888;
          }
          .typing-indicator {
            font-style: italic;
            color: #888;
            margin-left: 10px;
            animation: blink 1s steps(5, start) infinite;
          }
          @keyframes blink {
            50% {
              opacity: 0;
            }
          }
        `}
      </style>

      <div className="page-content">
          <title>Chat | Live Metrics E-com SASS</title>
        <Container fluid>
          <div className="d-lg-flex">
            <div className="w-100 user-chat">
              <Card>
                <div className="p-4 border-bottom">
                  <Row>
                    <Col md="4" xs="9">
                      <h5 className="font-size-15 mb-1">{Chat_Box_Username}</h5>
                      <p className="text-muted mb-0">
                        <i
                          className={
                            Chat_Box_User_Status === "online"
                              ? "mdi mdi-circle text-success align-middle me-1"
                              : "mdi mdi-circle align-middle me-1"
                          }
                        />
                        {Chat_Box_User_Status}
                      </p>
                    </Col>
                    <Col md="4" xs="3">
                      <Input
                        type="select"
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                      >
                        <option value="google">Google Ads</option>
                        <option value="facebook">Facebook Ads</option>
                      </Input>
                    </Col>
                  </Row>
                </div>

                <PerfectScrollbar className="chat-conversation p-3 px-2" style={{ height: "600px" }} containerRef={(ref) => setMessageBox(ref)}>
                  <ul className="list-unstyled">
                    <li>
                      <div className="chat-day-title">
                        <span className="title">Today</span>
                      </div>
                    </li>
                    {responses.map((res, index) => (
                      <li key={index} className={`d-flex ${res.type === "user" ? "justify-content-end" : "justify-content-start"}`}>
                        <div className={res.type === "user" ? "user-message" : "bot-message"}>{res.text}</div>
                      </li>
                    ))}
                    {isTyping && (
                      <li className="d-flex justify-content-start">
                        <div className="bot-message">
                          <span className="typing-indicator">ConverGinie is typing...</span>
                        </div>
                      </li>
                    )}
                    {botMessage && !typingCompleted && (
                      <li className="d-flex justify-content-start">
                        <div className="bot-message">{botMessage}</div>
                      </li>
                    )}
                  </ul>
                </PerfectScrollbar>

                <div className="p-3 border-top">
                  <Row>
                    <Col>
                      <div className="position-relative">
                        <input
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          className="form-control border bg-soft-light"
                          placeholder="Enter Message..."
                        />
                      </div>
                    </Col>
                    <Col className="col-auto">
                      <Button type="button" color="primary" onClick={sendMessage} className="btn btn-primary chat-send w-md">
                        <span className="d-none d-sm-inline-block me-2">Send</span>
                        <i className="mdi mdi-send float-end" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

export default Chat;
