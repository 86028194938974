import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import GeneralOverviewCard from './GeneralOverviewCard';

import GeneralOverviewHorizontalCard from './GeneralOverviewHorizontalCard';
import {
  formatNumberWithSpaces,
  getExchangeRate,
} from '../GoogleAnalytics/common/HelperFunctions/utils';
import { logoMap } from '../../assets/integrations_images/logoMap';

function GenralOverviewCard(props) {
  const googleCost = props.data.GoogleMetrics?.[0]?.totalCost || 0;
  const facebookCost = props.data.facebookMetrics?.[0]?.totalCost || 0;

  // Revenue calculations
  const googleRevenue =
    props.data.GoogleMetrics?.[0]?.totalconversionValue || 0;
  const facebookRevenue =
    props.data.facebookMetrics?.[0]?.totalconversionValue || 0;

  //currencies
  const googleRevenueCurrency =
    props.data?.GoogleMetrics?.[0]?.revenue_currency_code;
  const googleSpendCurrency =
    props.data?.GoogleMetrics?.[0]?.spend_currency_code;
  const facebookRevenueCurrency =
    props.data?.facebookMetrics?.[0]?.revenue_currency_code;
  const facebookSpendCurrency =
    props.data?.facebookMetrics?.[0]?.spend_currency_code;
  //conversion Values
  const [googleRevenueConversionRate, setGoogleRevenueConversionRate] =
    useState(1);
  const [facebookRevenueConversionRate, setFacebookRevenueConversionRate] =
    useState(1);
  const [googleSpendConversionRate, setGoogleSpendConversionRate] = useState(1);
  const [facebookSpendConversionRate, setFacebookSpendConversionRate] =
    useState(1);

  useEffect(() => {
    const fetchConversionRates = async () => {
      try {
        const USDExchangeRate = await getExchangeRate('USD');

        const googleRevRate = USDExchangeRate[googleRevenueCurrency];

        const facebookRevRate = USDExchangeRate[facebookRevenueCurrency];

        const googleSpendRate = USDExchangeRate[googleSpendCurrency];

        const facebookSpendRate = USDExchangeRate[facebookSpendCurrency];

        setGoogleRevenueConversionRate(googleRevRate);
        setFacebookRevenueConversionRate(facebookRevRate);
        setGoogleSpendConversionRate(googleSpendRate);
        setFacebookSpendConversionRate(facebookSpendRate);
      } catch (error) {
        console.error('Failed to fetch conversion rates', error);
      }
    };
    if (
      googleRevenueCurrency &&
      facebookRevenueCurrency &&
      googleSpendCurrency &&
      facebookSpendCurrency
    )
      fetchConversionRates();
  }, [
    googleRevenueCurrency,
    facebookRevenueCurrency,
    googleSpendCurrency,
    facebookSpendCurrency,
  ]);

  let globalConversionRate;

  if (
    props.data.GoogleMetrics?.length > 0 &&
    props.data.facebookMetrics?.length > 0
  ) {
    // Case: Both Google and Facebook data are available
    const googleConversionRate = props.data.GoogleMetrics[0].totalCVR;
    const facebookConversionRate =
      (props.data.facebookMetrics[0].totalConversions /
        props.data.facebookMetrics[0].totalLandingPageViews) *
      100;
    globalConversionRate = (googleConversionRate + facebookConversionRate) / 2;
  } else if (props.data.GoogleMetrics?.length > 0) {
    // Case: Only Google data is available
    globalConversionRate = props.data.GoogleMetrics[0].totalCVR;
  } else if (props.data.facebookMetrics?.length > 0) {
    // Case: Only Facebook data is available
    globalConversionRate =
      (props.data.facebookMetrics.totalConversions /
        props.data.facebookMetrics.totalLandingPageViews) *
      100;
  } else {
    globalConversionRate = 0;
  }
  console.log('props.data', globalConversionRate);

  const cardsData = [
    {
      title: 'Average Order Value',
      data: `$${(
        (props.data?.GoogleMetrics?.[0]?.totalAOV +
          props.data.facebookMetrics?.[0]?.totalconversionValue /
            props.data.facebookMetrics?.[0]?.totalConversions) /
          2 || 0
      ).toFixed(2)}`,
    },
    {
      title: 'Conversion Rate',
      data: globalConversionRate
        ? `%${globalConversionRate.toFixed(2)}`
        : '%0.00',
    },
    {
      title: 'ROAS',
      data: `${formatNumberWithSpaces(
        (
          (googleRevenue / googleRevenueConversionRate +
            facebookRevenue / facebookRevenueConversionRate) /
            (facebookCost / facebookSpendConversionRate +
              googleCost / googleSpendConversionRate) || 0
        ).toFixed(2)
      )}`,
    },
    {
      title: 'Total Conversions',
      data: formatNumberWithSpaces(
        (
          props.data.GoogleMetrics?.[0]?.totalConversions +
            props.data.facebookMetrics?.[0]?.totalConversions || 0
        ).toFixed(2)
      ),
    },
  ];
  return (
    <div>
      <Col>
        <Row className="align-items-center">
          <Col>
            <Row className="align-items-center mb-4">
              <Col>
                <GeneralOverviewCard
                  data={[
                    {
                      value: googleCost / googleSpendConversionRate,
                      name: 'Google Ads',
                      itemStyle: { color: '#FAC858' },
                      logo: logoMap['Google Ads'],
                    },
                    {
                      value: facebookCost / facebookSpendConversionRate,
                      name: 'Facebook Ads',
                      itemStyle: { color: '#0080F9' },
                      icon: logoMap['Facebook Ads'],
                    },
                  ]}
                  title="Spend Split"
                />
              </Col>
              <Col>
                <GeneralOverviewCard
                  data={[
                    {
                      value: googleRevenue / googleRevenueConversionRate,
                      name: 'Google Ads',
                      itemStyle: { color: '#FAC858' },
                    },
                    {
                      value: facebookRevenue / facebookRevenueConversionRate,
                      name: 'Facebook Ads',
                      itemStyle: { color: '#0080F9' },
                    },
                  ]}
                  title="Revenue Split"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col xl={12} md={4}>
          <Row>
            {cardsData.map((item, index) => (
              <GeneralOverviewHorizontalCard
                key={index}
                item={item}
                t={props.t}
              />
            ))}
          </Row>
        </Col>
      </Col>
    </div>
  );
}

GenralOverviewCard.propTypes = {
  t: PropTypes.any,
  data: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(GenralOverviewCard));
