import gql from 'graphql-tag';

const getOverviewReport = gql`
  query (
    $startDate: String!
    $endDate: String!
    $compareStartDate: String
    $compareEndDate: String
  ) {
    generalreport(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      store {
        delivred_orders
        total_sales
        total_orders
        aov
        currency
      }
      GoogleMetrics {
        revenue_currency_code
        spend_currency_code
        totalClicks
        totalCost
        totalImpressions
        totalConversions
        totalconversionValue
        totalinteractions
        totalctr
        totalAOV
        totalCPC
        totalCPA
        totalROAS
        totalCVR
      }
      facebookMetrics {
        revenue_currency_code
        spend_currency_code
        totalClicks
        totalCost
        totalImpressions
        totalConversions
        totalconversionValue
        totalLandingPageViews
        totalCpc
        totalCtr
        totalReach
      }
    }
  }
`;

const getStoreCurrency = gql`
  query {
    getStoreCurrency {
      currency
    }
  }
`;
export { getOverviewReport, getStoreCurrency };
