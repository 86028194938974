import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useCallback,
} from 'react';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import SideBarContentElement from './SideBarContentElement';








const SidebarContent = (props) => {
  const ref = useRef();

  const sideBarContent = [
    { name: 'Overview', route: '/overview-dashboard' },
    {
      name: 'Reports',
      children: [
        { name: 'Miscellaneous Costs', route: '/costs' },
        { name: 'Operations', route: '/reports-opertations' },
        { name: 'Strategic', route: '/reports-strategic' },
        { name: 'Profit & Loss', route: '/reports-pnl' },
      ],
    },
    {
      name: 'PrestaShop',
      children: [
        { name: 'Presta Dashboard', route: '/prestashop-dashboard' },
        { name: 'Products', route: '/prestashop-all-products' },
      ],
    },
    {
      name: 'WooCommerce',
      children: [
        { name: 'Woo Dashboard', route: '/woo_dashboard' },
        { name: 'Coupons Codes', route: '/woo_coupons' },
        { name: 'Top Sellers', route: '/woo_top_sellers' },
      ],
    },
    {
      name: 'Google Ads',
      children: [
        { name: 'Google Overview', route: '/google-dashboard' },
        { name: 'Campaigns', route: '/google-campaigns-details' },
        { name: 'Ads', route: '/google-ads-details' },
        { name: 'Keywords Performance', route: '/google-keywords-details' },
      ],
    },
    {
      name: 'Google Analytics',
      children: [
        {
          name: 'Google Analytics Overview',
          route: '/google-analytics',
        },
        { name: 'Users', route: '/google-analytics-users' },
        {
          name: 'Ecommerce Affiliation',
          route: '/google-analytics-ecommerce-affiliation',
        },
      ],
    },
    {
      name: 'Meta Ads',
      children: [
        { name: 'Meta Overview', route: '/ads-dashboard' },
        { name: 'Campaigns', route: '/fb-campaigns' },
        { name: 'Ads', route: '/MetaAds' },
      ],
    },

    {
      name: 'Social Media',
      children: [
        {
          name: 'Facebook Social Media Overview',
          route: '/fb-social-media-dashboard',
        },
        {
          name: 'Instagram Social Media Overview',
          route: '/InstagramDashboard',
        },
      ],
    },
    {
      name: 'Aramex',
      route: '/Aramex-Dashboard',
    },
    {
      name: 'Email Builder',
      route: '/email-builder',
    },
    {
      name: 'Integrations',
      route: '/integrations',
    },
    {
      name: 'Advisor',
      route: '/advisor',
    },
  
  ];

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    scrollElement(item);
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
 

  return (
    <React.Fragment>
    

      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sideBarContent.map((item) =>
              SideBarContentElement((item = { item }))
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(SidebarContent));
