

// Operations.js
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { getOperationDataRequest } from '../../../store/reports/operations/action';
import { formatDate } from '../../GoogleAnalytics/common/HelperFunctions/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Space,
} from "antd";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import OperationsChart from '../ReportsChart'; 
import NumberFormat from 'react-number-format';
import secureSection from "../../../MangeSecurity/MemberSecurity";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CostsIcon from "../../../assets/images/CostsIcon.svg";
import ExportIcon from "../../../assets/images/ExportIcon.svg";
import PropTypes from 'prop-types';
import OperationsTable from '../ReportsTable'; 

function Operations(props) {
  const dispatch = useDispatch();
  const { ops, series, sales, avg, daily } = useSelector((state) => state.operation);
  secureSection();
  const currency = window.localStorage.getItem("currency");

  const headers = [
    { label: props.t("Date"), key: "created" },
    { label: props.t("Sales"), key: "sumTotal" },
    { label: props.t("Gross Rev"), key: "grossRev" },
    { label: props.t("Ad Cost"), key: "adCost" },
    { label: props.t("Cost Of Goods"), key: "costGoods" },
    { label: props.t("Shipping"), key: "shippingCost" },
    { label: props.t("S&H"), key: "variantHandling" },
    { label: props.t("Merchant Fee"), key: "merchantFee" },
    { label: props.t("Refunds"), key: "refunds" },
    { label: props.t("Discount"), key: "discount" },
    { label: props.t("Tax"), key: "tax" },
    { label: props.t("Net Income"), key: "netProfit" },
    { label: props.t("Orders"), key: "numOrders" },
    { label: props.t("Units"), key: "sumQte" },
  ];

  const [loadingState, setLoadingState] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const onExport = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // Adjust as needed
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("report.pdf");
    });
  };

  const [channel, setChannel] = useState("all");
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
  const newStartDate = formatDate(dateRange.selection1.startDate);
  const newEndDate = formatDate(dateRange.selection1.endDate);

  const getData = () => {
    setLoadingState(true);
    dispatch(getOperationDataRequest(newStartDate, newEndDate, channel));
  };

  useEffect(() => {
    getData(channel);
  }, [dateRange, channel]);

  // Prepare metric data for the chart
  const metricData = {
    name: "Sales",
    data: sales || [],
  };

  const colors = {
    lineColor: "#556ee6",
    fillColor: [
      { offset: 0, color: 'rgba(85, 110, 230, 0.2)' },
      { offset: 1, color: 'rgba(85, 110, 230, 0)' }
    ],
  };

  // Define the columns here
  const columns = [
    {
      title: props.t('DATE'),
      dataIndex: 'created',
      key: 'created',
      render: (text) => {
        const date = new Date(text);
        const options = { month: 'short', day: '2-digit' };
        return (
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            {date.toLocaleDateString('en-US', options).replace(',', '')}
          </span>
        );
      },
    },
    {
      title: props.t('TOTAL REVENUE'),
      dataIndex: 'sumTotal',
      key: 'sumTotal',
      width: '9%',
      render: (text) => (
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('GROSS REVENUE'), 
      dataIndex: 'grossRev',
      key: 'grossRev',
      width: '9%',
      render: (text) => (
        <span style={{
          display: 'inline-block',
          height: '25px',
          width: '100%',
          fontSize: '16px',
          fontWeight: '500',
          color: text < 0 ? '#D30000' : '#007F00',
          backgroundColor: text < 0 ? '#FFE0E0' : '#CDFFCD',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
          <NumberFormat 
            value={text} 
            displayType={'text'} 
            thousandSeparator={true} 
            prefix={"TND"} 
            decimalScale={2} 
          />
        </span>
      ),
    },
    {
      title: props.t('AD COST'),
      dataIndex: 'adCost',
      key: 'adCost',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('COST OF GOODS'),
      dataIndex: 'costGoods',
      key: 'costGoods',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('SHIPPING'),
      dataIndex: 'shippingCost',
      key: 'shippingCost',
      width: '8%',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('S&H'),
      dataIndex: 'variantHandling',
      key: 'variantHandling',
      width: '8%',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('MERCHANT FEE'),
      dataIndex: 'merchantFee',
      key: 'merchantFee',
      width: '8%',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('REFUNDS'),
      dataIndex: 'refunds',
      key: 'refunds',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('DISCOUNT'),
      dataIndex: 'discount',
      key: 'discount',
      width: '8%',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('TAX'),
      dataIndex: 'tax',
      key: 'tax',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('NET INCOME'),
      dataIndex: 'netProfit',
      key: 'netProfit',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          <NumberFormat value={text} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={2} />
        </span>
      ),
    },
    {
      title: props.t('ORDERS'),
      dataIndex: 'numOrders',
      key: 'numOrders',
      width: '6%',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          {text}
        </span>
      ),
    },
    {
      title: props.t('UNITS'),
      dataIndex: 'sumQte',
      key: 'sumQte',
      width: '6%',
      render: (text) => (
        <span style={{ fontSize: '15px', fontWeight: '500' }}>
          {text}
        </span>
      ),
    }, 
   ];

  return (
    <div className="page-content">
        <title>{props.t("Reports operations")}</title>
      <Breadcrumbs
        title={props.t('Costs')}
        breadcrumbItem={props.t('Miscellaneous Costs')}
        pageHeading={props.t('Reports')}
        image={CostsIcon}
      />
      <Row>
        <Row style={{ marginTop: '15px' }}>
          <Col xs={8}>
            <span style={{ fontWeight: "300", fontSize: '15px', color: '#9291A5' }}>{props.t("Total Revenue")}</span>
            {loadingState ? (
              <div>
                <NumberFormat value={avg} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={3} style={{ fontWeight: "bold", fontSize: '36px', color: 'black' }} />
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </Col>

          <Col xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Space>
              <Button type="primary" onClick={() => setModalVisible(true)} style={{ width: '212px', height: '53px', fontSize: '15px' }}>
                <img src={ExportIcon} alt="Export" style={{ marginRight: 10 }} />
                {props.t('Export PDF')}
              </Button>
              <Modal
                title="Export Options"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
              >
                <Button onClick={onExport} style={{ marginRight: 10 }}>
                  Export PDF
                </Button>
                <CSVLink
                  filename={`${new Date().toLocaleDateString()}_Strategic.csv`}
                  data={ops || []}
                  headers={headers}
                >
                  <Button>Export CSV</Button>
                </CSVLink>
              </Modal>
            </Space>
          </Col>
        </Row>

        <OperationsChart metricData={metricData} colors={colors} />

      </Row>
      <Row style={{ marginTop: '30px' }}>
        <span style={{ fontWeight: "300", fontSize: '15px', color: '#9291A5' }}>{props.t("Daily Average")} </span>
        {loadingState ? (
          <NumberFormat value={daily} displayType={'text'} thousandSeparator={true} prefix={"TND"} decimalScale={3} style={{ fontWeight: "bold", fontSize: '36px', color: 'black' }} />
        ) : (
          <div>Loading...</div>
        )}
      </Row>
      
      <Row id="pdf-content" style={{ marginTop: '50px' }}>
        <OperationsTable columns={columns} data={ops} />
      </Row>
    </div>
  );
}

Operations.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Operations);
