import React, { useState } from 'react';
import { Card, Col, Row, Dropdown, Menu, Button } from 'antd';
import { DownOutlined, PlusSquareOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import DeleteableMetricCard from './DeleteableMetricCard';

const CustomisableMetricCards = ({ metricsOption }) => {
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleSelectGroup = (selectedKey) => {
    const selectedOption = metricsOption.find(
      (option) => option.key === selectedKey
    );
    if (
      selectedOption &&
      !selectedGroups.find((group) => group.key === selectedKey)
    ) {
      setSelectedGroups((prevGroups) => [...prevGroups, selectedOption]);
    }
  };

  // Create menu items for the dropdown
  const menu = (
    <Menu onClick={(e) => handleSelectGroup(e.key)}>
      {metricsOption.map((option) => (
        <Menu.Item key={option.key}>{option.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Row className="justify-content-center gap-3 mb-4">
      {selectedGroups.map((selectedGroup, index) => (
        <DeleteableMetricCard
          item={selectedGroup}
          cardIndex={index}
          setSelectedGroups={setSelectedGroups}
        />
      ))}

      {/* Customize your metrics card */}
      <Col xl={4}>
        <Card
          
          style={{
            border: '1px dashed #D9D5EC', // Dashed border like in the image
            borderRadius: '3px',
            height: '100% ', // Rounded corners
          }}
        >
          <div className="my-0 d-flex flex-column align-items-center gap-3">
            {/* Title styling */}
            <span
              className="mb-2"
              style={{
                color: '#0044BD',
                fontWeight: '700',
                fontSize: '13px',
                fontFamily: 'DM Sans , sans-serif',
              }}
            >
              {i18next.t('Customize your metrics')}
            </span>

            {/* Dropdown Select */}
            <Dropdown overlay={menu} trigger={['click']}>
              <Button
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: '100%',
                  backgroundColor: '#E9E9EF', // Light gray background for dropdown
                  fontWeight: 500,
                  fontFamily: 'DM Sans , sans-serif',
                }}
              >
                <PlusSquareOutlined /> {i18next.t('Select metrics')}{' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomisableMetricCards;
