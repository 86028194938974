import React, { useState, useEffect, useRef } from 'react';
import {
  Space,
  Button,
  Dropdown,
  Menu,
  Table,
  Input,
  Checkbox,
} from 'antd';
import {
  CardBody,
  Card,
} from 'reactstrap';
import { format } from 'date-fns';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdsFacebook,
} from '../../store/facebookAds/actions';
import secureSection from '../../MangeSecurity/MemberSecurity';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';

const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

function AdsFacebook(props) {
  secureSection();
  const dispatch = useDispatch();
  const { ads } = useSelector((state) => state.facebookAds);
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: dateRange.selection1.key,
    },
  };

  useEffect(() => {
    dispatch(
      getAdsFacebook(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [dateRange]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5,
    pageSizeOptions: ['5', '10', '20'],
  });
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };

  const columns = [
    {
      title: props.t('Ad Image'),
      dataIndex: 'image_url',
      key: 'image_url',
      align: 'center',
      width: 180 ,
      filteredValue: filteredInfo.image_url || null,
      fixed: 'left',
      render: (text, record) => {
        return (
          <div className="d-flex align-items-center">
            <img
              src={text}
              alt="Ad Image"
              style={{ width: '130', height: '150px' }}
            />
          </div>
        );
      },
    },
    {
      title: props.t('Ad Name'),
      dataIndex: 'ad_name',
      key: 'ad_name',
      align: 'center',
      width: 180,
      fixed: 'left',
      filteredValue: filteredInfo.ad_name || null,
      onFilter: (value, record) => record.ad_name.includes(value),
      ...getColumnSearchProps('ad_name'),
    },
    {
      title: props.t('Campaign Name'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 180,
      fixed: 'left',
      filteredValue: filteredInfo.campaign_name || null,
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
    },
    {
      title: props.t('Spend'),
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      category: 'performance',
      width: 100,
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('Revenue'),
      dataIndex: 'action_values',
      key: 'action_values',
      category: 'performance',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
      width: 100,
      align: 'center',
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t('Conversions'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      category: 'performance',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      width: 120,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Impressions'),
      dataIndex: 'impressions',
      key: 'impressions',
      category: 'performance',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      width: 120,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('Clicks'),
      dataIndex: 'clicks',
      key: 'Clicks',
      category: 'performance',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      width: 100,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('Reach'),
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      category: 'performance',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      align: 'center',
      key: 'ctr',
      category: 'performance',
      sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      width: 100,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return (
          <strong>
            {isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}
          </strong>
        );
      },
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      category: 'performance',
      align: 'center',
      sorter: (a, b) => a.cpc - b.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      width: 100,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return (
          <strong>
            {isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}
          </strong>
        );
      },
    },
    {
      title: props.t('Landing PageView'),
      dataIndex: 'landing_page_views',
      align: 'center',
      key: 'landing_page_views',
      category: 'performance',
      sorter: (a, b) => a.landing_page_views - b.landing_page_views,
      sortOrder:
        sortedInfo.columnKey === 'landing_page_views' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Post engagement'),
      dataIndex: 'post_engagement',
      align: 'center',
      key: 'post_engagement',
      category: 'engagement',
      sorter: (a, b) => a.post_engagement - b.post_engagement,
      sortOrder:
        sortedInfo.columnKey === 'post_engagement' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Post reaction'),
      dataIndex: 'post_reaction',
      align: 'center',
      key: 'post_reaction',
      category: 'engagement',
      sorter: (a, b) => a.post_reaction - b.post_reaction,
      sortOrder:
        sortedInfo.columnKey === 'post_reaction' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Page engagement'),
      dataIndex: 'page_engagement',
      align: 'center',
      key: 'page_engagement',
      category: 'engagement',
      sorter: (a, b) => a.page_engagement - b.page_engagement,
      sortOrder:
        sortedInfo.columnKey === 'page_engagement' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Engagement Rate'),
      dataIndex: 'engagement_rate',
      align: 'center',
      key: 'engagement_rate',
      category: 'engagement',
      sorter: (a, b) =>
        (a.post_engagement / a.impressions) * 100 -
        (b.post_engagement / b.impressions) * 100,
      sortOrder:
        sortedInfo.columnKey === 'engagement_rate' ? sortedInfo.order : null,
      width: 120,
      render: (_, record) => {
        const engagementRate =
          (record.post_engagement / record.impressions) * 100;
        return (
          <strong>{engagementRate ? engagementRate.toFixed(2) : '-'}</strong>
        );
      },
    },
    {
      title: 'Roas',
      dataIndex: 'roas',
      align: 'center',
      key: 'roas',
      category: 'performance',
      sorter: (a, b) => a.action_values / a.spend - b.action_values / b.spend,
      sortOrder: sortedInfo.columnKey === 'roas' ? sortedInfo.order : null,
      width: 100,
      render: (_, record) => {
        const roas = record.action_values / record.spend;
        return <strong>{roas !== null ? roas.toFixed(2) : '-'}</strong>;
      },
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  // Separate fixed columns from non-fixed columns
const fixedColumns = columns.filter(column => column.fixed);
const nonFixedColumns = columns.filter(column => !column.fixed);

// Apply filters to non-fixed columns
let filteredNonFixedColumns = nonFixedColumns.filter(column =>
  selectedColumns.includes(column.key)
);

if (selectedCategory !== 'all') {
  filteredNonFixedColumns = filteredNonFixedColumns.filter(
    (column) => column.category === selectedCategory
  );
}

// Combine filtered non-fixed columns with fixed columns
const filteredColumns = [...fixedColumns, ...filteredNonFixedColumns];

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const categoryMenu = (
    <Menu onClick={(e) => setSelectedCategory(e.key)}>
      <Menu.Item key="all">All</Menu.Item>
      <Menu.Item key="performance">Performance</Menu.Item>
      <Menu.Item key="engagement">Engagement</Menu.Item>
    </Menu>
  );

  return (
    <div className="page-content">
        <title>
          {props.t(' Meta Campaigns Data')} | Convergen-Metrics - E-com SaaS
        </title>
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t('Meta Ads campaigns')}
          breadcrumbItem={props.t('Meta Ads Dashboard')}
          pageHeading={props.t('Ads Dashboard')}
          image={FacebbokAdsIcon}
        />
        <Card>
          <Card style={{ border: 'none' }}>
            <CardBody>
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Button onClick={setAgeSort}>{props.t('Sort Impressions')}</Button>
                <Button onClick={clearFilters}>{props.t('Clear filters')}</Button>
                <Button onClick={clearAll}>{props.t('Clear filters and sorters')}</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button>{props.t('Select Columns')}</Button>
                </Dropdown>
                <Dropdown overlay={categoryMenu} trigger={['click']}>
                  <Button>{props.t('Select Category')}</Button>
                </Dropdown>
              </Space>
              <Table
                columns={filteredColumns}
                bordered
                dataSource={ads}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true,
                }}
                scroll={{ x: 900, y: 600 }}
              />
            </CardBody>
          </Card>
        </Card>
      </div>
    </div>
  );
}

AdsFacebook.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AdsFacebook));