import React from 'react';
import { Redirect } from 'react-router-dom';
import PageMaintenance from '../pages/Utility/PageMaintenance';
import Error404 from '../pages/Utility/Error404';
import Error500 from '../pages/Utility/Error500';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import Members from '../pages/Accounts/Members/Members';
import PrestaShopDashboard from '../pages/PrestaDashbaord';
import AllProducts from '../pages/PrestaDashbaord/Products/AllProducts';
import Advisor from '../pages/Advisor/Advisor';
import AllOrders from '../pages/PrestaDashbaord/Orders/AllOrders';
import Integrationns from '../pages/Integrations';
import Socials from '../pages/Socials/Socials';
import Operations from '../pages/Reports/Operations';
import Strategic from '../pages/Reports/Strategic';
import NProfitandLossTable from '../pages/Reports/NProfitAndLoss';
import Costs from '../pages/Costs/Costs';
import GoogleDashboard from '../pages/GoogleDashboard/Google Ads Overview/index';
import WooDashboard from '../pages/WooDashbaord';
import GaUsers from '../pages/GoogleAnalytics/gaUsers/GAUsers';
import GAAudience from '../pages/GoogleAnalytics/gaAudience';
import gaEcommerceAffiliations from '../pages/GoogleAnalytics/gaEcommerceAffiliations';
import OverviewDashboard from '../pages/OverviewDashboard';
import WooCoupons from '../pages/WooDashbaord/WooCoupons/wooCoupons';
import WooTopSellers from '../pages/WooDashbaord/WooTopSellers/WooTopSellers';
import AramexTracking from '../pages/AramexDashboard/index';
import Index from '../pages/EmailBuilder/EmailEditor';
import CampaignsDetails from '../pages/Socials/CampaignsDetails';
import GoogleCampaignsDetails from '../pages/GoogleDashboard/googleCampaigns Details/index';
import Adstable from '../pages/GoogleDashboard/adTable';
import Campaignstable from '../pages/GoogleDashboard/campaignTable';
import Keywordstable from '../pages/GoogleDashboard/keywordsTable';
import fbcampaigns from '../pages/Socials/campaignTable';
import FbSocialMediaDashboard from '../pages/facebookSocialMediaDashboard/fbSocialMediaDashboard';
import InstagramDashboard from '../pages/InstagramDashboard/InstagrammDashboard';
import MetaAds from '../pages/Socials/MetaAds';
import TwoStepVerification from '../pages/Authentication/TwoStepVerification';
import GAOverview from '../pages/GoogleAnalytics/gaOverview/index';
import Settings from '../pages/Accounts/Settings';  
import GoogleAnalyticsReportDetail from '../pages/GoogleAnalytics/gaOverview/GoogleAnalyticsReportDetail';
// import { Settings } from 'react-feather';

const userRoutes = [
  //Integrations
  { path: '/integrations', component: Integrationns },
  //Settings
  { path: '/settings', component: Settings},
  { path: '/settings-members', component: Members },

  //Ads Dashboard
  { path: '/ads-dashboard', component: Socials },
  //PrestaShop Dashboards
  { path: '/prestashop-dashboard', component: PrestaShopDashboard },
  { path: '/prestashop-all-products', component: AllProducts },
  { path: '/prestashop-all-orders', component: AllOrders },
  //Advisor
  { path: '/advisor', component: Advisor },
  //WooCommerce Dashboard
  { path: '/woo_dashboard', component: WooDashboard },
  { path: '/woo_coupons', component: WooCoupons },
  { path: '/woo_top_sellers', component: WooTopSellers },
  // { path: '/woo_orders_status', component: WooOrdersStatus },
  // { path: '/woo_product_category', component: WooProductCategory },
  //Klaviyo Dashboard
  { path: '/Aramex-Dashboard', component: AramexTracking },
  //Reports
  { path: '/reports-opertations', component: Operations },
  { path: '/reports-strategic', component: Strategic },
  { path: '/reports-pnl', component: NProfitandLossTable },

  //Google Analytics
  
  { path: '/google-analytics-users', component: GaUsers },
  { path: '/google-analytics-audience', component: GAAudience },
 
  {
    path: '/GoogleAnalyticsReportDetail',
    component: GoogleAnalyticsReportDetail,
  },
 
  {
    path: '/google-analytics-ecommerce-affiliation',
    component: gaEcommerceAffiliations,
  },
  { path: '/google-analytics', component: GAOverview },
  //Overview Dashboard
  { path: '/overview-dashboard', component: OverviewDashboard },
  //Costs
  { path: '/costs', component: Costs },
  // Google Dashboard
  { path: '/google-dashboard', component: GoogleDashboard },
  { path: '/GoogleCampaignsDetails', component: GoogleCampaignsDetails },
  // Email Builder
  { path: '/email-builder', component: Index },
  // facebook ads campaigns
  { path: '/fb-campaigns', component: fbcampaigns },
  { path: '/fb-campaigns-details', component: CampaignsDetails },
  

  // google ads campaigns data
  { path: '/google-campaigns-details', component: Campaignstable },
  // google ads ads data
  { path: '/google-ads-details', component: Adstable },
  // google ads keywords data
  { path: '/google-keywords-details', component: Keywordstable },
  // facebook social media dashboard s
  { path: '/fb-social-media-dashboard', component: FbSocialMediaDashboard },
  //
  { path: '/InstagramDashboard', component: InstagramDashboard },
  //
  { path: '/MetaAds', component: MetaAds },

  // this route should be at the top ( first one to display) of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/integrations" /> },
];
const authRoutes = [
  //authencation page
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/verif/:id/:verifCode', component: TwoStepVerification },

  //Utility page
  { path: '/pages-maintenance', component: PageMaintenance },
  { path: '/pages-404', component: Error404 },
  { path: '/pages-500', component: Error500 },
];
export { userRoutes, authRoutes };
