import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import secureSection from '../../MangeSecurity/MemberSecurity';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFacebookCampaignsPerDate } from '../../store/facebookAds/actions';
import AdTableSingleSearch from '../GoogleDashboard/AdTableSingleSearch';
import { formatNumberWithSpaces } from '../GoogleAnalytics/common/HelperFunctions/utils';
import i18next from 'i18next';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { logoMap } from '../../assets/integrations_images/logoMap';
function Campaignstable({ overview = false }) {
  secureSection();
  const dispatch = useDispatch();
  const { metrcisbyCompaign, isLoading } = useSelector(
    (state) => state.facebookAds
  );
  const { dateRange } = useSelector((state) => state.dateRangeReducer);

  const formattedDateRange = {
    selection1: {
      startDate: format(new Date(dateRange.selection1.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.selection1.endDate), 'yyyy-MM-dd'),
      key: dateRange.selection1.key,
    },
  };

  useEffect(() => {
    dispatch(
      getFacebookCampaignsPerDate(
        formattedDateRange.selection1.startDate,
        formattedDateRange.selection1.endDate
      )
    );
  }, [dateRange]);

  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      title: i18next.t('STATUS'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (status) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: status === 'ACTIVE' ? '#007F00' : 'grey',
            backgroundColor: status === 'ACTIVE' ? '#CDFFCD' : '#fff0f6',
            fontWeight: status === 'ACTIVE' ? 'bold' : 'normal',
            opacity: status === 'ACTIVE' ? 1 : 0.6,
            borderRadius: '12.67px',
          }}
        >
          <span
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: status === 'ACTIVE' ? 'green' : 'grey',
              marginRight: '8px',
            }}
          ></span>
          {status === 'ACTIVE' ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      title: i18next.t('NAME'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 200,
      render: (text) => <span className="table-cell-text">{text}</span>,
    },
    {
      title: i18next.t('SPEND'),
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">{text.toFixed(2)}$</strong>
      ),
    },
    {
      title: i18next.t('REVENUE'),
      dataIndex: 'action_values',
      key: 'action_values',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => (
        <strong className="table-cell-text">{text.toFixed(2)}$</strong>
      ),
    },
    {
      title: i18next.t('CONVERSIONS'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong className="table-cell-text">{text}</strong>,
      width: 150,
    },
    {
      title: i18next.t('IMPRESSIONS'),
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
      width: 150,
    },
    {
      title: i18next.t('CLICKS'),
      dataIndex: 'clicks',
      key: 'Clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
      width: 100,
    },
    {
      title: i18next.t('REACH'),
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">
          {formatNumberWithSpaces(text)}
        </strong>
      ),
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const ctrValue = (record.clicks / record.impressions) * 100;
        return (
          <strong className="table-cell-text">
            {isNaN(ctrValue) ? '0' : ctrValue.toFixed(2)}%
          </strong>
        );
      },
    },
    {
      title: 'CPA',
      dataIndex: 'cpa',
      align: 'center',
      key: 'cpa',
      sorter: (a, b) => a.cpa - b.cpa,
      sortOrder: sortedInfo.columnKey === 'cpa' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => (
        <strong className="table-cell-text">{text.toFixed(2)}$</strong>
      ),
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      sorter: (a, b) => a.cpc - b.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return (
          <strong className="table-cell-text">
            {isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}
          </strong>
        );
      },
    },
  ];
  return (
    <>
        <title>
          {i18next.t(' Meta Campaigns Data')} | Convergen-Metrics - E-com SaaS
        </title>
      <div className="page-content">
        <Breadcrumb
          image={logoMap['Facebook Ads']}
          title="Facebook Ads"
          
        />
        <AdTableSingleSearch
          overview={overview}
          columns={columns}
          data={metrcisbyCompaign}
          setSortedInfo={setSortedInfo}
          sortedInfo={sortedInfo}
          loading={isLoading}
          statusMapping={{
            ENABLED: 'ACTIVE',
            PAUSED: 'PAUSED',
          }}
        />
      </div>
    </>
  );
}

Campaignstable.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Campaignstable));
