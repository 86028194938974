export const facebook = {
  FACEBOOK_APP_ID: "1188499042109576",
  FACEBOOK_APP_REDIRECT_STATIC: "https://app.live-metrics.io/integrations",
};
export const google = {
  GOOGLE_CLIENT_ID: "109830448513-0uifdqhirlplbn24h27efitd4fkoap7m.apps.googleusercontent.com",
 
};

export const  URL_Setup ={
  URL: "app.live-metrics.io",
  IP:"149.202.92.140",
}