import gql from 'graphql-tag';

const generateUrl = gql`
  query {
    url {
      url
    }
  }
`;
const getGooglePlatform = gql`
  query {
    get {
      id
      name
      google_id
      type
      active
    }
  }
`;
const getAccounts = gql`
  query {
    accounts {
      id
      account_id
      selfLink
      name
      active
    }
  }
`;

const getProfileAccounts = gql`
  query {
    profileaccounts {
      id
      account_id
      web_prop_id
      profile_id
      name
      active
    }
  }
`;

const integrate = gql`
  mutation ($code: String!) {
    integration(code: $code) {
      name
      createdAt
    }
  }
`;

const toggleGoogle = gql`
  mutation ($adAccount: String!) {
    toggle(adAccount: $adAccount) {
      active
      name
    }
  }
`;
const toggleGoogleProfile = gql`
  mutation ($adAccount: String!) {
    toggleprofiles(adAccount: $adAccount) {
      active
    }
  }
`;
// WE DONT NEED IT ANYMORE

const deleteGoogle = gql`
  mutation {
    delete {
      isDeleted
    }
  }
`;
const getGABrowsers = gql`
  query {
    browsers {
      salesPlatform
      profileId
      data
    }
  }
`;

const getGATrackingPage = gql`
  query {
    trackingpage {
      salesPlatform
      profileId
      entranceRate
      pageviews
      pageValue
      pageviewsPerSession
      timeOnPage
      exits
      exitRate
    }
  }
`;
const getGAUsers = gql`
  query getGAUsers(
    $startDate: String
    $endDate: String
    $compareStartDate: String
    $compareEndDate: String
  ) {
    gausers(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      salesPlatform
      profileId
      userEngagementDuration
      screenPageViewsPerUser
      users
      newUsers
      day
      newUsersByCountry {
        countryId
        newUsers
      }
    }
  }
`;

const getGAAudience = gql`
  query getGAAudience(
    $startDate: String
    $endDate: String
    $compareStartDate: String
    $compareEndDate: String
  ) {
    gaaudiences(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      salesPlatform
      profileId
      userAgeBracket
      userGender
      interestOtherCategory
      interestAffinityCategory
    }
  }
`;

const getSoldProducts = gql`
  query getSoldProducts($startDate: String, $endDate: String) {
    gaSoldProducts(startDate: $startDate, endDate: $endDate) {
      salesPlatform
      products {
        itemCategory
        itemName
        itemRevenue
        itemsPurchased
      }

      day
    }
  }
`;

const getGAEventCategoryTracking = gql`
  query {
    gaeventcategorytracking {
      salesPlatform
      profileId
      totalEvents
      eventValue
      avgValue
      sessionsWithEvent
      eventsPerSessionWithEvent
    }
  }
`;

const getGAEventActionTracking = gql`
  query {
    gaeventactiontracking {
      salesPlatform
      profileId
      totalEvents
      eventValue
      avgValue
      sessionsWithEvent
      eventsPerSessionWithEvent
    }
  }
`;
const getGAAdWordAdGroup = gql`
  query {
    gaadgroupwords {
      salesPlatform
      profileId
      impressions
      clicks
      cost
      cpm
      cpc
      ctr
      costPerTransaction
      costPerGoalConversion
      costPerConversion
      rpc
      roas
    }
  }
`;

const getGAEcommerceAffiliation = gql`
  query getGAEcommerceAffiliation(
    $startDate: String
    $endDate: String
    $compareStartDate: String
    $compareEndDate: String
  ) {
    gaecommerceaffiliations(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      salesPlatform
      accountId
      ecommercePurchases
      purchaserRate
      purchaseRevenue
      averagePurchaseRevenue
      averagePurchaseRevenuePerPayingUser
      day
      createdAt
      updatedAt
      deletedAt
      isDeleted
    }
  }
`;

const getGAPlatform = gql`
  query getGAPlatform(
    $startDate: String
    $endDate: String
    $compareStartDate: String
    $compareEndDate: String
  ) {
    gaPlatform(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      deviceCategory {
        deviceCategory
        totalUsers
      }
    }
  }
`;

const getGAPurchaseJourney = gql`
  query getGAPurchaseJourney(
    $startDate: String
    $endDate: String
    $compareStartDate: String
    $compareEndDate: String
  ) {
    gaPurchaseJourney(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      sessionStart
      viewProducts
      addToCart
      beginCheckout
      purchase
      day
    }
  }
`;

const getGATrafficAcquisition = gql`
  query getGATrafficAcquisition(
    $startDate: String
    $endDate: String
    $compareStartDate: String
    $compareEndDate: String
  ) {
    gaTrafficAcquisition(
      startDate: $startDate
      endDate: $endDate
      compareStartDate: $compareStartDate
      compareEndDate: $compareEndDate
    ) {
      salesPlatform
      accountId
      day
      # activeUsers {
      #   sessionPrimaryChannelGroup
      #   value
      # }
      # sessions {
      #   sessionPrimaryChannelGroup
      #   value
      # }
      # engagedSessions {
      #   sessionPrimaryChannelGroup
      #   value
      # }
      # averageSessionDuration {
      #   sessionPrimaryChannelGroup
      #   value
      # }
      # keyEvents {
      #   sessionPrimaryChannelGroup
      #   value
      # }
      totalRevenue {
        sessionPrimaryChannelGroup
        value
      }
    }
  }
`;

export {
  generateUrl,
  getGooglePlatform,
  integrate,
  getAccounts,
  toggleGoogle,
  deleteGoogle,
  getProfileAccounts,
  getGABrowsers,
  getGATrackingPage,
  getGAUsers,
  getGAAudience,
  getGAEventCategoryTracking,
  getGAEventActionTracking,
  getGAAdWordAdGroup,
  getGAEcommerceAffiliation,
  getGAPlatform,
  toggleGoogleProfile,
  getSoldProducts,
  getGATrafficAcquisition,
  getGAPurchaseJourney,
};
