// WooDataCard.js
import React from "react";
import { Row } from "reactstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import WooMetricCard from './WooMetricCard';
import i18next from 'i18next';

function WooDataCards({ metrics }) {
  const { dateRange } = useSelector((state) => state.dateRangeReducer);
console.log(metrics)
  const calculatePercentageChange = (sales, compareSales) => {
    const totalSales = getTotal(sales);
    const totalCompareSales = getTotal(compareSales);
    if (totalCompareSales === 0) return totalSales === 0 ? "0" : "100";
    return (((totalSales - totalCompareSales) / totalCompareSales) * 100).toFixed(2);
  };

  const getTotal = (array) => array.reduce((sum, value) => sum + Number(value), 0);
  const getTotalFixed = (array, decimal = 2) => getTotal(array).toFixed(decimal);
  const totalAOV = (getTotal(metrics.sales) / getTotal(metrics.NumberOrders)).toFixed(2);
// const ComparetotalAOV = metrics.compareNumberOrders.length > 0 
//   ? (getTotal(metrics.compareSales) / getTotal(metrics.compareNumberOrders)).toFixed(2) 
//   : "0.00";

  //calcuate AOV  

  const percentageChangeSales = calculatePercentageChange(metrics.sales, metrics.compareSales);
  const percentageChangeGrossProfit = calculatePercentageChange(metrics.GrossProfit, metrics.compareGrossProfit);
  const percentageChangeAOV = calculatePercentageChange( metrics.AOV,metrics.compareAOV);
  const percentageChangeNumberOrders = calculatePercentageChange(metrics.NumberOrders, metrics.compareNumberOrders);

  return (
    <Row>
      <WooMetricCard
        title={i18next.t("Sales")}
        value={getTotalFixed(metrics.sales)}
        percentageChange={percentageChangeSales}
        seriesData={metrics.sales}
        compareData={metrics.compareSales}
      />
      <WooMetricCard
        title={i18next.t("Gross Profit")}
        value={getTotalFixed(metrics.GrossProfit)}
        percentageChange={percentageChangeGrossProfit}
        seriesData={metrics.GrossProfit}
        compareData={metrics.compareGrossProfit}
      />
      <WooMetricCard
        title={i18next.t("AOV")}
        value={getTotalFixed(metrics.AOV)}
        percentageChange={percentageChangeAOV}
        seriesData={metrics.AOV}
        compareData={metrics.compareAOV}
      />
      <WooMetricCard
        title={i18next.t("Number of Orders")}
        value={getTotalFixed(metrics.NumberOrders)}
        percentageChange={percentageChangeNumberOrders}
        seriesData={metrics.NumberOrders}
        compareData={metrics.compareNumberOrders}
      />
    </Row>
  );
}

WooDataCards.propTypes = {
  metrics: PropTypes.shape({
    sales: PropTypes.array.isRequired,
    compareSales: PropTypes.array.isRequired,
    GrossProfit: PropTypes.array.isRequired,
    compareGrossProfit: PropTypes.array.isRequired,
    AOV: PropTypes.array.isRequired,
    compareAOV: PropTypes.array.isRequired,
    NumberOrders: PropTypes.array.isRequired,
    compareNumberOrders: PropTypes.array.isRequired,
  }).isRequired,
};

export default React.memo(WooDataCards);
